/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides header for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

import React, { FunctionComponent, MouseEvent, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';


import { IAuthSession } from '@servicestack/client';

import {
    AppBar,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SwipeableDrawer,
    Toolbar,
    Typography,
    makeStyles,
    styled
} from '@material-ui/core';

import { faExternalLink, faUserCircle } from '@fortawesome/pro-duotone-svg-icons';

import { faBars } from '@fortawesome/pro-solid-svg-icons';

import { Link, NavLink } from 'react-router-dom';
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';
import useIsMobile from '../../hooks/useIsMobile';
import { set } from 'immer/dist/internal';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMenuItem {
    icon: IconDefinition;
    label: React.ReactNode;
    url: string;
    external: boolean;
    newTab: boolean;
}

interface IHeaderV2Props {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];

    user?: IAuthSession | null;

    trialName: string;
    protocolId: string;
    organisationName: string;
    organisationLogo: string | null;
    organisationLogoMobile: string | null;
    applyDarkMode?: (apply: boolean) => void;
}



/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => {
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    return ({
        appBar: {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            gap: theme.spacing(1),
            padding: theme.spacing(0, 3),
            // minHeight: '64px !important',

            '& >div': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 1.5),
                gap: theme.spacing(5),
            },
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(0, 1.5),
                gap: theme.spacing(3),
            }
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: `${theme.spacing(1)} !important`,
        },
        logo: {
            display: 'flex',
            height: '64px',
            margin: theme.spacing(0),

            '& >img': {
                height: '64px',
            },

            [theme.breakpoints.only('xs')]: {
                display: 'none'
            },
        },
        logoMobile: {
            width: '250px',
            height: 'auto',
            padding: theme.spacing(3),
        },
        title: {
            color: theme.palette.primary.main,
            fontWeight: 'bold',

            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        trialUserContainer: {
            gap: theme.spacing(0.5),
        },
        userName: {
            fontWeight: 500,
            fontSize: '1rem',
            color: theme.palette.primary.main,

            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        trialName: {
            //color: onlinePatientManagement.colors.primary[100],

            color: theme.palette.primary.main,

            fontSize: '1rem',
            fontWeight: 600,
            textDecoration: 'none',
            lineHeight: '1.25rem',

            
            //marginTop: theme.spacing(0.4),

            [theme.breakpoints.only('xs')]: {
                color: theme.palette.primary.main
            },
        },
        trialProtocolId: {
            color: '#585a5b',

            fontSize: '0.85rem',
            fontWeight: 400,
            textDecoration: 'none',
        },
        trialNameArea: {
            paddingLeft: theme.spacing(1.5),
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'flex-start' 
        },
        menu: {
            '& ul': {
                padding: 0,

                '& a, li': {
                    padding: theme.spacing(1.5, 3)
                }
            }
        },
        icon: {
            padding: theme.spacing(1),
            fontSize: '1.5rem',
            cursor: 'pointer',
        },
        menuContainer: {
            flexGrow: 1,
            justifyContent: 'flex-end !important',
            display: 'flex',
            wrap: 'nowrap',
        },
        menuDivider: {
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            height: '1.25rem',
            zIndex: 1000
        },
        navLink: {
            display: 'flex',
            flexWrap: 'nowrap',
            borderRadius: 0,
            textTransform: 'none',
            fontWeight: 400,
            fontSize: '1rem',
            height: '100%',
            // backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
            backgroundPosition: 'bottom left',
            // backgroundSize: `0% ${theme.spacing(0.75)}px`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#fff',
            //padding: `${theme.spacing(0, 1)} !important`,
            padding: `${theme.spacing(0)} !important`,
            margin: theme.spacing(0),
            transition: 'background-size 0.15s, background-position 0s 0.15s',

            '& >span': {
                fontSize: '1rem',

                '& >div': {
                    '& >svg': {
                        fontSize: '16px !important',
                        marginRight: theme.spacing(1),
                    },
                    '& >div': {
                        display: 'inline-flex'
                    }
                }
            },

            '&:hover': {
                backgroundPosition: 'bottom right',
                // backgroundSize: `100% ${theme.spacing(0.75)}px`,
                // backgroundColor: '#fff',
            }
        },
        navLinkActive: {
            backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
            backgroundPosition: 'bottom left',
            backgroundSize: `100% ${theme.spacing(0.35)}px`,
            color: theme.palette.primary.main,
        },
        navMenu: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: theme.spacing(0, 1)
        },
        mobileNavLink: {
            padding: theme.spacing(1, 4),
            color: theme.palette.primary.main,

            '& div': {
                color: theme.palette.primary.main,
            }
        },
        mobileNavLinksecondary: {
            padding: theme.spacing(1, 4),
            color: theme.palette.secondary.main,

            '& div': {
                color: theme.palette.secondary.main,
            }
        },
        mobileNavLinkActive: {
            color: theme.palette.primary.dark,
            background: '#C2B5D0', 
        },
        link: {
            color: 'inherit',
            textDecoration: 'none',
            display: 'flex',

            '& .MuiCollapse-container': {
                width: '0px !important',
                transitionProperty: 'width !important'
            },
            '& .MuiCollapse-entered': {
                width: '100% !important'
            },
            '& .MuiCollapse-hidden': {
                width: '0px !important'
            }
        },

    })
});

const HeaderV2: FunctionComponent<IHeaderV2Props> = ({
    menuItems,
    organisationLogo,
    organisationLogoMobile,
    organisationName,
    trialName,
    protocolId,
    user,
    applyDarkMode
}) => {
    const classes = useStyles();

    const isMobile = useIsMobile();

    const [userAnchorEl, setUserAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const { homePageUrl } = React.useContext(OnlinePatientManagementContext);


    const [open, setOpen] = React.useState(false);

    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen])

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    //const [modeIcon, setModeIcon] = React.useState(faSunBright);

    //const darkMode = modeIcon === faMoon;

    //const toggleMode = React.useCallback(() => {
    //    if (!applyDarkMode) {
    //        return;
    //    }

    //    if (darkMode) {
    //        setModeIcon(faSunBright);
    //        applyDarkMode(!darkMode);
    //    } else {
    //        setModeIcon(faMoon);
    //        applyDarkMode(!darkMode);
    //    }
    //}, [darkMode, setModeIcon, applyDarkMode]);
    
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    let dividerInserted = false;

    return (
        <>
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.logoContainer}>
                        {
                            !!isMobile && <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={onOpen}
                            >
                                <FontAwesomeIcon fixedWidth icon={faBars} />
                            </IconButton>
                        }

                        <a href={homePageUrl} className={classes.link}>
                            {
                                !!organisationLogo ?
                                    <Box
                                        className={classes.logo}
                                    >
                                        <img
                                            src={organisationLogo}
                                            alt={organisationName}
                                        />
                                    </Box>
                                    :
                                    <Typography
                                        variant="h2"
                                        className={classes.title}
                                        variantMapping={{
                                            h2: 'span'
                                        }}
                                    >
                                        {organisationName}
                                    </Typography>
                            }
                        </a>
                        <Link to="/" className={classes.link}>
                            <Box
                                className={classes.trialNameArea}
                            >
                                <Typography
                                    className={classes.trialName}
                                >
                                    {trialName}
                                </Typography>
                                <Typography
                                    className={classes.trialProtocolId}
                                >
                                    {protocolId}
                                </Typography>
                            </Box>
                        </Link>
                    </Box>

                    {
                        !!menuItems && !isMobile && (
                            
                            <Box className={classes.menuContainer}>
                                
                                {
                                    menuItems.map((menuItem, i) => {
                                        
                                        if (menuItem.external || menuItem.newTab) {
                                            if (!dividerInserted) {
                                                dividerInserted = true;
                                                return (
                                                    <React.Fragment key={i}>
                                                        <Box className={classes.menuDivider}></Box>
                                                        <Button
                                                            component="a"
                                                            href={menuItem.url}
                                                            color="secondary"
                                                            target={menuItem.newTab ? '_blank' : undefined}
                                                            className={classes.navLink}
                                                            size="small"
                                                        >
                                                            <Box className={classes.navMenu}>
                                                                <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                                                {menuItem.label}
                                                            </Box>
                                                        </Button>
                                                    </React.Fragment>
                                                );
                                            }
                                            return (
                                                <Button
                                                    key={i}
                                                    component="a"
                                                    href={menuItem.url}
                                                    color="secondary"
                                                    target={menuItem.newTab ? '_blank' : undefined}
                                                    className={classes.navLink}
                                                    size="small"
                                                >
                                                    <Box className={classes.navMenu}>
                                                        <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                                        {menuItem.label}
                                                    </Box>
                                                </Button>
                                            );
                                        }

                                        return (
                                            <>
                                                <Button
                                                    key={i}
                                                    component={NavLink}
                                                    to={menuItem.url}
                                                    color="inherit"
                                                    className={classes.navLink}
                                                    activeClassName={classes.navLinkActive}
                                                    size="small"
                                                >
                                                    <Box className={classes.navMenu}>
                                                        <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                                        {menuItem.label}
                                                    </Box>
                                                </Button>
                                            </>
                                        );
                                    })
                                }
                            </Box>
                        )
                    }

                    <Box className={classes.trialUserContainer}>
                        {/* <Box>
                            <Typography
                                className={classes.userName} 
                            >
                                {user?.displayName ?? user?.userName}
                            </Typography>
                        </Box> */}
                        <>
                            <IconButton
                                id="btn-auth"
                                aria-label="account of current user"
                                aria-controls="menu-auth"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit">
                                <FontAwesomeIcon icon={faUserCircle} />
                            </IconButton>
                            <Menu
                                id="menu-auth"
                                anchorEl={anchorEl}
                                keepMounted
                                MenuListProps={{
                                    disablePadding: true
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={anchorEl?.id === 'btn-auth'}
                                onClose={handleClose}
                            >
                                <MenuItem>{user?.displayName ?? user?.userName}</MenuItem>
                                <Divider 
                                    style={{ margin: '0 !important' }}
                                />
                                <MenuItem component="a" href="/auth/logout">
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                        {
                            //!!applyDarkMode && <Tooltip title={darkMode ? 'Dark Mode' : 'Light Mode'}>
                            //    <IconButton
                            //        aria-label="settings"
                            //        aria-controls="menu-settings"
                            //        aria-haspopup="true"
                            //        onClick={toggleMode}
                            //        className={classes.icon}
                            //        color="inherit"
                            //        size="small">
                            //        <FontAwesomeIcon icon={modeIcon} />
                            //    </IconButton>
                            //</Tooltip>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={open}
                onClose={onClose}
                onOpen={onOpen}
            >

                {
                    !!organisationLogoMobile && <Box>
                        <img
                            className={classes.logoMobile}
                            src={organisationLogoMobile}
                            alt={organisationName}
                        />
                    </Box>
                }

                <Divider />
                <List
                    disablePadding
                >
                    {
                        !!menuItems && menuItems.map((menuItem, i) => {

                            if (menuItem.external || menuItem.newTab) {
                                return (
                                    <React.Fragment
                                        key={i}
                                    >
                                        <ListItem
                                            component="a"
                                            button
                                            href={menuItem.url}
                                            color="secondary"
                                            target={menuItem.newTab ? "_blank" : undefined}
                                            className={classes.mobileNavLinksecondary}
                                        >
                                            <ListItemIcon style={{ minWidth: '3rem' }}>
                                                <FontAwesomeIcon icon={menuItem.icon} style={{ fontSize: '1.5rem' }} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={menuItem.label}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment
                                    key={i}
                                >
                                    <ListItem
                                        component={NavLink}
                                        button
                                        to={menuItem.url}
                                        color="inherit"
                                        className={classes.mobileNavLink}
                                        activeClassName={classes.mobileNavLinkActive}
                                    >
                                        <ListItemIcon style={{ minWidth: '3rem' }}>
                                            <FontAwesomeIcon icon={menuItem.icon} style={{ fontSize: '1.5rem' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={menuItem.label}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })
                    }
                </List>
            </SwipeableDrawer>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default HeaderV2;