/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an sae by patient number and sae number
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import { bindActionCreators } from 'redux';

import { IPatient, ResponseStatus, useAsyncFunction, OmitFirstTwoArgs, BoundActionCreator, TypedFunction, bindActionCreatorsWithType } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { SaeReducer, saeActions, useSaeSelector, saeSelectors, ISaeStore } from '../store/sae'

import { ISaeRejectionForm } from '../components/SaeInvestigatorReviewForm';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseSaeActions {
    load: BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.load>>;
    clear: BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.clear>>;
    notify: BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.notify>>;
    reject: BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.reject>>;
    performAction: BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.performAction>>;
    submitMedicalReview: BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.submitMedicalReview>>;
    asyncNotify: TypedFunction<Parameters<BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.notify>>>, Promise<Dtos.SaeNotificationForm>>;
    asyncReject: TypedFunction<Parameters<BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.reject>>>, Promise<ISaeRejectionForm>>;
    asyncPerformAction: TypedFunction<Parameters<BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.performAction>>>, Promise<Dtos.WebAction>>;
    asyncSubmitMedicalReview: TypedFunction<Parameters<BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.submitMedicalReview>>>, Promise<Dtos.MedicalReview>>;
    asyncSubmitInvalidSaeForm: TypedFunction<Parameters<BoundActionCreator<OmitFirstTwoArgs<typeof saeActions.submitInvalidForm>>>, Promise<Dtos.InvalidSaeForm>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useSaeByContext = <TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>>(patientStudyNumber: string, saeNumber: number, autoLoad?: boolean): [
    TSae | null,
    IRequestState<ResponseStatus>,
    IUseSaeActions,
    IRequestState<ResponseStatus>
] => {
    const dispatch = useDispatch();

    const unboundAsyncNotify = useAsyncFunction(saeActions.notify, saeActions.notifySuccess, saeActions.notifyFailure);

    const unboundAsyncReject = useAsyncFunction(saeActions.reject, saeActions.rejectSuccess, saeActions.rejectFailure);

    const unboundAsyncPerformAction = useAsyncFunction(saeActions.performAction, saeActions.performActionSuccess, saeActions.performActionFailure);

    const unboundAsyncSubmitMedicalReview = useAsyncFunction(saeActions.submitMedicalReview, saeActions.submitMedicalReviewSuccess, saeActions.submitMedicalReviewFailure);

    const unboundAsyncSubmitInvalidSaeForm = useAsyncFunction(saeActions.submitInvalidForm, saeActions.submitInvalidFormSuccess, saeActions.submitInvalidFormFailure);

    const actions = React.useMemo(() => {
        const load = () => saeActions.load(patientStudyNumber, saeNumber);
        load.type = saeActions.load.type;

        const clear = () => saeActions.clear(patientStudyNumber, saeNumber);
        clear.type = saeActions.clear.type;

        const notify = (notificationForm: Dtos.SaeNotificationForm) => saeActions.notify(patientStudyNumber, saeNumber, notificationForm);
        notify.type = saeActions.notify.type;

        const reject = (rejectForm: ISaeRejectionForm) => saeActions.reject(patientStudyNumber, saeNumber, rejectForm);
        reject.type = saeActions.reject.type;

        const performAction = (webAction: Dtos.WebAction) => saeActions.performAction(patientStudyNumber, saeNumber, webAction);
        performAction.type = saeActions.performAction.type;

        const submitMedicalReview = (medicalReview: Dtos.MedicalReview) => saeActions.submitMedicalReview(patientStudyNumber, saeNumber, medicalReview);
        submitMedicalReview.type = saeActions.submitMedicalReview.type;

        const submitInvalidSaeForm = (invalidSaeForm: Dtos.InvalidSaeForm) => saeActions.submitInvalidForm(patientStudyNumber, saeNumber, invalidSaeForm);
        submitInvalidSaeForm.type = saeActions.submitInvalidForm.type;

        const asyncNotify = async (form: Dtos.SaeNotificationForm) => {
            const [, ,] = await unboundAsyncNotify([patientStudyNumber, saeNumber, form]);

            return form;
        };

        const asyncReject = async (rejectForm: ISaeRejectionForm) => {
            const [, ,] = await unboundAsyncReject([patientStudyNumber, saeNumber, rejectForm]);

            return rejectForm;
        };

        const asyncPerformAction = async (webAction: Dtos.WebAction) => {
            const [, ,] = await unboundAsyncPerformAction([patientStudyNumber, saeNumber, webAction]);

            return webAction;
        };

        const asyncSubmitMedicalReview = async (medicalReview: Dtos.MedicalReview) => {
            const [, ,] = await unboundAsyncSubmitMedicalReview([patientStudyNumber, saeNumber, medicalReview]);

            return medicalReview;
        };

        const asyncSubmitInvalidSaeForm = async (invalidSaeForm: Dtos.InvalidSaeForm) => {
            const [, ,] = await unboundAsyncSubmitInvalidSaeForm([patientStudyNumber, saeNumber, invalidSaeForm]);

            return invalidSaeForm;
        };

        return {
            ...bindActionCreatorsWithType<IUseSaeActions>({
                load,
                clear,
                notify,
                reject,
                performAction,
                submitMedicalReview,
                submitInvalidSaeForm,
            }, dispatch),
            asyncNotify,
            asyncReject,
            asyncPerformAction,
            asyncSubmitMedicalReview,
            asyncSubmitInvalidSaeForm
        }
    }, [saeActions, patientStudyNumber, saeNumber, dispatch, unboundAsyncNotify, unboundAsyncNotify, unboundAsyncPerformAction, unboundAsyncSubmitMedicalReview]);

    const saeSelector = React.useCallback((state: ISaeStore) => {
        return saeSelectors.sae(state, patientStudyNumber, saeNumber)
    }, [saeSelectors.sae, patientStudyNumber, saeNumber]);

    const loadStateSelector = React.useCallback((state: ISaeStore) => {
        return saeSelectors.loadState(state, patientStudyNumber, saeNumber)
    }, [saeSelectors.loadState, patientStudyNumber, saeNumber]);

    const actionStateSelector = React.useCallback((state: ISaeStore) => {
        const id = `${patientStudyNumber}-${saeNumber}`;

        return saeSelectors.actionState(state, patientStudyNumber, saeNumber)
    }, [saeSelectors.actionState, patientStudyNumber, saeNumber]);

    const sae = useSaeSelector(saeSelector);

    const loadState = useSaeSelector(loadStateSelector);

    const actionState = useSaeSelector(actionStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, patientStudyNumber, saeNumber]);

    return [
        sae as TSae | null,
        loadState,
        actions,
        actionState
    ];
};

