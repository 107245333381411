/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    asyncDebounce,
    IForm,
    IFormState,
    IFormContext,
    IInstitution,
    useAuthenticatedUser,
    useSnackbar,
    InstitutionContext,
    IValidationError,
    ValidationErrorType,
    IPatient,
    IFormValidationError,
    OnlinePatientManagementContext,
    SnackbarVariant,
    ILookup
} from '@ngt/opms';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';
import { ISaeRejectionForm } from '../components/SaeInvestigatorReviewForm';
import { pascalToCameCasePropertyPath } from '@ngt/opms/dist/utilities/pascalToCamelCase';

/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/

export interface IUseSaeNotificationFormOptions<Type extends ISaeRejectionForm> {
}

const errorVariantMapping: Record<ValidationErrorType, SnackbarVariant> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligible',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification-failure',
    [ValidationErrorType.Critical]: 'critical'
}

const errorTextMapping: Record<ValidationErrorType, string> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligibility warning',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification failure',
    [ValidationErrorType.Critical]: 'critical error'
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useSaeInvestigatorReviewForm = <Type extends ISaeRejectionForm>() => {
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { institution } = React.useContext(InstitutionContext);

    const { sae, loadState, actions: saeActions } = React.useContext(SaeContext);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<Type, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SAE Not Reviewed
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type === ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SAE Not Reviewed
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async (formState: IFormState<Type, IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    SAE Not Reviewed
                    </AlertTitle>
                    An error occurred while attempting to review the SAE.
            </>,
            { variant: 'critical' }
        );
    }, []);

    const onFormCancel = React.useCallback(() => {
        const institutionCode = institution?.code;

        if (sae) {
            history.push(`/sae/${institutionCode}/${sae?.patient?.studyNumber}/${sae?.saeNumber}`)
        }
        else if (institutionCode) {
            history.push(`/sae/${institutionCode}`)
        }
        else {
            history.push(`/sae`);
        }
    }, [history, sae, institution]);

    const onFormApprove = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'approved');
        }
    }, []);

    const onFormReject = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'rejected');
        }
    }, []);

    const lookups = React.useMemo(() => {
        const rejectedAsLookup: ILookup = {
            propertyName: 'rejectedAs',
            items: [
                {
                    id: 1,
                    value: 'SAE is invalid',
                    order: 1
                },
                {
                    id: 2,
                    value: 'Information is incorrect',
                    order: 2
                }
            ]
        }

        return [rejectedAsLookup];
    }, []);

    const createRejectNotes = React.useCallback((values: Type) => {
        if (!values.reasonForRejection || !values.rejectedAs) {
            return '';
        }

        return `
            <strong>Rejected as ${lookups.find(x => x.propertyName == 'rejectedAs')?.items.find(i => i.id == values.rejectedAs)?.value}</strong>
            <br/><br/>
            <strong>Reason for rejection: </strong>
            <br/>
            ${values.reasonForRejection}
        `
    }, [lookups]);

    const onValidate = React.useCallback(async (formState: IFormState<Type, IValidationError>) => {

        const { submitType } = formState?.values as any;

        if (submitType === 'approved') {
            return [] as any;
        }

        const actionForm: Dtos.WebAction = {
            patientStudyNumber: '',
            userName: '',
            recipients: '',
            saeWebActionType: {} as Dtos.SaeWebActionType,
            reasonForRejection: formState.values.reasonForRejection,
            rejectedAs: formState.values.rejectedAs,
            notes: createRejectNotes(formState.values),
        } 

        // Send request to server.
        const response = await onlinePatientManagement
            .serviceStackClient
            .post(new Dtos.SaeValidateRejectionForm({ form: actionForm }));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, IFormValidationError[]>, b: IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        return groupErrors;

    }, [onlinePatientManagement.serviceStackClient, pascalToCameCasePropertyPath, createRejectNotes]);

    //// debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<Type, IValidationError>) => {
        const { submitType, ...form } = values as any;

        if (submitType === 'approved') {

            const webAction = new Dtos.WebAction({
                patientStudyNumber: sae?.patient.studyNumber,
                saeNumber: sae?.saeNumber,
                saeFollowUpNumber: sae?.form.followUpNumber,
                actionType: Dtos.SaeWebActionTypeEnum.Approved,
                notes: '',
                recipients: '',
                reasonForRejection: '',
                rejectedAs: undefined
            });

            await saeActions?.asyncPerformAction(webAction);

        } else {
            await saeActions?.asyncReject({
                followUpNumber: values.followUpNumber,
                notes: createRejectNotes(values),
                rejectedAs: values.rejectedAs,
                reasonForRejection: values.reasonForRejection
            } as Type);
        }

        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SAE Not Reviewed
                    </AlertTitle>
                    The form was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                </>,
                { variant: errorVariantMapping[maxErrorType] }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SAE Reviewed
                    </AlertTitle>
                    The review form was successfully submitted.
                </>,
                { variant: 'success' }
            );
        }

        history.push(`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}`)

    }, [saeActions?.asyncReject, saeActions.performAction, saeActions.load, institution, sae, history, createRejectNotes]);

    return {
        handleSubmit,
        onFormCancel,
        onFormApprove,
        onFormReject,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate: debouncedValidate,
        lookups: lookups
    }
}

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useSaeInvestigatorReviewForm;

