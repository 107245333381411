/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { makeStyles, Toolbar, Button, Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IBreadcrumb {
    label: React.ReactNode;
    icon: IconDefinition;
    to: string;
}

/**
 * This interface defines the properties for the Breadcrumbs component.
 */
export interface IBreadcrumbsProps {
    breadcrumbs: IBreadcrumb[]
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => {
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    return ({
        breadcrumbs: {
            background: 'transparent',
            color: '#585a5b',
            display: 'flex',
            alignItems: 'center'
        },
        navLink: {
            borderRadius: 50,
            fontSize: '0.75rem',
            fontWeight: 500,
            textTransform: 'none',
            margin: theme.spacing(0.25, 0),

            // '&:hover': {
            //     backgroundColor: onlinePatientManagement.colors.secondary[50]
            // }
        },
        navLinkActive: {
            // background: onlinePatientManagement.colors.secondary[50]
        }
    })
});

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = ({
    breadcrumbs
}) => {
    const classes = useStyles();
    return (
        <MuiBreadcrumbs
            className={classes.breadcrumbs}
            separator={<FontAwesomeIcon icon={faChevronRight} fixedWidth />}
        >
            {
                breadcrumbs?.map((breadcrumb, i) =>{
                    return (
                        <Button
                            key={i}
                            component={NavLink}
                            to={breadcrumb.to}
                            color="inherit"
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            startIcon={<FontAwesomeIcon icon={breadcrumb.icon} fixedWidth />}
                            size="small"
                            disableRipple
                        >
                            {breadcrumb.label}
                        </Button>
                    );
                })
            }
        </MuiBreadcrumbs>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Breadcrumbs;