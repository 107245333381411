/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an sae reviewers by institution codes & roles
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import { bindActionCreators } from 'redux';

import {
    ResponseStatus,
    IPatient,
    OmitFirstTwoArgs,
    OmitFirstArg,
    BoundActionCreator
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { SaeReviewersReducer, saeReviewersActions, useSaeReviewersSelector, saeReviewersSelectors, ISaeReviewersStore } from '../store/saereviewers';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseSaeReviewersActions {
    load: BoundActionCreator<OmitFirstTwoArgs<typeof saeReviewersActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof saeReviewersActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useSaeReviewers = (institutionCode: string, roles: number[], autoLoad?: boolean): [
    Record<number, Dtos.InstitutionPersonnel>,
    IRequestState<ResponseStatus>,
    IUseSaeReviewersActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => saeReviewersActions.load(institutionCode, roles);
        load.type = saeReviewersActions.load.type;

        const clear = () => saeReviewersActions.clear(institutionCode);
        clear.type = saeReviewersActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [saeReviewersActions, institutionCode, dispatch]);

    const saeReviewersSelector = React.useCallback((state: ISaeReviewersStore) => {
        return saeReviewersSelectors.reviewers(state, institutionCode)
    }, [saeReviewersSelectors.reviewers, institutionCode]);

    const loadStateSelector = React.useCallback((state: ISaeReviewersStore) => {
        return saeReviewersSelectors.loadState(state, institutionCode)
    }, [saeReviewersSelectors.loadState, institutionCode]);

    const reviewers = useSaeReviewersSelector(saeReviewersSelector);

    const loadState = useSaeReviewersSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionCode, roles]);

    return [
        reviewers,
        loadState,
        actions
    ];
};