/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drugShipment modal component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { IRequestState, RequestState } from '@ngt/request-utilities';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Theme, makeStyles } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Field,
    FieldProvider,
    Form,
    FormGrid,
    IFormGridCell,
    IInstitution,
    Input,
    KeyboardDatePicker,
    Numeric,
    PharmacistCheckList,
    ResponseStatus,
    Select,
    SubmitButton,
    Text
} from '@ngt/opms';

import { DateTime } from 'luxon';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import DrugManagementContext from '../context/DrugManagementContext';
import { IUseDrugShipmentActions } from '../hooks/useDrugShipment';
import useModal from '../hooks/useModal';
import { usePharmacists } from '../hooks/usePharmacists';
import { useValidation } from '../hooks/useValidation';
import { convertStringToDate } from '../utilities/convertStringToDate';
import { getDrugUnitName } from '../utilities/getDrugUnitName';
import { getShipmentStatusDateDescription } from '../utilities/getShipmentStatusText';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    formControl: {
        width: '100%'
    },
    dialogContent: {
        padding: '0'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugShipmentModalProps {
    drugShipment: Dtos.DrugShipment | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    depotBatches: Dtos.DepotBatch[] | null;
    depotBatchesLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    institutions: IInstitution[] | null;
    institutionLoadState: IRequestState<ResponseStatus>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    onFormSaveError?: () => void;
    drugShipmentActions: IUseDrugShipmentActions;
    canAdministerDrugManagement: boolean;
}

interface IDrugShipmentFormProps {
    drugShipment: Dtos.DrugShipment | null;
    depotBatches: Dtos.DepotBatch[] | null;
    depotBatchesLoadState: IRequestState<ResponseStatus>;
    drugs: Dtos.Drug[] | null;
    drugLoadState: IRequestState<ResponseStatus>;
    institutions: IInstitution[] | null;
    institutionLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
    canAdministerDrugManagement: boolean;
    pharmacists: Dtos.IPharmacist[] | null;
    pharmacistsLoadState: IRequestState<ResponseStatus>;
}

const DrugShipmentForm: React.FunctionComponent<IDrugShipmentFormProps> = ({
    drugShipment,
    depotBatches,
    depotBatchesLoadState,
    institutions,
    institutionLoadState,
    setModalOpen,
    canAdministerDrugManagement,
    pharmacists,
    pharmacistsLoadState
}) => {

    const classes = useStyles();
    const drugManagementContext = React.useContext(DrugManagementContext);
    const drugUnitName = getDrugUnitName(true);

    const shipmentNotNew = !!drugShipment && drugShipment.shipmentStatus !== Dtos.ShipmentStatus.New;
    const shipmentExists = !!drugShipment && drugShipment.id;

    const onCloseClick = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const institutionItems = React.useMemo(() => {
        if (!institutions || institutions.length === 0) {
            return [];
        }
        else {
            return institutions.map(institution => {
                return <MenuItem
                    key={institution.id}
                    value={institution.code}
                >
                    {institution.name}
                </MenuItem>;
            });
        }
    }, [institutions]);

    const allowBatchDelete = React.useCallback((_values: Dtos.DrugShipmentDepotBatch[] | null | undefined,
        index: number) => {
        return index > 0;
    }, []);

    const depotBatchItems = React.useMemo(() => {
        if (!depotBatches || depotBatches.length === 0) {
            return [];
        }
        else {
            //if the shipment is new, don't let them assign batches that are unavailable, or where there aren't any drugs to allocate, 
            //or where the allocation cut off date has passed
            //if multiple batches exist at the depot with the same batch id, take the one with the lowest depotbatchno, which should be the oldest record

            let depotBatchesForForm = depotBatches;

            if (!shipmentNotNew) {
                const minDepotBatch = depotBatches.filter(db => db.available &&
                    (db.drugUnitsAllocated < db.drugUnitsTotal) &&
                    DateTime.local().startOf('day') <= DateTime.fromISO(db.batch.allocationCutOffDate!!))
                    .reduce((accumulator, currentValue) => {
                        const existingValue = accumulator[currentValue.batchId];

                        if (!existingValue ||
                            existingValue.depotBatchNo!! > currentValue.depotBatchNo!!) {
                            accumulator[currentValue.batchId] = currentValue;
                        }

                        return accumulator;
                    }, {} as Record<number, Dtos.DepotBatch>);

                depotBatchesForForm = Object.keys(minDepotBatch).map(key => minDepotBatch[parseInt(key, 10)]);
            }

            return depotBatchesForForm.map(depotBatch => {
                return <MenuItem
                    key={depotBatch.id}
                    value={depotBatch.id}
                >
                    {`${depotBatch.batch.drug.drugName} (${depotBatch.batch.batchNo} (${depotBatch.depotBatchNo} Expires ${DateTime.fromISO(depotBatch.batch.dateExpiration!!).toFormat('dd/MM/yyyy')}))`}
                </MenuItem>;
            });
        }
    }, [depotBatches, depotBatchesLoadState, shipmentNotNew]);

    const drugShipmentStatusHistory = React.useMemo(() => {
        if (drugShipment &&
            drugShipment.drugShipmentStatusHistory &&
            drugShipment.drugShipmentStatusHistory.length > 0) {

            return <FieldProvider
                name="drugShipmentStatusHistory"
                autoRegister={false}
            >
                {
                    drugShipment.drugShipmentStatusHistory.map((statusHistory, i) => {
                        return (
                            <FieldProvider
                                key={`sh-${statusHistory.id}`}
                                name={`[${i}]`}
                                autoRegister={false}
                            >
                                <Field
                                    name="statusDate"
                                    label={getShipmentStatusDateDescription(statusHistory.shipmentStatus)}
                                    component={KeyboardDatePicker}
                                    parse={convertStringToDate}
                                />
                            </FieldProvider>
                        );
                    })
                }
            </FieldProvider>;
        }

        return null;
    }, [drugShipment]);

    const drugShipmentDepotBatches = React.useMemo(() => {
        if (drugShipment &&
            drugShipment.drugShipmentDepotBatches &&
            drugShipment.drugShipmentDepotBatches.length > 0) {

            const columns: Array<IFormGridCell<Dtos.DrugShipmentDepotBatch>> = [
                {
                    name: 'depotBatchId',
                    header: 'Drug Batch',
                    minWidth: 400,
                    content: (
                        <Input
                            component={Select}
                            disabled={shipmentNotNew && !canAdministerDrugManagement}
                        >
                            {depotBatchItems}
                        </Input>
                    )
                },
                {
                    name: 'drugUnits',
                    header: drugUnitName,
                    content: (
                        <Input
                            component={Numeric}
                            disabled={shipmentNotNew && !canAdministerDrugManagement}
                        />
                    )
                }
            ];

            return <FormGrid
                name="drugShipmentDepotBatches"
                columns={columns}
                type={Dtos.DrugShipmentDepotBatch}
                minRow={1}
                allowDelete={allowBatchDelete}
                allowAdd={!shipmentNotNew || canAdministerDrugManagement}
            />;
        }

        return null;
    }, [drugShipment, depotBatchesLoadState, depotBatchItems, shipmentNotNew]);

    const showConsignmentNumber = shipmentExists ||
        drugManagementContext.allowEditConsignmentNumber;

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                {
                    institutionLoadState && institutionLoadState.state && institutionLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="institutionCode"
                            component={Select}
                            disabled={true}
                            label="Institution"
                        >
                            {institutionItems}
                        </Field>
                }
                <Field
                    name="shipmentStatus" 
                    component={Select}
                    disabled={true}
                    label="Status"
                >
                    <MenuItem value={Dtos.ShipmentStatus.New}>New</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.Ordered}>Ordered</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.InTransit}>In Transit</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.Available}>Available</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.Lost}>Lost</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.Destroyed}>Destroyed</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.QuarantinedFromTransit}>Quarantined From Transit</MenuItem>
                    <MenuItem value={Dtos.ShipmentStatus.QuarantinedAtSite}>Quarantined At Site</MenuItem>
                </Field>
                {
                    showConsignmentNumber ?
                        <Field
                            name="consignmentNo"
                            component={Text}
                            label={drugManagementContext.consignmentNumberLabel}
                            disabled={!drugManagementContext.allowEditConsignmentNumber}
                        /> :
                        null
                }
                {
                    shipmentExists ?
                        <Field
                            name="pharmacyShipmentNo"
                            component={Text}
                            label="Shipment Number"
                            disabled={true}
                        /> :
                        null
                }
                {
                    drugManagementContext.allowDrugShipmentPharmacistSelection ?
                        pharmacistsLoadState && pharmacistsLoadState.state && pharmacistsLoadState.state === RequestState.Pending ?
                            <CircularProgress /> :
                                <Field 
                                    name="drugShipmentPharmacists"
                                    component={PharmacistCheckList}
                                    options={pharmacists}
                                    optionsTextFormat={(item: Dtos.IPharmacist) => {
                                        return `${item.title ?? ''} ${item.firstName ?? ''} ${item.lastName ?? ''}`
                                    }}
                                    optionsStoreFormat={(item: Dtos.IPharmacist) => {
                                        return {
                                            id: undefined,
                                            pharmacyId: undefined,
                                            pharmacistId: item.id,
                                            drugShipmentId: undefined
                                        } as Dtos.DrugShipmentPharmacist;
                                    }}
                                    label="Pharmacists"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}   
                                /> :
                        null
                }
                {
                    drugShipmentStatusHistory
                }
                {
                    drugShipmentDepotBatches
                }
                {
                    <Field
                        name="notes"
                        component={Text}
                        label="Notes"
                        multiline={true}
                        rows={4}
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DrugShipmentModal: React.FunctionComponent<IDrugShipmentModalProps> = ({
    drugShipment,
    modalOpen,
    setModalOpen,
    depotBatches,
    depotBatchesLoadState,
    drugs,
    drugLoadState,
    institutions,
    institutionLoadState,
    onCloseClick,
    onFormSave,
    onFormSaveError,
    drugShipmentActions,
    canAdministerDrugManagement
}) => {

    const onValidate = useValidation<Dtos.DrugShipment>((formValues) => new Dtos.ValidateDrugShipment({ drugShipment: formValues }));

    const drugManagementContext = React.useContext(DrugManagementContext);

    const [pharmacists, pharmacistsLoadState, pharmacistsActions] = usePharmacists();

    React.useEffect(() => {
        if (drugShipment?.institutionCode &&
            drugManagementContext.allowDrugShipmentPharmacistSelection) {
                pharmacistsActions.loadByInstCode(drugShipment.institutionCode);
            }

            return () => {
                pharmacistsActions.clear();
            }
    }, 
    [
        pharmacistsActions,
        drugShipment?.institutionCode,
        drugManagementContext.allowDrugShipmentPharmacistSelection
    ]);

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'drug shipment',
        asyncSave: drugShipmentActions.asyncSave,
        onFormSave: onFormSave,
        onFormSaveError: onFormSaveError,
        onValidate: onValidate
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Save Drug Shipment
                </DialogTitle>
                <Form
                    initialValues={drugShipment}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DrugShipmentForm
                        drugShipment={drugShipment}
                        depotBatches={depotBatches}
                        depotBatchesLoadState={depotBatchesLoadState}
                        drugs={drugs}
                        drugLoadState={drugLoadState}
                        institutions={institutions}
                        institutionLoadState={institutionLoadState}
                        setModalOpen={setModalOpen}
                        canAdministerDrugManagement={canAdministerDrugManagement}
                        pharmacists={pharmacists}
                        pharmacistsLoadState={pharmacistsLoadState}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default DrugShipmentModal;