/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae investigator review form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { makeStyles, Button, List, ListItem, MenuItem } from '@material-ui/core';

import { InstitutionContext, Form, SubmitButton, Field, TextArea, FieldGroup, FormGrid, IFormGridCell, Input, OnlinePatientManagementContext, Select, ToggleButtonGroup } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';

import useSaeInvestigatorReviewForm from '../hooks/useSaeInvestigatorReviewForm';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeInvestigatorReviewFormProps {
}

export interface ISaeRejectionForm {
    notes?: string;
    rejectedAs?: number;
    followUpNumber?: number;
    reasonForRejection: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    rejectionLabel: {
        fontWeight: "bold",
        marginTop: theme.spacing(2)
    },
    rejectionReason: {
        marginLeft: theme.spacing(-3)
    },
    ml0: {
        marginLeft: theme.spacing(-1)
    },
    mt1: {
        marginTop: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeInvestigatorReviewForm: React.FunctionComponent<ISaeInvestigatorReviewFormProps> = () => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState, actions: saeActions } = React.useContext(SaeContext);

    const showForm = React.useMemo(() => {
        return sae?.status !== Dtos.SaeStatus.Approved && sae?.status !== Dtos.SaeStatus.Rejected
    }, [sae?.status]);

    const initialInvestigatorReviewForm: ISaeRejectionForm = React.useMemo(() => {
        return {
            rejectedAs: undefined,
            followUpNumber: sae?.form?.followUpNumber,
            reasonForRejection: ''
        }
    }, [sae?.form?.followUpNumber]);

    const { trialName, organisationName, organisationLogo } = React.useContext(OnlinePatientManagementContext)

    const {
        handleSubmit,
        onFormCancel,
        onFormApprove,
        onFormReject,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate,
        lookups
    } = useSaeInvestigatorReviewForm();

    return (
        <>
            {
                showForm &&
                <>
                    <Form
                        initialValues={initialInvestigatorReviewForm}
                        onValidate={validate}
                        validateOn="onChange"
                        allowSubmit={undefined}
                        fieldErrors={'default'}
                        onSubmit={handleSubmit}
                        labels={undefined}
                        lookups={lookups}
                        readOnly={false}
                        onSubmitFailed={onFormSubmitFailure}
                        onSubmitValidationFailed={onFormSubmitValidationFailure}
                    >
                        <FieldGroup>
                        <br />
                        <Typography
                            component={'span'}
                            className={classes.mt1}
                        >
                                An SAE has been updated for the {trialName} trial.

                            <List component="ul">
                                <ListItem>Approve: If submitted data is correct click 'approve'.</ListItem>
                                <ListItem>Reject: If data amendments are required or the event does not constitute an SAE enter the reason and click 'reject'. The site ISC will be requested to log in to OpenClinica to make the required changes or submit a follow up SAE.</ListItem>
                            </List>
                        </Typography>

                        <SubmitButton
                            variant="contained"
                            color="primary"
                            onClick={onFormApprove}
                            className={classes.ml0}
                        >
                            Approve SAE
                        </SubmitButton>

                        <Typography>
                            <br />
                            OR
                        <br />
                        </Typography>

                        <Field
                            name="rejectedAs"
                            component={ToggleButtonGroup}
                            sm={8}
                            md={8}
                            xl={8}
                            lg={8}
                            label={<Typography><strong>Rejected as</strong></Typography>}
                            paddingTop={2}
                        />
                            
                        <Field
                            name="reasonForRejection"
                            component={TextArea}
                            rows={10}
                            rowsMax={20}
                            variant="outlined"
                            label={<Typography><strong>Reason for rejection</strong></Typography>}
                            paddingTop={2}
                            fullWidth   
                            noErrorFlag
                            sm={8}
                            md={8}
                            xl={8}
                            lg={8}
                        />
                        
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            onClick={onFormReject}
                            className={classes.ml0}
                        >
                            Reject SAE
                        </SubmitButton>
                        <br /><br />
                        </FieldGroup>
                    </Form>
                </>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeInvestigatorReviewForm;
