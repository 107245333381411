/* Options:
Date: 2025-01-15 08:42:46
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44367

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetUserEmailAddresses,GetUserEmailAddressesResponse,GetUserPersonnelEmailAddress,UserPersonnelEmailAddressResponse,GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,GetPharmacyEmails,GetPharmacyEmailsResponse,GetDrugManagementLogDataResponse,GetDrugShipmentReportDataResponse,GetPatientActionReportDataResponse,GetDrugManagementLogData,GetDrugShipmentReportData,GetPatientActionReportData
//DefaultImports: 
*/

//@ts-nocheck

export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IDelete
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IHasPatientId
{
    patientId?: number;
}

export enum RejectedAsType
{
    SaeIsInvalid = 1,
    InformationIsIncorrect = 2,
}

export interface IReportRequest
{
    token: string;
}

export class Drug
{
    public id?: number;
    public drugName: string;
    public unitsPerShippingContainer: number;
    public containerName: string;
    public unitConcentration: number;
    public unitVolume: number;
    public enableDrugShipmentGeneration: boolean;
    public drugTypeId?: number;

    public constructor(init?: Partial<Drug>) { (Object as any).assign(this, init); }
}

export class Batch
{
    public id?: number;
    public drugId?: number;
    public batchNo: string;
    public dateExpiration?: string;
    public allocationCutOffDate?: string;
    public available: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<Batch>) { (Object as any).assign(this, init); }
}

export class DepotBatch
{
    public id?: number;
    public depotId: number;
    public batchId: number;
    public depotBatchNo?: number;
    public drugUnitsTotal: number;
    public drugUnitsAllocated: number;
    public drugUnitsDestroyed: number;
    public available: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public batch: Batch;

    public constructor(init?: Partial<DepotBatch>) { (Object as any).assign(this, init); }
}

export class OrderForm
{
    public id?: number;
    public orderFormName: string;

    public constructor(init?: Partial<OrderForm>) { (Object as any).assign(this, init); }
}

export class Depot
{
    public id?: number;
    public depotName: string;
    public address: string;
    public email: string;
    public contactName: string;
    public phoneNo: string;
    public orderFormId: number;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public orderForm: OrderForm;

    public constructor(init?: Partial<Depot>) { (Object as any).assign(this, init); }
}

export enum PharmacyStatus
{
    Inactive = 1,
    Open = 2,
    Closed = 3,
}

export class PharmacyDrug
{
    public id?: number;
    public pharmacyId: number;
    public drugId: number;
    public capacity: number;
    public minThreshold: number;
    public enabled: boolean;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<PharmacyDrug>) { (Object as any).assign(this, init); }
}

export class Pharmacy
{
    public id?: number;
    public depotId: number;
    public institutionCode: string;
    public pharmacyName: string;
    public phoneNumber: string;
    public address: string;
    public email: string;
    public ccOnEmails: boolean;
    public pharmacyStatus?: PharmacyStatus;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public notes: string;
    public pharmacistPersonId?: number;
    public pharmacyDrugs: PharmacyDrug[];

    public constructor(init?: Partial<Pharmacy>) { (Object as any).assign(this, init); }
}

export class Timepoint
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<Timepoint>) { (Object as any).assign(this, init); }
}

export class DrugDispensation implements IDrugManagementForm
{
    public id?: number;
    public pharmacyId?: number;
    public patientId?: number;
    public depotBatchId?: number;
    public timepointId?: number;
    public dateDispensation?: string;
    public drugUnits?: number;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public pharmacy: Pharmacy;
    public depotBatch: DepotBatch;
    public timepoint: Timepoint;

    public constructor(init?: Partial<DrugDispensation>) { (Object as any).assign(this, init); }
}

export enum ShipmentStatus
{
    New = 1,
    Ordered = 2,
    InTransit = 3,
    Available = 4,
    Lost = 5,
    QuarantinedFromTransit = 6,
    Destroyed = 7,
    QuarantinedAtSite = 8,
}

export class DrugShipmentDepotBatch implements IDrugManagementForm
{
    public id?: number;
    public drugShipmentId?: number;
    public depotBatchId?: number;
    public drugUnits?: number;
    public drugUnitsUsed?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;
    public notes: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipment: DrugShipment;
    public depotBatch: DepotBatch;
    public drugAllocated?: number;
    public drugRemaining?: number;

    public constructor(init?: Partial<DrugShipmentDepotBatch>) { (Object as any).assign(this, init); }
}

export class DrugShipmentStatusHistory implements IDrugManagementForm
{
    public id?: number;
    public drugShipmentId?: number;
    public shipmentStatus: ShipmentStatus;
    public statusDate: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<DrugShipmentStatusHistory>) { (Object as any).assign(this, init); }
}

export class DrugManagementFileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<DrugManagementFileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class DrugManagementFile
{
    // @Ignore()
    public upload: DrugManagementFileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<DrugManagementFile>) { (Object as any).assign(this, init); }
}

export class DrugShipmentDocument
{
    public id?: number;
    public drugShipmentId?: number;
    public name: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public file: DrugManagementFile;

    public constructor(init?: Partial<DrugShipmentDocument>) { (Object as any).assign(this, init); }
}

export class DrugShipmentPharmacist
{
    public id?: number;
    public pharmacyId?: number;
    public pharmacistId?: number;
    public drugShipmentId?: number;

    public constructor(init?: Partial<DrugShipmentPharmacist>) { (Object as any).assign(this, init); }
}

export class DrugShipment implements IDrugManagementForm
{
    public id?: number;
    public institutionCode: string;
    public pharmacyId?: number;
    public pharmacyShipmentNo?: number;
    public shipmentStatus?: ShipmentStatus;
    public consignmentNo: string;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public notes: string;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];
    public drugShipmentStatusHistory: DrugShipmentStatusHistory[];
    public drugShipmentDocuments: DrugShipmentDocument[];
    public drugShipmentPharmacists: DrugShipmentPharmacist[];

    public constructor(init?: Partial<DrugShipment>) { (Object as any).assign(this, init); }
}

export class DrugAmount
{
    public drugId?: number;
    public drugUnits?: number;

    public constructor(init?: Partial<DrugAmount>) { (Object as any).assign(this, init); }
}

export class DrugShipmentDepotBatchVerify
{
    public id?: number;
    public drugShipmentId?: number;
    public depotBatchId?: number;
    public drugUnits?: number;
    public drugUnitsReceived?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;

    public constructor(init?: Partial<DrugShipmentDepotBatchVerify>) { (Object as any).assign(this, init); }
}

export class DrugShipmentVerify
{
    public id?: number;
    public consignmentNo: string;
    public received?: string;
    public notes: string;
    public drugShipmentDepotBatches: DrugShipmentDepotBatchVerify[];
    public drugShipmentDocuments: DrugShipmentDocument[];

    public constructor(init?: Partial<DrugShipmentVerify>) { (Object as any).assign(this, init); }
}

export enum PatientTreatmentStatus
{
    OnTreatment = 1,
    OffTreatment = 2,
}

export class PatientDrug
{
    public id?: number;
    public patientId?: number;
    public drugId?: number;
    public patientTreatmentStatus?: PatientTreatmentStatus;
    public enteredDate?: string;
    public enteredByUserId: string;
    public modifiedDate?: string;
    public modifiedByUserId: string;
    public drug: Drug;

    public constructor(init?: Partial<PatientDrug>) { (Object as any).assign(this, init); }
}

export enum QuarantinedStockAction
{
    Quarantined = 1,
    ReturnStockToSiteSupply = 2,
    DestroyStock = 3,
}

export enum StatusTypeEnum
{
    Waiting = 1,
    Responded = 2,
    NotInterested = 3,
    PatientSigning = 4,
    InvestigatorSigning = 5,
    VerifyingDocument = 6,
    Completed = 7,
    Refused = 8,
}

export class StatusType
{
    public id: StatusTypeEnum;
    public value: string;

    public constructor(init?: Partial<StatusType>) { (Object as any).assign(this, init); }
}

export class Invitation
{
    public id?: number;
    // @Ignore()
    public initials: string;

    public emailAddress: string;
    public institutionCode: string;
    public investigator: string;
    public guid: string;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.StatusType)")
    public statusId?: StatusTypeEnum;

    public dateSent?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public status: StatusType;

    public constructor(init?: Partial<Invitation>) { (Object as any).assign(this, init); }
}

export interface IEoiGetSingleById extends IGet
{
    id?: number;
}

export interface IEoiGetCollection extends IGet
{
}

export interface IEoiGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export class Eoi implements IEoi
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    public invitationId?: number;

    public firstName: string;
    public lastName: string;
    public confirmed?: boolean;
    public dateSubmitted?: string;
    public statusId?: StatusTypeEnum;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public invitation: Invitation;

    public constructor(init?: Partial<Eoi>) { (Object as any).assign(this, init); }
}

export interface IEoiDelete extends IDelete
{
    id?: number;
}

export interface IPatientConsentGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientConsentGetSingleByGuid extends IGet
{
    guid: string;
}

export interface IPatientConsentGetCollection extends IGet
{
}

export interface IPatientConsentGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export enum ConsentTypeEnum
{
    Remote = 1,
    FaceToFace = 2,
    Standard = 3,
}

export class ConsentPicf
{
    public id?: number;
    public consentId?: number;
    public picfTypeId?: number;
    public picfId?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConsentPicf>) { (Object as any).assign(this, init); }
}

export class ConsentFileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<ConsentFileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class ConsentFileUpload
{
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public exists: boolean;
    public image: boolean;
    public upload: ConsentFileUploadTemporary;

    public constructor(init?: Partial<ConsentFileUpload>) { (Object as any).assign(this, init); }
}

export class ConsentType
{
    public id: ConsentTypeEnum;
    public value: string;

    public constructor(init?: Partial<ConsentType>) { (Object as any).assign(this, init); }
}

export class PatientConsent
{
    public id?: number;
    // @References("typeof(Tugether.ServiceLogic.Models.Dos.EConsent.Eoi)")
    public eoiId?: number;

    public patientId?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.ConsentType)")
    public typeId?: ConsentTypeEnum;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.StatusType)")
    public statusId?: StatusTypeEnum;

    public guid: string;
    public dateStarted?: string;
    public mobileNumber: string;
    public patientSigned?: boolean;
    public datePatientSigned?: string;
    public investigatorSigned?: boolean;
    public dateInvestigatorSigned?: string;
    public documentVerified?: boolean;
    public documentVerifiedBy: string;
    public dateDocumentVerified?: string;
    public dateNotificationSent?: string;
    public contactAboutGeneticRisks?: boolean;
    public alternativeContactName: string;
    public alternativeContactAddress: string;
    public alternativeContactNumber: string;
    public alternativeContactEmail: string;
    // @Ignore()
    public picfs: ConsentPicf[];

    // @Ignore()
    public documentUpload: ConsentFileUpload;

    public type: ConsentType;
    public status: StatusType;
    public eoi: Eoi;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientConsent>) { (Object as any).assign(this, init); }
}

export interface IPatientConsentDelete extends IDelete
{
    id?: number;
}

export enum SaeStatus
{
    Added = 0,
    New = 1,
    AwaitingForReview = 2,
    Approved = 3,
    Rejected = 4,
    Invalid = 5,
}

export enum SaeRelatedness
{
    Unrelated = 1,
    Unlikely = 2,
    Possible = 3,
    Probable = 4,
    Definite = 5,
}

export enum SaeExpectedness
{
    Expected = 1,
    Unexpected = 2,
}

export class MedicalReviewItem
{
    public id?: number;
    public medicalReviewId?: number;
    public saeTreatmentCategoryId?: number;
    public typeId?: number;
    public dataEntered?: boolean;
    public latestRelatednessId?: number;
    public relatednessId?: SaeRelatedness;
    public expected?: SaeExpectedness;
    public comments: string;
    public notApplicable?: boolean;

    public constructor(init?: Partial<MedicalReviewItem>) { (Object as any).assign(this, init); }
}

export class MedicalReview
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public date?: string;
    public saeStatus?: SaeStatus;
    public narrativeSummary: string;
    public items: MedicalReviewItem[];

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export enum QueryStatus
{
    Issued = 1,
    Responded = 2,
    Closed = 3,
    Cancelled = 4,
}

export class Query implements IDataModel
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public parentQueryId?: number;
    public queryNumber?: number;
    public repeat?: number;
    public question: string;
    public response: string;
    public recipientId?: number;
    public status?: QueryStatus;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Query>) { (Object as any).assign(this, init); }
}

export interface ISaeGetCollection extends IGet
{
}

export interface ISaeGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface ISaeGetSingleByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
    saeNumber?: number;
}

export enum SaeWebActionTypeEnum
{
    MarkAsInvalid = 0,
    NotifiedSiteInvestigators = 1,
    Approved = 2,
    Rejected = 3,
    NotifiedSiteTc = 4,
    Reset = 5,
    NotifiedMedicalReviewers = 6,
    SubmittedMedicalReview = 7,
    NotifiedTeam = 8,
    NotifiedStudyChair = 9,
    NotifiedStudySponsor = 10,
}

export class SaeWebActionType
{
    public id?: number;
    public value: string;

    public constructor(init?: Partial<SaeWebActionType>) { (Object as any).assign(this, init); }
}

export class WebAction
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public actionType?: SaeWebActionTypeEnum;
    public actionDate?: string;
    public userName: string;
    public notes: string;
    public reasonForRejection: string;
    public rejectedAs: number;
    public recipients: string;
    public saeWebActionType: SaeWebActionType;

    public constructor(init?: Partial<WebAction>) { (Object as any).assign(this, init); }
}

export class SaeStudyDrugReviewForm
{
    public studyDrug: string;
    public investigatorAssessment: string;
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeStudyDrugReviewForm>) { (Object as any).assign(this, init); }
}

export class SaeExpectednessForm
{
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeExpectednessForm>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewForm
{
    public studyDrugReviews: SaeStudyDrugReviewForm[];
    public expectedness: SaeExpectednessForm[];
    public isEventStatusDeath: boolean;

    public constructor(init?: Partial<SaeMedicalReviewForm>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FileUploadTemporary implements IFileUploadTemporary
{
    // @DataMember(Order=1)
    public guid: string;

    // @DataMember(Order=2)
    public name: string;

    // @DataMember(Order=3)
    public extension: string;

    // @DataMember(Order=4)
    public fileSize?: number;

    // @DataMember(Order=5)
    public contentType: string;

    // @DataMember(Order=6)
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class SaeDocumentForm
{
    public name: string;
    public file: File;

    public constructor(init?: Partial<SaeDocumentForm>) { (Object as any).assign(this, init); }
}

export enum SaeNotificationType
{
    NotifyInvestigator = 1,
    NotifyMedicalReviewer = 2,
}

export class SaeNotificationForm
{
    public recipients: number[];
    public ccRecipients: number[];
    public notes: string;
    public notifyStudyChair?: boolean;
    public notifySponsor?: boolean;
    public attachments: SaeDocumentForm[];
    public notificationType: SaeNotificationType;
    public additionalData: { [index: string]: string; };

    public constructor(init?: Partial<SaeNotificationForm>) { (Object as any).assign(this, init); }
}

export enum InvalidSaeReason
{
    CriteriaNotMet = 1,
    Other = 2,
}

export class SaeFileUpload
{
    public id?: number;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;
    public exists: boolean;
    public upload: FileUploadTemporary;

    public constructor(init?: Partial<SaeFileUpload>) { (Object as any).assign(this, init); }
}

export class InvalidSaeFile implements IDataModel
{
    public id?: number;
    public invalidSaeFormId?: number;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public fileUpload: SaeFileUpload;

    public constructor(init?: Partial<InvalidSaeFile>) { (Object as any).assign(this, init); }
}

export class InvalidSaeForm implements IDataModel
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public comments: string;
    public date?: string;
    public appliedToOpenClinica?: boolean;
    public dateDeterminedInvalid?: string;
    public reason?: InvalidSaeReason;
    public reasonSpecify: string;
    public decidedById?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public files: InvalidSaeFile[];

    public constructor(init?: Partial<InvalidSaeForm>) { (Object as any).assign(this, init); }
}

export interface IScreeningLogGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningLogGetCollection extends IGet
{
}

export interface IScreeningLogGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export enum ScreeningStatus
{
    Pending = 1,
    Successful = 2,
    Failed = 3,
}

export enum ScreeningOutcome
{
    ProceedToRegistration = 1,
    ScreenFail = 2,
}

export class ScreeningLog implements IScreeningLog
{
    public id?: number;
    public institutionId?: number;
    public patientId?: number;
    public econsentInvitationId?: number;
    public screeningDate?: string;
    public status?: ScreeningStatus;
    public outcome?: ScreeningOutcome;
    public initials: string;
    public dateOfBirth?: string;
    public gender?: number;
    public reason?: number;
    public reasonSpecify: string;
    public patientUsingEconsent?: number;
    public econsentEmail: string;
    public econsentInvestigator: string;
    // @Ignore()
    public patientStudyNumber: string;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningLog>) { (Object as any).assign(this, init); }
}

export interface IScreeningLogDelete extends IDelete
{
    screeningLogId?: number;
}

export interface IScreeningLogGetSingleByPatientId extends IGet
{
    patientId?: number;
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

// @DataContract
export class Patient implements IOpenClinicaPatient, IPreregistrationPatient, IRegistrationPatient
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    // @DataMember(Order=3)
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @DataMember(Order=4)
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public previousPatientStateId?: number;

    // @DataMember(Order=5)
    public patientStatusReason?: string;

    // @DataMember(Order=6)
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    // @DataMember(Order=7)
    public studyNumber: string;

    // @DataMember(Order=8)
    public institutionCode: string;

    // @DataMember(Order=9)
    public preregistrationNumber: string;

    // @DataMember(Order=10)
    public preregistrationDate?: string;

    // @DataMember(Order=11)
    public registrationNumber: string;

    // @DataMember(Order=12)
    public registrationDate?: string;

    // @DataMember(Order=13)
    public initials: string;

    // @DataMember(Order=14)
    // @Ignore()
    public skipEmailingPatient?: boolean;

    // @DataMember(Order=15)
    public exportedToOpenClinica?: string;

    // @DataMember(Order=16)
    public preregisteredByWaiver?: boolean;

    // @DataMember(Order=17)
    public registeredByWaiver?: boolean;

    // @DataMember(Order=18)
    public enteredDate?: string;

    // @DataMember(Order=19)
    public enteredBy: string;

    // @DataMember(Order=20)
    public modifiedDate?: string;

    // @DataMember(Order=21)
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class SourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class WaiverSourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waiverType?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<WaiverSourceDocument>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public genericMedicationName: string;
    public indication: string;
    public startDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class Consent implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantSignedAndDatedConsentForm?: number;
    public dateParticipantSignedConsentForm?: string;
    public twoFfpeTumourBlocksAvailable?: number;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<Consent>) { (Object as any).assign(this, init); }
}

export class Demographics implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public gender?: number;
    public dateOfBirth?: string;
    public participantInitials: string;
    public postcode: string;
    public indigenousStatus?: number;
    public countryOfBirth?: number;
    public countryOfBirthOther: string;
    public primarySpokenLanguage?: number;
    public primarySpokenLanguageOther: string;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<Demographics>) { (Object as any).assign(this, init); }
}

export class EligibilityChecklist implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public confirmedHer2Positive?: number;
    public previouslyReceivedTaxane?: number;
    public progressionOfBreastCancer?: number;
    public lifeExpectancyAtleast6Months?: number;
    public agreeOnContraception?: number;
    public willingToComplyProtocol?: number;
    public previouslyTreatedWithLapatinib?: number;
    public priorAntiPd1?: number;
    public previousSevereHypersensitivity?: number;
    public knownOrSuspectedLeptomeningeal?: number;
    public havePoorlyControlledSeizures?: number;
    public historyOfCardiacDisease?: number;
    public activeOrHistoryOfAutoimmuneDisease?: number;
    public knownHivOrActiveHepatitis?: number;
    public stoppedBreastFeeding?: number;
    public requireTherapyWithWarfarin?: number;
    public significantGastrointestinalDisease?: number;
    public historyOrActiveLungDisease?: number;
    public activeTuberculosis?: number;
    public hadTissueOrganTransplant?: number;
    public uncontrolledHypertension?: number;
    public anyConditionConfoundingResults?: number;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<EligibilityChecklist>) { (Object as any).assign(this, init); }
}

export class General implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public physicalExaminationDate?: string;
    public height?: number;
    public weight?: number;
    public ecog?: number;
    public menopausalStatus?: number;
    // @Ignore()
    public age?: number;

    public bilateralOophorectomy?: boolean;
    public bilateralSalpingectomy?: boolean;
    public hysterectomy?: boolean;
    public postmenopausalTestDate?: string;
    public postmenopausalLevelsLh?: number;
    public postmenopausalLevelsLhLln?: number;
    public postmenopausalLevelsLhLlnNa?: boolean;
    public postmenopausalLevelsFsh?: number;
    public postmenopausalLevelsFshLln?: number;
    public postmenopausalLevelsFshLlnNa?: boolean;
    public postmenopausalLevelsOestradiol?: number;
    public postmenopausalLevelsOestradiolLln?: number;
    public postmenopausalLevelsOestradiolUln?: number;
    public postmenopausalLevelsOestradiolUlnNa?: boolean;
    public preOrPeriMenopausalPregnancyTest?: number;
    public preOrPeriMenopausalPregnancyTestDate?: string;
    public preOrPeriMenopausalPregnancyTestResult?: number;
    public lvef?: number;
    public lvefDate?: string;
    public lvefValue?: number;
    public ecgDate?: string;
    public ecgQctfValue?: number;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<General>) { (Object as any).assign(this, init); }
}

export class TargetLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public targetLesionNumber?: number;
    public organSite?: number;
    public organSiteSpecify: string;
    public lesionLocation: string;
    public methodOfMeasurement?: number;
    public measurement?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TargetLesion>) { (Object as any).assign(this, init); }
}

export class NonTargetLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public nonTargetLesionNumber?: number;
    public organSite?: number;
    public organSiteSpecify: string;
    public lesionLocation: string;
    public methodOfMeasurement?: number;
    public otherSpecify?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<NonTargetLesion>) { (Object as any).assign(this, init); }
}

export class ImagingAndTumourAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ctScanNotDone?: boolean;
    public ctScanDate?: string;
    public ctScanPositive?: number;
    public mriNotDone?: boolean;
    public mriDate?: string;
    public mriPositive?: number;
    public mriBrainNotDone?: boolean;
    public mriBrainDate?: string;
    public mriBrainPositive?: number;
    public ctScanBrainNotDone?: boolean;
    public ctScanBrainDate?: string;
    public ctScanBrainPositive?: number;
    public brainMetastases?: number;
    public largeLesionSize?: number;
    public hasApprovalGiven?: number;
    public localTherapyRequired?: number;
    public stableSinceTreatment?: number;
    public timeSinceWholeBrainRt?: number;
    public timeSinceStereotacticRt?: number;
    public otherSitesAssessable?: number;
    public poorlyControlledSeizures?: number;
    public chestXrayNotDone?: boolean;
    public chestXrayDate?: string;
    public chestXrayPositive?: number;
    public boneScanNotDone?: boolean;
    public boneScanDate?: string;
    public boneScanPositive?: number;
    public otherAssessmentNotDone?: boolean;
    public otherAssessmentSpecify: string;
    public otherAssessmentDate?: string;
    public otherAssessmentPositive?: number;
    public targetLesions: TargetLesion[];
    public sumOfTargetLesions?: number;
    public nonTargetLesions: NonTargetLesion[];
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<ImagingAndTumourAssessment>) { (Object as any).assign(this, init); }
}

export class LaboratoryAssessments implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public thyroidFunctionDate?: string;
    public tsh?: number;
    public t3?: number;
    public t4?: number;
    public hepatitisDate?: string;
    public hepatitisSameAsAbove?: boolean;
    public hbcab?: number;
    public hbsag?: number;
    public hbvDna?: number;
    public hcvRna?: number;
    public urinalysisDate?: string;
    public urinalysisSameAsAbove?: boolean;
    public urinalysisResult?: number;
    public urinalysisPlannedTreatment?: number;
    public urinalysisPlannedTreatmentSpecify: string;
    public haematologyDate?: string;
    public haemoglobin?: number;
    public haematocrit?: number;
    public pcv?: number;
    public platelets?: number;
    public rbcCount?: number;
    public wbcCount?: number;
    public absoluteNeutrophilCount?: number;
    public lymphocytes?: number;
    public monocytes?: number;
    public eosinophils?: number;
    public basophils?: number;
    public biochemistryDate?: string;
    public biochemistrySameAsAbove?: boolean;
    public gilbertSyndrome?: number;
    public liverMetastases?: number;
    public sodium?: number;
    public potassium?: number;
    public chloride?: number;
    public serumUrea?: number;
    public serumCreatinine?: number;
    public serumCreatinineLln?: number;
    public serumCreatinineUln?: number;
    public calculatedCreatinine?: number;
    public calculatedCreatinineLln?: number;
    public totalProtein?: number;
    public albumin?: number;
    public totalBilirubin?: number;
    public totalBilirubinUln?: number;
    public alt?: number;
    public altUln?: number;
    public ast?: number;
    public astUln?: number;
    public ggt?: number;
    public alp?: number;
    public ldh?: number;
    public correctedCalcium?: number;
    public magnesium?: number;
    public phosphate?: number;
    public randomGlucose?: number;
    public amylase?: number;
    public lipase?: number;
    public cortisol?: number;
    public cortisolDate?: string;
    public cReactiveProtein?: number;
    public coagulationDate?: string;
    public coagulationSameAsAbove?: boolean;
    public inr?: number;
    public inrUln?: number;
    public appt?: number;
    public apptUln?: number;
    public ppt?: number;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<LaboratoryAssessments>) { (Object as any).assign(this, init); }
}

export class MedicalCondition implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public ctcaeTermId?: number;
    public ctcaeGradeId?: number;
    public ctcaeSpecification: string;
    public currentAtRegistration?: number;
    public dateOfDiagnosis: string;
    public treatmentOngoing?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalCondition>) { (Object as any).assign(this, init); }
}

export class OtherMedicalCondition implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicalCondition: string;
    public currentAtRegistration?: number;
    public dateOfDiagnosis: string;
    public treatmentOngoing?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OtherMedicalCondition>) { (Object as any).assign(this, init); }
}

export class MedicalHistoryAndConMeds implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public anyRelatedToxicity?: number;
    public activeInfection?: number;
    public receivedTreatment?: number;
    public ongoingSystemicCorticosteroids?: number;
    public totalDailyDose?: number;
    public approvalForChronicStableDose?: number;
    public useOfStrongCyp2c8?: number;
    public treatmentWithBotanicalPreparations?: number;
    public liveAttenuatedVaccine?: number;
    public haveContraceptivesStopped?: number;
    public medicalConditions: MedicalCondition[];
    public otherMedicalConditions: OtherMedicalCondition[];
    public concomitantMedications: ConcomitantMedication[];
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<MedicalHistoryAndConMeds>) { (Object as any).assign(this, init); }
}

export class PreviousTreatment implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public therapyType?: number;
    public therapyTypeSpecify: string;
    public diseaseSetting?: number;
    public startDate?: string;
    public endDate?: string;
    public discontinuationReason?: number;
    public discontinuationReasonSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PreviousTreatment>) { (Object as any).assign(this, init); }
}

export class PathologyAndPriorTreatment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfHistopathologicalDiagnosis?: string;
    public oestrogenSample?: number;
    public oestrogenSampleCollectionDate?: string;
    public oestrogenPercentageOfCellsStained?: number;
    public oestrogenPercentageOfCellsStainedValue?: number;
    public progesteroneSample?: number;
    public progesteroneSampleCollectionDate?: string;
    public progesteronePercentageOfCellsStained?: number;
    public progesteronePercentageOfCellsStainedValue?: number;
    public her2Sample?: number;
    public her2SampleCollectionDate?: string;
    public her2IhcScore?: number;
    public her2Ish?: number;
    public erbb2CentromeresNotDone?: boolean;
    public erbb2Centromeres?: number;
    public her2CopyNumberNotDone?: boolean;
    public her2CopyNumber?: number;
    public brcaGermlineStatus?: number;
    public patientProgressOrIntolerant?: number;
    public previousTreatments: PreviousTreatment[];
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<PathologyAndPriorTreatment>) { (Object as any).assign(this, init); }
}

export class PdlAndTilResult implements IForm
{
    public id?: number;
    public notRequired: boolean;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public sampleResubmitted?: boolean;
    public tumourBlockId: string;
    public centralLabId: string;
    public dateReceivedAtCentralLab?: string;
    public dateExpected?: string;
    public specifyNewExpectedDate?: boolean;
    public newExpectedDate?: string;
    public newExpectedDateReason: string;
    public datePdlStainingCompleted?: string;
    public dateTilStainingCompleted?: string;
    public datePdlAssayAndTilCountReported?: string;
    public pdlAssaySuccessful?: number;
    public pdlAssayNotSuccessfulReason?: number;
    public pdlAssayNotSuccessfulReasonSpecify: string;
    public pdlAssayResult?: number;
    public pdlAssayResultNoResult?: boolean;
    public tilCountSuccessful?: number;
    public tilCountNotSuccessfulReason?: number;
    public tilCountNotSuccessfulReasonSpecify: string;
    public tilCountResult?: number;
    public tilCountNoResult?: boolean;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PdlAndTilResult>) { (Object as any).assign(this, init); }
}

export class PdlAndTilResultForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public pdlAndTilResults: PdlAndTilResult[];
    public noMoreTissueAvailable?: boolean;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<PdlAndTilResultForm>) { (Object as any).assign(this, init); }
}

export class TumourBlock implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public patientStateId?: number;
    public sampleNumber?: number;
    public sampleType?: number;
    public sampleSite?: number;
    public sampleSiteSpecify: string;
    public dateCollected?: string;
    public ffpeBlockOrSlides?: number;
    public dateSlidesWereCut?: string;
    public tumourBlockId: string;
    public slideIdStart: string;
    public slideIdEnd: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TumourBlock>) { (Object as any).assign(this, init); }
}

export class TumourBlockShipment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public trackingNumber: string;
    public dateShipped?: string;
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<TumourBlockShipment>) { (Object as any).assign(this, init); }
}

export class TumourBlockSubmission implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public tumourBlocks: TumourBlock[];
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<TumourBlockSubmission>) { (Object as any).assign(this, init); }
}

export class FailedEligibilityCriteria implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waiverType?: number;
    public formName: string;
    public fieldName: string;
    public details: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FailedEligibilityCriteria>) { (Object as any).assign(this, init); }
}

export class PatientStatusCriteria implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public formName: string;
    public fieldName: string;
    public details: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientStatusCriteria>) { (Object as any).assign(this, init); }
}

export class PatientStatusForm implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public markAsIneligible?: boolean;

    public reason: string;
    public patientStatusCriteria: PatientStatusCriteria[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientStatusForm>) { (Object as any).assign(this, init); }
}

export class Waiver implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waiverType?: number;
    public approvedBy?: number;
    // @Ignore()
    public approvedByValue: string;

    public approvedByOther: string;
    public dateWaiverGranted?: string;
    public reason: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Waiver>) { (Object as any).assign(this, init); }
}

export class WaiverForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public waivers: Waiver[];
    public failedEligibilityCriteria: FailedEligibilityCriteria[];
    public sourceDocuments: WaiverSourceDocument[];
    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<WaiverForm>) { (Object as any).assign(this, init); }
}

export interface IPatientGetEventStatesById extends IGet
{
    id?: number;
}

export interface IPatientGetEventStatesByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetFormStatesByIds extends IGet
{
    id?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EConsentView = 40,
    EConsentAdminister = 41,
    EConsentManage = 42,
    PISignOffView = 43,
    PISignOffPerform = 44,
    PISignOffAdminister = 45,
    OpmsDataManage = 46,
    RedactVerificationAccess = 47,
    ReconsentAdminister = 48,
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export class ContactAddress
{
    public contactId?: number;
    public addressId?: number;
    public addressType?: number;
    public street: string;
    public city: string;
    public state: string;
    public postalCode: string;
    public countryOrRegion: string;
    public preferred?: number;

    public constructor(init?: Partial<ContactAddress>) { (Object as any).assign(this, init); }
}

export class ContactEmail
{
    public contactId?: number;
    public emailId?: number;
    public email: string;
    public preferred?: number;

    public constructor(init?: Partial<ContactEmail>) { (Object as any).assign(this, init); }
}

export class ContactPhone
{
    public contactId?: number;
    public phoneId?: number;
    public phoneNum: string;
    public phoneType?: number;
    public preferred?: number;

    public constructor(init?: Partial<ContactPhone>) { (Object as any).assign(this, init); }
}

export class Contact
{
    public id?: number;
    public title: string;
    public givenName: string;
    public middleName: string;
    public surname: string;
    public suffix: string;
    public jobTitle: string;
    public profession: string;
    public organisation: string;
    public businessHomePage: string;
    public postalAddressIndex?: number;
    public birthday?: string;
    public manager: string;
    public assistantName: string;
    public displayName: string;
    public fileAs: string;
    public notes: string;
    public department: string;
    public imAddress: string;
    public nickname: string;
    public officeLocation: string;
    public spouseName: string;
    public weddingAnniversary?: string;
    public outlookId: string;
    public outlookDateEntered?: string;
    public outlookDateModified?: string;
    public dateEntered?: string;
    public dateModified?: string;
    public userEntered: string;
    public userModified: string;
    public inactive?: number;
    public preferred: string;
    public pronunciation: string;
    public maiden: string;
    public personalAssistant: string;
    public leaveNotes: string;
    public partTimeNotes: string;
    public pendingInactive?: number;
    public authorised?: boolean;
    public authorisedBy: string;
    public datePendingInactive?: string;
    public addresses: ContactAddress[];
    public emails: ContactEmail[];
    public phones: ContactPhone[];

    public constructor(init?: Partial<Contact>) { (Object as any).assign(this, init); }
}

export class LiverFunctionTestingInformation
{
    public studyNumber: string;
    public eventName: string;
    public dueDate: string;

    public constructor(init?: Partial<LiverFunctionTestingInformation>) { (Object as any).assign(this, init); }
}

export enum PatientStateType
{
    NewPatient = 1,
    Preregistered = 2,
    Registered = 3,
    Ineligible = 7,
    Waivered = 8,
    Removed = 9,
}

export enum EventDefinitionType
{
    Preregistration = 1,
    ShipmentDetails = 2,
    Registration = 3,
    PdlTilResult = 4,
    Staff = 5,
}

export enum FormDefinitionType
{
    Demographics = 1,
    Consent = 2,
    EligibilityChecklist = 3,
    TumourBlockSubmission = 4,
    TumourBlock = 5,
    TumourBlockShipment = 6,
    General = 7,
    PathologyAndPriorTreatment = 8,
    PreviousTreatment = 9,
    ImagingAndTumourAssessment = 10,
    TargetLesion = 11,
    NonTargetLesion = 12,
    LaboratoryAssessments = 13,
    MedicalHistoryAndConMeds = 14,
    MedicalCondition = 15,
    OtherMedicalCondition = 16,
    ConcomitantMedication = 17,
    PdlAndTilResultForm = 18,
    PdlAndTilResult = 19,
    SourceDocument = 20,
    WaiverForm = 21,
    FailedEligibilityCriteria = 22,
    WaiverSourceDocument = 23,
    Waiver = 24,
    PatientStatusForm = 25,
    PatientStatusCriteria = 26,
}

export enum TreatmentType
{
    Pdl1PositiveCohort = 1,
    Pdl1NegativeCohort = 2,
    Pdl1Pending = 3,
}

export enum LookupYesNoType
{
    No = 0,
    Yes = 1,
}

export enum LookupLanguageType
{
    English = 1,
    Other = 2,
}

export enum LookupIndigenousStatusType
{
    AboriginalAndOrTorresStraitIslander = 1,
    NeitherAboriginalAndOrTorresStraitIslander = 2,
    Unknown = 3,
}

export enum LookupGenderType
{
    Female = 1,
    Male = 2,
    NonBinary = 3,
}

export enum LookupCountryType
{
    Australia = 1,
    Other = 2,
}

export enum LookupUntypedType
{
    NoType = 1,
}

export enum LookupSampleType
{
    NewlyTaken = 1,
    PrimaryArchival = 2,
}

export enum LookupEchoMugaType
{
    Echo = 1,
    Muga = 2,
}

export enum LookupEcogType
{
    Ecog0 = 1,
    Ecog1 = 2,
    Ecog2 = 3,
    Ecog3 = 4,
    Ecog4 = 5,
}

export enum LookupMenopausalStatusType
{
    NotApplicable = 1,
    PostMenopausal = 2,
    PreOrPeriMenopausal = 3,
    SurgicalSterilisation = 4,
}

export enum LookupPostMenopausalType
{
    BilateralOophorectomy = 1,
    BilateralSalpingectomy = 2,
    Hysterectomy = 3,
    BilateralOvarianRadiation = 4,
    NotHadOophorectomyOrRadiation = 5,
}

export enum LookupPreOrPeriMenopausalType
{
    BilateralSalpingectomy = 1,
    Hysterectomy = 2,
    ChildbearingPotential = 3,
}

export enum LookupAgeType
{
    Below50 = 1,
    NotBelow50 = 2,
}

export enum LookupBrcaType
{
    Unknown = 1,
    Brca1Mutation = 2,
    Brca2Mutation = 3,
    NoMutation = 4,
}

export enum LookupCurrentDiseaseType
{
    DeNovoLocoRegional = 1,
    DeNovoMetastatic = 2,
    RecurrentLocoRegional = 3,
    RecurrentMetastatic = 4,
}

export enum LookupDiscontinuationReasonType
{
    ProgressiveDisease = 1,
    IntolerantToTherapy = 2,
    Other = 3,
    CompletedAdjuvantCourse = 4,
}

export enum LookupDiseaseSettingType
{
    NeoAdjuvant = 1,
    Advanced = 2,
}

export enum LookupIhcScoreType
{
    ND = 1,
    IhcScore0 = 2,
    IhcScore1 = 3,
    IhcScore2 = 4,
    IhcScore3 = 5,
}

export enum LookupIshType
{
    ND = 1,
    Negative = 2,
    Positive = 3,
}

export enum LookupLessGreaterThanType
{
    LessThan = 1,
    GreaterThan = 2,
    Equal = 3,
}

export enum LookupPrimaryMetastaticType
{
    Primary = 1,
    Metastatic = 2,
}

export enum LookupProgressionIntolerantType
{
    Progression = 1,
    Intolerant = 2,
}

export enum LookupTherapyType
{
    Taxane = 1,
    Trastuzumab = 2,
    Pertuzumab = 3,
    ADC = 4,
    Other = 5,
}

export enum LookupBrainMetastasesType
{
    Untreated = 1,
    Treated = 2,
}

export enum LookupMethodOfMeasurementType
{
    ClinicalExamination = 1,
    ChestXray = 2,
    CtChest = 3,
    MriChest = 4,
    CtBrain = 5,
    MriBrain = 6,
    BoneScan = 7,
    CtScan = 8,
    Cytology = 9,
    MriPetScan = 10,
    Other = 11,
}

export enum LookupOrganSiteType
{
    Breast = 1,
    Liver = 2,
    Skin = 3,
    Bone = 4,
    Ovary = 5,
    Lung = 6,
    LymphNode = 7,
    SoftTissue = 8,
    Brain = 9,
    CNS = 10,
    OtherOrgan = 11,
}

export enum LookupAbnormalityType
{
    Normal = 1,
    Abnormal = 2,
}

export enum LookupDetectedType
{
    Detected = 1,
    NotDetected = 2,
    NotDone = 3,
}

export enum LookupPositiveNegativeType
{
    Negative = 1,
    Positive = 2,
    NA = 3,
}

export enum LookupPlannedTreatmentType
{
    None = 1,
    OtherSpecify = 2,
}

export enum LookupUnitsType
{
    Percent = 1,
    Ampule = 2,
    Capsule = 3,
    Drop = 4,
    Gram = 5,
    Grain = 6,
    IU = 7,
    Microgram = 8,
    Milligram = 9,
    Millilitre = 10,
    Puff = 11,
    Inhalation = 12,
    Spray = 13,
    Tablespoon = 14,
    Tablet = 15,
    Unit = 16,
    Unknown = 17,
}

export enum LookupFrequencyType
{
    AsNeeded = 1,
    OnceADay = 2,
    TwiceADay = 3,
    ThreeTimesADay = 4,
    FourTimesADay = 5,
    EveryOtherDay = 6,
    OnceAWeek = 7,
    TwiceAWeek = 8,
    ThreeTimesAWeek = 9,
    EveryTwoWeeks = 10,
    EveryMonth = 11,
    Unknown = 12,
    Other = 13,
}

export enum LookupRouteType
{
    Epidural = 1,
    Inhaled = 2,
    IntraArticular = 3,
    Intramuscular = 4,
    Intranasal = 5,
    Intravenous = 6,
    Intravitreal = 7,
    Nasogastric = 8,
    Ophthalmic = 9,
    Oral = 10,
    Otic = 11,
    PerRectum = 12,
    PerVagina = 13,
    Subcutaneous = 14,
    Sublingual = 15,
    Topical = 16,
    Transdermal = 17,
    Unknown = 18,
}

export enum LookupIndicationType
{
    Treatment = 1,
    Prophylaxis = 2,
}

export enum LookupNotSuitableReasonType
{
    InsufficientQuantity = 1,
    Other = 2,
}

export enum LookupPdlResultType
{
    PdlPositive = 1,
    PdlNegative = 2,
}

export enum LookupScreeningLogReasonType
{
    ParticipantIneligible = 1,
    ClinicianDeemedUnsuitable = 2,
    ParticipantDeclinedTrialStandardCare = 3,
    ParticipantDeclinedTrialNoReason = 4,
}

export enum LookupSampleSiteType
{
    Brain = 1,
    Breast = 2,
    Bone = 3,
    Cns = 4,
    Gastrointestinal = 5,
    Genitourinary = 6,
    HeadAndNeck = 7,
    Liver = 8,
    Lung = 9,
    LymphNode = 10,
    Ovary = 11,
    Skin = 12,
    SoftTissue = 13,
    Other = 14,
}

export enum LookupFfpeType
{
    Block = 1,
    Slides = 2,
}

export enum LookupLargestLegionSizeType
{
    LessThanOrEqualTwo = 1,
    GreaterThanTwo = 2,
}

export enum LookupDailyDoseType
{
    LessThanOrEqualTwo = 1,
    GreaterThanTwo = 2,
}

export enum LookupApprovedByType
{
    Other = 0,
    StudyChair = 1,
    MedicalReviewer = 2,
}

export enum LookupWaiverType
{
    Preregistration = 1,
    Registration = 2,
}

export enum LookupWaiverApprovedByType
{
    DataManagement = 1,
    TrialDepartmentManager = 2,
    Other = 3,
}

export class RequisitionFormData
{
    public studyNumber: string;
    public institutionCode: string;
    public institutionName: string;
    public sampleNumber?: number;
    public sampleType: string;
    public dateCollected?: string;
    public tumourBlockId: string;

    public constructor(init?: Partial<RequisitionFormData>) { (Object as any).assign(this, init); }
}

export class DrugStockData
{
    public drugName: string;
    public batchNo: string;
    public dateExpiration?: string;
    public drugUnitsAvailable: number;

    public constructor(init?: Partial<DrugStockData>) { (Object as any).assign(this, init); }
}

export class DrugShipmentReport
{
    public institutionCode: string;
    public institutionName: string;
    public pharmacyAddress: string;
    public shipmentNumber: string;
    public eventDate: string;
    public batches: string;
    public pembrolizumab?: number;
    public tucatinib150?: number;
    public tucatinib50?: number;
    public status: string;

    public constructor(init?: Partial<DrugShipmentReport>) { (Object as any).assign(this, init); }
}

export class PatientAction
{
    public institutionCode: string;
    public institutionName: string;
    public randNo: string;
    public visit: string;
    public eventDate: string;
    public batches: string;
    public pembrolizumab?: number;
    public tucatinib150?: number;
    public tucatinib50?: number;
    public patientStatus: string;

    public constructor(init?: Partial<PatientAction>) { (Object as any).assign(this, init); }
}

export class BatchReport
{
    public batch: string;
    public randNo: string;
    public visit: string;
    public dispensationDate: string;
    public drugs?: number;

    public constructor(init?: Partial<BatchReport>) { (Object as any).assign(this, init); }
}

export class DrugManagementLog
{
    public institutionName: string;
    public date?: string;
    public participant: string;
    public action: string;

    public constructor(init?: Partial<DrugManagementLog>) { (Object as any).assign(this, init); }
}

export class MonthlySeagenData
{
    public participantNumber: string;
    public saeNo?: number;
    public cohort: string;
    public ctcaeTerm: string;
    public saeGrade?: number;
    public dateReported: string;
    public dateOfOnset: string;
    public eventStatus: string;
    public eventStatusDate?: string;
    public drugName: string;
    public related: string;
    public action: string;
    public expectedness: string;
    public drugDose: string;
    public comments: string;
    public datesOfTreatment: string;

    public constructor(init?: Partial<MonthlySeagenData>) { (Object as any).assign(this, init); }
}

export class AESIData
{
    public patientId: string;
    public ctcaeTerm: string;
    public serious: string;
    public aesi: string;
    public causality: string;
    public eventOnset: string;
    public outcome: string;
    public dateFirstDose: string;
    public dateLastDose: string;
    public actionTaken: string;
    public isNew: boolean;
    public bctGrade: string;
    public ordinal?: number;
    public cohort: string;
    public dateUpdated?: string;

    public constructor(init?: Partial<AESIData>) { (Object as any).assign(this, init); }
}

export class FormsDueEvent
{
    public initials: string;
    public studyNumber: string;
    public institutionCode: string;
    public institutionName: string;
    public eventName: string;
    public eventRepeat?: number;
    public visitDate: string;
    public dueDate: string;
    public formsDue: string;

    public constructor(init?: Partial<FormsDueEvent>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class DrugManagementValidationError implements IDrugManagementValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<DrugManagementValidationError>) { (Object as any).assign(this, init); }
}

export class DrugManagementValidationResult
{
    public result: ValidationResultType;
    public errors: DrugManagementValidationError[];

    public constructor(init?: Partial<DrugManagementValidationResult>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class DrugManagementValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: DrugManagementValidationResult;

    public constructor(init?: Partial<DrugManagementValidationResponse>) { (Object as any).assign(this, init); }
}

export interface IPharmacist
{
    id?: number;
    title: string;
    firstName: string;
    lastName: string;
    deliveryAddress: string;
    deliveryStreet2: string;
    deliverySuburb: string;
    deliveryState: string;
    deliveryPostCode: string;
    deliveryCountry: string;
    email: string;
    phone: string;
    fax: string;
}

export class PharmacyBatchDrugRemaining
{
    public pharmacy: Pharmacy;
    public batch: Batch;
    public drugRemaining?: number;
    public drugAllocated?: number;
    public drugUnits?: number;
    public drugUnitsUsed?: number;
    public drugUnitsDamaged?: number;
    public drugUnitsDestroyed?: number;
    public drugUnitsLost?: number;
    public drugUnitsQuarantined?: number;

    public constructor(init?: Partial<PharmacyBatchDrugRemaining>) { (Object as any).assign(this, init); }
}

export class PicfType
{
    public id?: number;
    public name: string;
    public label: string;
    public fileName: string;

    public constructor(init?: Partial<PicfType>) { (Object as any).assign(this, init); }
}

export class Picf
{
    public type: PicfType;
    public picfId?: number;
    public name: string;
    public label: string;
    public version: string;
    public fileName: string;
    public file: string;

    public constructor(init?: Partial<Picf>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationError implements ISaeFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<SaeFormValidationError>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationResult
{
    public result: ValidationResultType;
    public errors: SaeFormValidationError[];

    public constructor(init?: Partial<SaeFormValidationResult>) { (Object as any).assign(this, init); }
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export enum SaeMedicalReviewStatus
{
    NotNotified = 0,
    Pending = 1,
    Reviewed = 3,
}

export class Sae<TSaeForm, TPatient>
{
    public patient: TPatient;
    public saeNumber?: number;
    public status: SaeStatus;
    public form: TSaeForm;
    public ecrfs: TSaeForm[];
    public saeFormItems: { [index: string]: string; };
    public invalidForm: InvalidSaeForm;
    public medicalReview: MedicalReview;
    public medicalReviewStatus: SaeMedicalReviewStatus;
    public actionHistory: WebAction[];
    public lookups: Lookup[];

    public constructor(init?: Partial<Sae<TSaeForm, TPatient>>) { (Object as any).assign(this, init); }
}

export interface ISaeForm extends IModel
{
    patientId?: number;
    saeNumber?: number;
    followUpNumber?: number;
    eventStatus: string;
    dateCreated?: string;
    dateUpdated?: string;
    unassignedProperties: { [index: string]: Object; };
    inconvertableProperties: { [index: string]: string; };
}

export class ScreeningLogFormValidationError implements IScreeningLogFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningLogFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningLogFormValidationError[];

    public constructor(init?: Partial<ScreeningLogFormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public eConsentActivationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export class EventState implements IEventState
{
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;

    public constructor(init?: Partial<EventState>) { (Object as any).assign(this, init); }
}

export class FormState implements IFormState
{
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;

    public constructor(init?: Partial<FormState>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnel
{
    public id?: number;
    public name: string;
    public email: string;

    public constructor(init?: Partial<InstitutionPersonnel>) { (Object as any).assign(this, init); }
}

export interface IDrugManagementForm
{
}

export interface IEoi extends IDataModel
{
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    invitationId?: number;

    firstName: string;
    lastName: string;
    confirmed?: boolean;
    dateSubmitted?: string;
    statusId?: StatusTypeEnum;
    invitation: Invitation;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IPatientConsent extends IDataModel
{
    eoiId?: number;
    typeId?: ConsentTypeEnum;
    statusId?: StatusTypeEnum;
    patientId?: number;
    guid: string;
    dateStarted?: string;
    documentVerified?: boolean;
    documentVerifiedBy?: string;
    dateDocumentVerified?: string;
    mobileNumber: string;
    patientSigned?: boolean;
    datePatientSigned?: string;
    investigatorSigned?: boolean;
    dateInvestigatorSigned?: string;
    dateNotificationSent?: string;
    type: ConsentType;
    status: StatusType;
    eoi: IEoi;
    picfs: ConsentPicf[];
}

export interface IScreeningLog extends IDataModel
{
    institutionId?: number;
    patientId?: number;
    screeningDate?: string;
    outcome?: ScreeningOutcome;
    status?: ScreeningStatus;
    initials: string;
    dateOfBirth?: string;
    reason?: number;
    reasonSpecify: string;
}

export interface IOpenClinicaPatient extends IPatient
{
    exportedToOpenClinica?: string;
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IPreregistrationPatient
{
    preregistrationNumber: string;
    preregistrationDate?: string;
}

export interface IRegistrationPatient
{
    registrationNumber: string;
    registrationDate?: string;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IOpenClinicaForm extends IForm
{
    exportedToOpenClinica?: string;
}

export interface IDrugManagementValidationError extends IValidationError
{
    property: string;
}

export interface IDrugManagementValidationResult extends IValidationResult
{
    errors: IDrugManagementValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface ISaeFormValidationError extends IValidationError
{
    property: string;
}

export interface ISaeFormValidationResult extends IValidationResult
{
    errors: ISaeFormValidationError[];
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export class LastDoseStudyDrug implements ISaeSubform
{
    public ordinal: number;
    public drugName: string;
    public drugDose: string;
    public drugRoute: string;
    public drugDateInitiallyCommenced?: string;
    public drugDateLastAdmin?: string;
    public drugRelatedToEvent: string;
    public drugActionTaken: string;

    public constructor(init?: Partial<LastDoseStudyDrug>) { (Object as any).assign(this, init); }
}

export class LastDoseConcomitantDrug implements ISaeSubform
{
    public ordinal: number;
    public medicationName: string;
    public medicationDose: string;
    public medicationDateInitiallyCommenced?: string;
    public medicationDateLastAdmin?: string;
    public medicationRelatedToEvent: string;

    public constructor(init?: Partial<LastDoseConcomitantDrug>) { (Object as any).assign(this, init); }
}

export class TreatmentOfEvent implements ISaeSubform
{
    public ordinal: number;
    public treatmentName: string;
    public treatmentDose: string;
    public treatmentDateCommenced?: string;
    public treatmentOngoing?: boolean;
    public treatmentDateCeased?: string;

    public constructor(init?: Partial<TreatmentOfEvent>) { (Object as any).assign(this, init); }
}

export class SourceDocuments implements ISaeSubform, ISourceDocument
{
    public ordinal: number;
    public type: string;
    public name: string;
    public document: string;

    public constructor(init?: Partial<SourceDocuments>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeForm implements ISaeForm
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=2)
    public patientId?: number;

    // @DataMember(Order=3)
    public saeNumber?: number;

    // @DataMember(Order=4)
    public followUpNumber?: number;

    // @DataMember(Order=5)
    public siteInvestigator: string;

    // @DataMember(Order=6)
    public patient: string;

    // @DataMember(Order=7)
    public saeNo?: number;

    // @DataMember(Order=8)
    public dateOfOnset?: string;

    // @DataMember(Order=9)
    public eventName: string;

    // @DataMember(Order=10)
    public eventGrade?: number;

    // @DataMember(Order=11)
    public descriptionOfEvent: string;

    // @DataMember(Order=12)
    public reportingResultsInDeath?: boolean;

    // @DataMember(Order=13)
    public reportingLifeThreatening?: boolean;

    // @DataMember(Order=14)
    public requiresHospitalisation?: boolean;

    // @DataMember(Order=15)
    public reportingDisability?: boolean;

    // @DataMember(Order=16)
    public reportingAnomaly?: boolean;

    // @DataMember(Order=17)
    public reportingSignificant?: boolean;

    // @DataMember(Order=18)
    public eventStatus: string;

    // @DataMember(Order=19)
    public eventStatusDate?: string;

    // @DataMember(Order=20)
    public relevantTests: string;

    // @DataMember(Order=21)
    public additionalInfo: string;

    // @DataMember(Order=22)
    public investigatorName: string;

    // @DataMember(Order=23)
    public investigatorPhoneNo: string;

    // @DataMember(Order=24)
    public dateCreated?: string;

    // @DataMember(Order=25)
    public dateUpdated?: string;

    // @DataMember(Order=26)
    public lastDoseStudyDrugs: LastDoseStudyDrug[];

    // @DataMember(Order=27)
    public lastDoseConcomitantDrugs: LastDoseConcomitantDrug[];

    // @DataMember(Order=28)
    public treatments: TreatmentOfEvent[];

    // @DataMember(Order=29)
    public sourceDocuments: SourceDocuments[];

    // @DataMember(Order=30)
    public unassignedProperties: { [index: string]: Object; };

    // @DataMember(Order=31)
    public inconvertableProperties: { [index: string]: string; };

    // @DataMember(Order=32)
    public aerNo: string;

    public constructor(init?: Partial<SaeForm>) { (Object as any).assign(this, init); }
}

export interface IScreeningLogFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningLogFormValidationResult extends IValidationResult
{
    errors: IScreeningLogFormValidationError[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
    eConsentActivationDate?: string;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IEventState
{
    eventDefinitionId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
}

export interface IFormState
{
    eventDefinitionId?: number;
    eventRepeat?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface IFile extends IDataModel, IHasPatientId, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface ISaeSubform
{
    ordinal: number;
}

export interface ISourceDocument
{
    type: string;
    name: string;
    document: string;
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class SendDrugShipmentCreatedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentCreatedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDrugShipmentOrderedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDrugShipmentOrderedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendStagnantDrugOrdersEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendStagnantDrugOrdersEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendStagnantDrugShipmentsEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendStagnantDrugShipmentsEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendExpiringStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendExpiringStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSiteExpiringStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSiteExpiringStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSiteAutoQuarantiningDrugEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSiteAutoQuarantiningDrugEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLowStockEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLowStockEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLateDispensationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLateDispensationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientsOffTreatmentResponse
{
    public patientsMarkedOffTreatment: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SetPatientsOffTreatmentResponse>) { (Object as any).assign(this, init); }
}

export class GetContactByOutlookIdResponse
{
    public responseStatus: ResponseStatus;
    public contact: Contact;

    public constructor(init?: Partial<GetContactByOutlookIdResponse>) { (Object as any).assign(this, init); }
}

export class PharmacyOpenedResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<PharmacyOpenedResponse>) { (Object as any).assign(this, init); }
}

export class DrugDispensedResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<DrugDispensedResponse>) { (Object as any).assign(this, init); }
}

export class GetInstitutionBatchesResponse
{
    public institutionBatches: { [index: string]: number[]; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetInstitutionBatchesResponse>) { (Object as any).assign(this, init); }
}

export class QuarantineBatchIdResponse
{
    public responseStatus: ResponseStatus;
    public quarantinedUnits: { [index: number]: number; };

    public constructor(init?: Partial<QuarantineBatchIdResponse>) { (Object as any).assign(this, init); }
}

export class GetLiverFunctionTestingCyclesResponse
{
    public responseStatus: ResponseStatus;
    public cycles: LiverFunctionTestingInformation[];

    public constructor(init?: Partial<GetLiverFunctionTestingCyclesResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public treatmentType?: TreatmentType;
    public lookupYesNoType?: LookupYesNoType;
    public lookupLanguageType?: LookupLanguageType;
    public lookupIndigenousStatusType?: LookupIndigenousStatusType;
    public lookupGenderType?: LookupGenderType;
    public lookupCountryType?: LookupCountryType;
    public lookupUntypedType?: LookupUntypedType;
    public lookupSampleType?: LookupSampleType;
    public lookupEchoMugaType?: LookupEchoMugaType;
    public lookupEcogType?: LookupEcogType;
    public lookupMenopausalStatusType?: LookupMenopausalStatusType;
    public lookupPostMenopausalType?: LookupPostMenopausalType;
    public lookupPreOrPeriMenopausalType?: LookupPreOrPeriMenopausalType;
    public lookupAgeType?: LookupAgeType;
    public lookupBrcaType?: LookupBrcaType;
    public lookupCurrentDiseaseType?: LookupCurrentDiseaseType;
    public lookupDiscontinuationReasonType?: LookupDiscontinuationReasonType;
    public lookupDiseaseSettingType?: LookupDiseaseSettingType;
    public lookupIhcScoreType?: LookupIhcScoreType;
    public lookupIshType?: LookupIshType;
    public lookupLessGreaterThanType?: LookupLessGreaterThanType;
    public lookupPrimaryMetastaticType?: LookupPrimaryMetastaticType;
    public lookupProgressionIntolerantType?: LookupProgressionIntolerantType;
    public lookupTherapyType?: LookupTherapyType;
    public lookupBrainMetastasesType?: LookupBrainMetastasesType;
    public lookupMethodOfMeasurementType?: LookupMethodOfMeasurementType;
    public lookupOrganSiteType?: LookupOrganSiteType;
    public lookupAbnormalityType?: LookupAbnormalityType;
    public lookupDetectedType?: LookupDetectedType;
    public lookupPositiveNegativeType?: LookupPositiveNegativeType;
    public lookupPlannedTreatmentType?: LookupPlannedTreatmentType;
    public lookupUnitsType?: LookupUnitsType;
    public lookupFrequencyType?: LookupFrequencyType;
    public lookupRouteType?: LookupRouteType;
    public lookupIndicationType?: LookupIndicationType;
    public lookupNotSuitableReasonType?: LookupNotSuitableReasonType;
    public lookupPdlResultType?: LookupPdlResultType;
    public lookupScreeningLogReasonType?: LookupScreeningLogReasonType;
    public lookupSampleSiteType?: LookupSampleSiteType;
    public lookupFfpeType?: LookupFfpeType;
    public lookupLargestLegionSizeType?: LookupLargestLegionSizeType;
    public lookupDailyDoseType?: LookupDailyDoseType;
    public lookupApprovedByType?: LookupApprovedByType;
    public lookupWaiverType?: LookupWaiverType;
    public lookupWaiverApprovedByType?: LookupWaiverApprovedByType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetCtcaeTermNameResponse
{
    public responseStatus: ResponseStatus;
    public ctcaeTermName: string;

    public constructor(init?: Partial<GetCtcaeTermNameResponse>) { (Object as any).assign(this, init); }
}

export class SendPreRegistrationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPreRegistrationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendTissueResubmissionEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendTissueResubmissionEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendRegistrationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendRegistrationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientHasBeenRegisteredEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientHasBeenRegisteredEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPdlAssayDelayEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPdlAssayDelayEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPdlAssayResultAvailableEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPdlAssayResultAvailableEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPdlAssayResultAvailablePostRegistrationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPdlAssayResultAvailablePostRegistrationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnsuccessfulPdlAssayEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnsuccessfulPdlAssayEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnsuccessfulTilCountEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnsuccessfulTilCountEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPdlAndTilNoAdditionalTissueEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPdlAndTilNoAdditionalTissueEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueuePdlAssayAndTilCountReportReminderEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePdlAssayAndTilCountReportReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPdlAssayAndTilCountReportReminderEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPdlAssayAndTilCountReportReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendImagingBctAndScApprovalEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendImagingBctAndScApprovalEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendMedHistoryAndConMedsBctAndScApprovalEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendMedHistoryAndConMedsBctAndScApprovalEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeUnapprovedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeUnapprovedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeUnresolvedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeUnresolvedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeIncompleteEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public incompleteSaesCount?: number;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeIncompleteEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyStudyChairEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyStudyChairEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyStudySponsorMsdEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyStudySponsorMsdEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyStudySponsorPfizerEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyStudySponsorPfizerEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyMedicalReviewerEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyMedicalReviewerEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeInvestigatorReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeTrialTeamRejectionEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeTrialTeamRejectionEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeMedicalReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeMedicalReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeBlueCardNotificationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeBlueCardNotificationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeDeathNotificationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeDeathNotificationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeInvalidEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeInvalidEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendScreeningLogReminderEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendScreeningLogReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetEConsentSettingsResponse
{
    public eConsentActivated: boolean;
    public eConsentActivatedInstitutions: number[];
    public trialInfoPageUrl: string;
    public trialConsentPageUrl: string;
    public trialConsentSignedPageUrl: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetEConsentSettingsResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvitationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvitationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentEoiEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentEoiEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentSuccessEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentSuccessEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentSuccessTrialEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentSuccessTrialEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentRefusedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentRefusedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentRefusedPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentRefusedPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendQuarterlyTeaeReportResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendQuarterlyTeaeReportResponse>) { (Object as any).assign(this, init); }
}

export class SendMonthlySaesResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendMonthlySaesResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeSusarEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeSusarEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendAesEmailResponse
{
    public noNewData: boolean;
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendAesEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendAesMissingBctGradesEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendAesMissingBctGradesEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendElevatedLiverTestResultsEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendElevatedLiverTestResultsEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendFormsDueSummaryEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendFormsDueSummaryEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendDataCleaningReportsEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendDataCleaningReportsEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendLiverFunctionTestingFormOverdueEmailLoaderResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendLiverFunctionTestingFormOverdueEmailLoaderResponse>) { (Object as any).assign(this, init); }
}

export class SendLiverFunctionTestingFormOverdueEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendLiverFunctionTestingFormOverdueEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetRequisitionFormDataResponse
{
    public responseStatus: ResponseStatus;
    public requisitionFormData: RequisitionFormData[];

    public constructor(init?: Partial<GetRequisitionFormDataResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugStockDataResponse
{
    public responseStatus: ResponseStatus;
    public drugStockData: DrugStockData[];

    public constructor(init?: Partial<GetDrugStockDataResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchReportDataResponse
{
    public responseStatus: ResponseStatus;
    public batchReport: BatchReport[];

    public constructor(init?: Partial<GetBatchReportDataResponse>) { (Object as any).assign(this, init); }
}

export class GetMonthlySeagenDataResponse
{
    public responseStatus: ResponseStatus;
    public monthlySeagenData: MonthlySeagenData[];

    public constructor(init?: Partial<GetMonthlySeagenDataResponse>) { (Object as any).assign(this, init); }
}

export class GetAESIDataResponse
{
    public responseStatus: ResponseStatus;
    public adverseEventSpecialInterestData: AESIData[];

    public constructor(init?: Partial<GetAESIDataResponse>) { (Object as any).assign(this, init); }
}

export class GetFormsDueSummaryDataResponse
{
    public responseStatus: ResponseStatus;
    public formsDueSummary: FormsDueEvent[];

    public constructor(init?: Partial<GetFormsDueSummaryDataResponse>) { (Object as any).assign(this, init); }
}

export class GetTpRegistrationByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpRegistrationByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

export class GetTpScreeningByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpScreeningByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

export class GetTpDrugManagementByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpDrugManagementByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

export class GetTpSaeByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpSaeByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public batch: Batch;

    public constructor(init?: Partial<GetBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesByIdsResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesByIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetBatchesExpiringAtSpecifiedDateResponse
{
    public responseStatus: ResponseStatus;
    public batches: Batch[];

    public constructor(init?: Partial<GetBatchesExpiringAtSpecifiedDateResponse>) { (Object as any).assign(this, init); }
}

export class SetBatchResponse
{
    public responseStatus: ResponseStatus;
    public batch: Batch;

    public constructor(init?: Partial<SetBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<GetDepotBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByDepotIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByDepotIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByPharmacyIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByPharmacyIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByDrugShipmentIdResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByDrugShipmentIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesByBatchIdsResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesByBatchIdsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotBatchesWithLowStockByDepotIdsResponse
{
    public responseStatus: ResponseStatus;
    public depotBatches: DepotBatch[];

    public constructor(init?: Partial<GetDepotBatchesWithLowStockByDepotIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<SetDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotsResponse
{
    public responseStatus: ResponseStatus;
    public depots: Depot[];

    public constructor(init?: Partial<GetDepotsResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotByIdResponse
{
    public responseStatus: ResponseStatus;
    public depot: Depot;

    public constructor(init?: Partial<GetDepotByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDepotsByDrugUnderThresholdResponse
{
    public responseStatus: ResponseStatus;
    public depotIds: number[];

    public constructor(init?: Partial<GetDepotsByDrugUnderThresholdResponse>) { (Object as any).assign(this, init); }
}

export class SetDepotResponse
{
    public responseStatus: ResponseStatus;
    public depot: Depot;

    public constructor(init?: Partial<SetDepotResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationsResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<GetDrugDispensationByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientsWithLateDispensationsResponse
{
    public responseStatus: ResponseStatus;
    public patientIds: number[];

    public constructor(init?: Partial<GetPatientsWithLateDispensationsResponse>) { (Object as any).assign(this, init); }
}

export class GetLastDispensationForPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<GetLastDispensationForPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugDispensationsByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensations: DrugDispensation[];

    public constructor(init?: Partial<GetDrugDispensationsByIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugDispensationResponse
{
    public responseStatus: ResponseStatus;
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<SetDrugDispensationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDrugDispensationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDrugDispensationResponse>) { (Object as any).assign(this, init); }
}

export class ValidateDrugDispensationResponse extends DrugManagementValidationResponse
{

    public constructor(init?: Partial<ValidateDrugDispensationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDrugsResponse
{
    public responseStatus: ResponseStatus;
    public drugs: Drug[];

    public constructor(init?: Partial<GetDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentDepotBatchesByPharmacyIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatches: DrugShipmentDepotBatch[];

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByPharmacyIdResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<SetDrugShipmentDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class StartDrugShipmentGenerationResponse
{
    public responseStatus: ResponseStatus;
    public messages: string[];
    public createdDrugShipments: DrugShipment[];

    public constructor(init?: Partial<StartDrugShipmentGenerationResponse>) { (Object as any).assign(this, init); }
}

export class CreateDrugShipmentFromPatientResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<CreateDrugShipmentFromPatientResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsByInstitutionResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsByInstitutionResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentByIdResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<GetDrugShipmentByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsByIdsResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsByIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentResponse>) { (Object as any).assign(this, init); }
}

export class ValidateDrugShipmentResponse extends DrugManagementValidationResponse
{

    public constructor(init?: Partial<ValidateDrugShipmentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetDrugShipmentStatusResponse
{
    public responseStatus: ResponseStatus;
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentStatusResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDrugShipmentResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDrugShipmentResponse>) { (Object as any).assign(this, init); }
}

export class SetDrugShipmentVerifyResponse
{
    public responseStatus: ResponseStatus;
    public drugShipmentVerify: DrugShipmentVerify;

    public constructor(init?: Partial<SetDrugShipmentVerifyResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsNotShippedByExpectedDateResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsNotShippedByExpectedDateResponse>) { (Object as any).assign(this, init); }
}

export class GetDrugShipmentsNotReceivedByExpectedDateResponse
{
    public responseStatus: ResponseStatus;
    public drugShipments: DrugShipment[];

    public constructor(init?: Partial<GetDrugShipmentsNotReceivedByExpectedDateResponse>) { (Object as any).assign(this, init); }
}

export class GetOrderFormsResponse
{
    public responseStatus: ResponseStatus;
    public orderForms: OrderForm[];

    public constructor(init?: Partial<GetOrderFormsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientDrugsResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<GetPatientDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetPatientDrugsByPatientIdResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<GetPatientDrugsByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class SetPatientDrugsResponse
{
    public responseStatus: ResponseStatus;
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<SetPatientDrugsResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacistsByInstCodeResponse
{
    public responseStatus: ResponseStatus;
    public pharmacists: IPharmacist[];

    public constructor(init?: Partial<GetPharmacistsByInstCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacistByIdResponse
{
    public responseStatus: ResponseStatus;
    public pharmacist: IPharmacist;

    public constructor(init?: Partial<GetPharmacistByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmaciesResponse
{
    public responseStatus: ResponseStatus;
    public pharmacies: Pharmacy[];

    public constructor(init?: Partial<GetPharmaciesResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyByIdResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<GetPharmacyByIdResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyByInstitutionCodeResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<GetPharmacyByInstitutionCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetPharmacyBatchesDrugRemainingByBatchIdsResponse
{
    public responseStatus: ResponseStatus;
    public pharmacyBatchesDrugRemaining: PharmacyBatchDrugRemaining[];

    public constructor(init?: Partial<GetPharmacyBatchesDrugRemainingByBatchIdsResponse>) { (Object as any).assign(this, init); }
}

export class SetPharmacyResponse
{
    public responseStatus: ResponseStatus;
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<SetPharmacyResponse>) { (Object as any).assign(this, init); }
}

export class QuarantinePharmacyStockResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<QuarantinePharmacyStockResponse>) { (Object as any).assign(this, init); }
}

export class QuarantinePharmacyDepotBatchResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<QuarantinePharmacyDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class ActionQuarantinedStockResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<ActionQuarantinedStockResponse>) { (Object as any).assign(this, init); }
}

export class QuarantineRemovePharmacyDepotBatchResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QuarantineRemovePharmacyDepotBatchResponse>) { (Object as any).assign(this, init); }
}

export class QuarantinePharmacyBatchesResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<QuarantinePharmacyBatchesResponse>) { (Object as any).assign(this, init); }
}

export class ActionQuarantinedPharmacyBatchesResponse
{
    public responseStatus: ResponseStatus;
    public success: boolean;

    public constructor(init?: Partial<ActionQuarantinedPharmacyBatchesResponse>) { (Object as any).assign(this, init); }
}

export class GetTimepointsResponse
{
    public responseStatus: ResponseStatus;
    public timepoints: Timepoint[];

    public constructor(init?: Partial<GetTimepointsResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetCollectionResponse
{
    public responseStatus: ResponseStatus;
    public invitations: Invitation[];

    public constructor(init?: Partial<InvitationGetCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByIdResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByIdResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByGuidResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByGuidResponse>) { (Object as any).assign(this, init); }
}

export class InvitationSaveResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSaveResponse>) { (Object as any).assign(this, init); }
}

export class InvitationDeleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<InvitationDeleteResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetInvestigatorsResponse
{
    public responseStatus: ResponseStatus;
    public investigators: { [index: string]: string; };

    public constructor(init?: Partial<InvitationGetInvestigatorsResponse>) { (Object as any).assign(this, init); }
}

export class GetPicfsResponse
{
    public responseStatus: ResponseStatus;
    public picfs: Picf[];

    public constructor(init?: Partial<GetPicfsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eoi: Eoi;

    public constructor(init?: Partial<EoiSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eois: Eoi[];

    public constructor(init?: Partial<EoiCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EoiDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientConsent: PatientConsent;

    public constructor(init?: Partial<PatientConsentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientConsents: PatientConsent[];

    public constructor(init?: Partial<PatientConsentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientConsentDeleteResponse>) { (Object as any).assign(this, init); }
}

export class PatientConsentSendVerificationCodeResponse
{
    public responseStatus: ResponseStatus;
    public verificationCode: number;

    public constructor(init?: Partial<PatientConsentSendVerificationCodeResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewSingleByPatientStudyNumberResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumberResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewNarrativeSummaryPostSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewPostSaveResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateMedicalReviewResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateMedicalReviewResponse>) { (Object as any).assign(this, init); }
}

export class SaeQueryCollectionByPatientSaeNumberResponse
{
    public responseStatus: ResponseStatus;
    public queries: Query[];
    public queryRecipientsLookup: Lookup;

    public constructor(init?: Partial<SaeQueryCollectionByPatientSaeNumberResponse>) { (Object as any).assign(this, init); }
}

export class SaeQueryPostSaveResponse
{
    public responseStatus: ResponseStatus;
    public queries: Query[];

    public constructor(init?: Partial<SaeQueryPostSaveResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public saes: Sae<SaeForm,Patient>[];

    public constructor(init?: Partial<SaeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public sae: Sae<SaeForm, Patient>;

    public constructor(init?: Partial<SaeSingleResponse>) { (Object as any).assign(this, init); }
}

export class SaeGetCollectionByStatusResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae<ISaeForm,IPatient>[];

    public constructor(init?: Partial<SaeGetCollectionByStatusResponse>) { (Object as any).assign(this, init); }
}

export class SaeGetIncompleteFormsResponse
{
    public responseStatus: ResponseStatus;
    public incompleteSaeForms: ISaeForm[];

    public constructor(init?: Partial<SaeGetIncompleteFormsResponse>) { (Object as any).assign(this, init); }
}

export class SaeWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeSendNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeSendNotificationResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateNotificationFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateNotificationFormResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateRejectionFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateRejectionFormResponse>) { (Object as any).assign(this, init); }
}

export class SaeInvalidFormPostSaveResponse
{
    public responseStatus: ResponseStatus;
    public invalidSaeForm: InvalidSaeForm;

    public constructor(init?: Partial<SaeInvalidFormPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateInvalidFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateInvalidFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public screeningLogs: ScreeningLog[];

    public constructor(init?: Partial<ScreeningLogCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ScreeningLogDeleteResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningLogFormValidationResult;

    public constructor(init?: Partial<ScreeningLogFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogSingleByPatientIdResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverSourceDocumentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: WaiverSourceDocument;

    public constructor(init?: Partial<WaiverSourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverSourceDocumentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: WaiverSourceDocument[];

    public constructor(init?: Partial<WaiverSourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverSourceDocumentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<WaiverSourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverSourceDocumentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<WaiverSourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Consent;

    public constructor(init?: Partial<ConsentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Consent[];

    public constructor(init?: Partial<ConsentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConsentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConsentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Demographics;

    public constructor(init?: Partial<DemographicsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Demographics[];

    public constructor(init?: Partial<DemographicsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicsDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DemographicsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DemographicsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: EligibilityChecklist[];

    public constructor(init?: Partial<EligibilityChecklistCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EligibilityChecklistDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EligibilityChecklistValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: General;

    public constructor(init?: Partial<GeneralSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: General[];

    public constructor(init?: Partial<GeneralCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GeneralDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<GeneralValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingAndTumourAssessmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ImagingAndTumourAssessment;

    public constructor(init?: Partial<ImagingAndTumourAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingAndTumourAssessmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ImagingAndTumourAssessment[];

    public constructor(init?: Partial<ImagingAndTumourAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingAndTumourAssessmentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ImagingAndTumourAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImagingAndTumourAssessmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ImagingAndTumourAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LaboratoryAssessmentsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: LaboratoryAssessments;

    public constructor(init?: Partial<LaboratoryAssessmentsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LaboratoryAssessmentsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: LaboratoryAssessments[];

    public constructor(init?: Partial<LaboratoryAssessmentsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LaboratoryAssessmentsDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<LaboratoryAssessmentsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LaboratoryAssessmentsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<LaboratoryAssessmentsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalConditionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: MedicalCondition;

    public constructor(init?: Partial<MedicalConditionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalConditionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: MedicalCondition[];

    public constructor(init?: Partial<MedicalConditionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalConditionDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicalConditionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalConditionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicalConditionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndConMedsSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: MedicalHistoryAndConMeds;

    public constructor(init?: Partial<MedicalHistoryAndConMedsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndConMedsCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: MedicalHistoryAndConMeds[];

    public constructor(init?: Partial<MedicalHistoryAndConMedsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndConMedsDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicalHistoryAndConMedsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryAndConMedsValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicalHistoryAndConMedsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonTargetLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: NonTargetLesion;

    public constructor(init?: Partial<NonTargetLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonTargetLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: NonTargetLesion[];

    public constructor(init?: Partial<NonTargetLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonTargetLesionDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<NonTargetLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NonTargetLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<NonTargetLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherMedicalConditionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: OtherMedicalCondition;

    public constructor(init?: Partial<OtherMedicalConditionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherMedicalConditionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: OtherMedicalCondition[];

    public constructor(init?: Partial<OtherMedicalConditionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherMedicalConditionDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<OtherMedicalConditionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherMedicalConditionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<OtherMedicalConditionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyAndPriorTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: PathologyAndPriorTreatment;

    public constructor(init?: Partial<PathologyAndPriorTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyAndPriorTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: PathologyAndPriorTreatment[];

    public constructor(init?: Partial<PathologyAndPriorTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyAndPriorTreatmentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PathologyAndPriorTreatmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PathologyAndPriorTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PathologyAndPriorTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: PdlAndTilResult;

    public constructor(init?: Partial<PdlAndTilResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: PdlAndTilResult[];

    public constructor(init?: Partial<PdlAndTilResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PdlAndTilResultDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PdlAndTilResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: PdlAndTilResultForm;

    public constructor(init?: Partial<PdlAndTilResultFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: PdlAndTilResultForm[];

    public constructor(init?: Partial<PdlAndTilResultFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultFormDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PdlAndTilResultFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PdlAndTilResultFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PdlAndTilResultFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousTreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: PreviousTreatment;

    public constructor(init?: Partial<PreviousTreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousTreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: PreviousTreatment[];

    public constructor(init?: Partial<PreviousTreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousTreatmentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PreviousTreatmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreviousTreatmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PreviousTreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetLesionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TargetLesion;

    public constructor(init?: Partial<TargetLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetLesionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TargetLesion[];

    public constructor(init?: Partial<TargetLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetLesionDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TargetLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TargetLesionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TargetLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TumourBlock;

    public constructor(init?: Partial<TumourBlockSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TumourBlock[];

    public constructor(init?: Partial<TumourBlockCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TumourBlockDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TumourBlockValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockShipmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TumourBlockShipment;

    public constructor(init?: Partial<TumourBlockShipmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockShipmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TumourBlockShipment[];

    public constructor(init?: Partial<TumourBlockShipmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockShipmentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TumourBlockShipmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockShipmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TumourBlockShipmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockSubmissionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TumourBlockSubmission;

    public constructor(init?: Partial<TumourBlockSubmissionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockSubmissionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TumourBlockSubmission[];

    public constructor(init?: Partial<TumourBlockSubmissionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockSubmissionDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TumourBlockSubmissionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TumourBlockSubmissionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TumourBlockSubmissionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FailedEligibilityCriteriaSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: FailedEligibilityCriteria;

    public constructor(init?: Partial<FailedEligibilityCriteriaSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FailedEligibilityCriteriaCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: FailedEligibilityCriteria[];

    public constructor(init?: Partial<FailedEligibilityCriteriaCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FailedEligibilityCriteriaDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<FailedEligibilityCriteriaDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FailedEligibilityCriteriaValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<FailedEligibilityCriteriaValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusCriteriaSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: PatientStatusCriteria;

    public constructor(init?: Partial<PatientStatusCriteriaSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusCriteriaCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: PatientStatusCriteria[];

    public constructor(init?: Partial<PatientStatusCriteriaCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusCriteriaDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientStatusCriteriaDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusCriteriaValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PatientStatusCriteriaValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: PatientStatusForm;

    public constructor(init?: Partial<PatientStatusFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: PatientStatusForm[];

    public constructor(init?: Partial<PatientStatusFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusFormDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientStatusFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStatusFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PatientStatusFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Waiver;

    public constructor(init?: Partial<WaiverSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Waiver[];

    public constructor(init?: Partial<WaiverCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<WaiverDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<WaiverValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverFormSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: WaiverForm;

    public constructor(init?: Partial<WaiverFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverFormCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: WaiverForm[];

    public constructor(init?: Partial<WaiverFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverFormDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<WaiverFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class WaiverFormValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<WaiverFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventStateResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public states: EventState[];

    public constructor(init?: Partial<EventStateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormStateResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public states: FormState[];

    public constructor(init?: Partial<FormStateResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: { [index: number]: InstitutionPersonnel; };

    public constructor(init?: Partial<InstitutionPersonnelResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class TrialPersonnelActiveRoleResponse
{
    public responseStatus: ResponseStatus;
    public hasRole: { [index: number]: boolean; };

    public constructor(init?: Partial<TrialPersonnelActiveRoleResponse>) { (Object as any).assign(this, init); }
}

// @Route("/drug-management-api/send-drug-created-email")
export class SendDrugShipmentCreatedEmail implements IReturn<SendDrugShipmentCreatedEmailResponse>, IPost
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendDrugShipmentCreatedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentCreatedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentCreatedEmailResponse(); }
}

// @Route("/drug-management-api/send-drug-ordered-email")
export class SendDrugShipmentOrderedEmail implements IReturn<SendDrugShipmentOrderedEmailResponse>, IPost
{
    public drugShipmentId: number;

    public constructor(init?: Partial<SendDrugShipmentOrderedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDrugShipmentOrderedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDrugShipmentOrderedEmailResponse(); }
}

// @Route("/drug-management-api/send-stagnant-drug-orders-email")
export class SendStagnantDrugOrdersEmail implements IReturn<SendStagnantDrugOrdersEmailResponse>, IPost
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendStagnantDrugOrdersEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStagnantDrugOrdersEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendStagnantDrugOrdersEmailResponse(); }
}

// @Route("/drug-management-api/send-stagnant-drug-shipments-email")
export class SendStagnantDrugShipmentsEmail implements IReturn<SendStagnantDrugShipmentsEmailResponse>, IPost
{
    public drugShipmentIds: number[];

    public constructor(init?: Partial<SendStagnantDrugShipmentsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendStagnantDrugShipmentsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendStagnantDrugShipmentsEmailResponse(); }
}

// @Route("/drug-management-api/send-expiring-stock-email")
export class SendExpiringStockEmail implements IReturn<SendExpiringStockEmailResponse>, IPost
{
    public batchIds: number[];
    public includeExhaustedBatches?: boolean;

    public constructor(init?: Partial<SendExpiringStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendExpiringStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendExpiringStockEmailResponse(); }
}

// @Route("/drug-management-api/send-site-expiring-stock-email")
export class SendSiteExpiringStockEmail implements IReturn<SendSiteExpiringStockEmailResponse>, IPost
{
    public institutionCode: string;
    public batchIds: number[];
    public includeExhaustedBatches?: boolean;

    public constructor(init?: Partial<SendSiteExpiringStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSiteExpiringStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSiteExpiringStockEmailResponse(); }
}

// @Route("/drug-management-api/send-site-auto-quarantining-drug-email")
export class SendSiteAutoQuarantiningDrugEmail implements IReturn<SendSiteAutoQuarantiningDrugEmailResponse>, IPost
{
    public institutionCode: string;
    public batchIds: number[];

    public constructor(init?: Partial<SendSiteAutoQuarantiningDrugEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSiteAutoQuarantiningDrugEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSiteAutoQuarantiningDrugEmailResponse(); }
}

// @Route("/drug-management-api/send-low-stock-email")
export class SendLowStockEmail implements IReturn<SendLowStockEmailResponse>, IPost
{
    public depotIds: number[];
    public drugThreshold: number;

    public constructor(init?: Partial<SendLowStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLowStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLowStockEmailResponse(); }
}

// @Route("/drug-management-api/send-late-dispensation-email")
export class SendLateDispensationEmail implements IReturn<SendLateDispensationEmailResponse>, IPost
{
    public patientId: number;

    public constructor(init?: Partial<SendLateDispensationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLateDispensationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLateDispensationEmailResponse(); }
}

// @Route("/drug-management-api/set-patients-off-treatment")
export class SetPatientsOffTreatment implements IReturn<SetPatientsOffTreatmentResponse>, IPost
{

    public constructor(init?: Partial<SetPatientsOffTreatment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPatientsOffTreatment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPatientsOffTreatmentResponse(); }
}

export class GetContactByOutlookId implements IReturn<GetContactByOutlookIdResponse>, IGet
{
    public outlookId: string;

    public constructor(init?: Partial<GetContactByOutlookId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContactByOutlookId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetContactByOutlookIdResponse(); }
}

export class PharmacyOpened implements IReturn<PharmacyOpenedResponse>, IPost
{
    public pharmacyId?: number;

    public constructor(init?: Partial<PharmacyOpened>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PharmacyOpened'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PharmacyOpenedResponse(); }
}

export class DrugDispensed implements IReturn<DrugDispensedResponse>, IPost
{
    public patientId: number;
    public dispensationIds: number[];

    public constructor(init?: Partial<DrugDispensed>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DrugDispensed'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DrugDispensedResponse(); }
}

export class GetInstitutionBatches implements IReturn<GetInstitutionBatchesResponse>
{
    public batchIds: number[];
    public includeExhaustedBatches?: boolean;

    public constructor(init?: Partial<GetInstitutionBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetInstitutionBatchesResponse(); }
}

export class QuarantineBatchId implements IReturn<QuarantineBatchIdResponse>, IPost
{
    // @Validate(Validator="NotEmpty")
    public batchId: number;

    public constructor(init?: Partial<QuarantineBatchId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantineBatchId'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantineBatchIdResponse(); }
}

export class GetLiverFunctionTestingCycles implements IReturn<GetLiverFunctionTestingCyclesResponse>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetLiverFunctionTestingCycles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiverFunctionTestingCycles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetLiverFunctionTestingCyclesResponse(); }
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

// @Route("/ctcae")
export class GetCtcaeTermName implements IReturn<GetCtcaeTermNameResponse>, IGet
{
    public ctcaeTermId?: number;

    public constructor(init?: Partial<GetCtcaeTermName>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCtcaeTermName'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetCtcaeTermNameResponse(); }
}

export class SendPreRegistrationEmail implements IReturn<SendPreRegistrationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPreRegistrationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPreRegistrationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPreRegistrationEmailResponse(); }
}

export class SendTissueResubmissionEmail implements IReturn<SendTissueResubmissionEmailResponse>
{
    public patientId?: number;
    public patientAgeOnPrereg?: number;
    public consentDate?: string;
    public tumourBlockId: string;

    public constructor(init?: Partial<SendTissueResubmissionEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendTissueResubmissionEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendTissueResubmissionEmailResponse(); }
}

export class SendRegistrationEmail implements IReturn<SendRegistrationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendRegistrationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendRegistrationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendRegistrationEmailResponse(); }
}

export class SendPatientHasBeenRegisteredEmail implements IReturn<SendPatientHasBeenRegisteredEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientHasBeenRegisteredEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientHasBeenRegisteredEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientHasBeenRegisteredEmailResponse(); }
}

// @Route("/get/requisition-form/{PatientId}/", "GET")
export class GetRequisitionForm
{
    public patientId?: number;

    public constructor(init?: Partial<GetRequisitionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequisitionForm'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/{PatientId}/forms-due-schedule", "GET")
export class GetFormsDueSchedule implements IHasPatientId
{
    public patientId?: number;

    public constructor(init?: Partial<GetFormsDueSchedule>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormsDueSchedule'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class SendPdlAssayDelayEmail implements IReturn<SendPdlAssayDelayEmailResponse>
{
    public patientId?: number;
    public pdlAndTilResultId?: number;

    public constructor(init?: Partial<SendPdlAssayDelayEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPdlAssayDelayEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPdlAssayDelayEmailResponse(); }
}

export class SendPdlAssayResultAvailableEmail implements IReturn<SendPdlAssayResultAvailableEmailResponse>
{
    public patientId?: number;
    public pdlAndTilResultId?: number;

    public constructor(init?: Partial<SendPdlAssayResultAvailableEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPdlAssayResultAvailableEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPdlAssayResultAvailableEmailResponse(); }
}

export class SendPdlAssayResultAvailablePostRegistrationEmail implements IReturn<SendPdlAssayResultAvailablePostRegistrationEmailResponse>
{
    public patientId?: number;
    public treatmentId?: number;

    public constructor(init?: Partial<SendPdlAssayResultAvailablePostRegistrationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPdlAssayResultAvailablePostRegistrationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPdlAssayResultAvailablePostRegistrationEmailResponse(); }
}

export class SendUnsuccessfulPdlAssayEmail implements IReturn<SendUnsuccessfulPdlAssayEmailResponse>
{
    public patientId?: number;
    public pdlAndTilResultFormId?: number;

    public constructor(init?: Partial<SendUnsuccessfulPdlAssayEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnsuccessfulPdlAssayEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnsuccessfulPdlAssayEmailResponse(); }
}

export class SendUnsuccessfulTilCountEmail implements IReturn<SendUnsuccessfulTilCountEmailResponse>
{
    public patientId?: number;
    public pdlAndTilResultFormId?: number;

    public constructor(init?: Partial<SendUnsuccessfulTilCountEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnsuccessfulTilCountEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnsuccessfulTilCountEmailResponse(); }
}

export class SendPdlAndTilNoAdditionalTissueEmail implements IReturn<SendPdlAndTilNoAdditionalTissueEmailResponse>
{
    public patientId?: number;
    public pdlAndTilResultFormId?: number;

    public constructor(init?: Partial<SendPdlAndTilNoAdditionalTissueEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPdlAndTilNoAdditionalTissueEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPdlAndTilNoAdditionalTissueEmailResponse(); }
}

export class QueuePdlAssayAndTilCountReportReminderEmail implements IReturn<QueuePdlAssayAndTilCountReportReminderEmailResponse>
{

    public constructor(init?: Partial<QueuePdlAssayAndTilCountReportReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePdlAssayAndTilCountReportReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePdlAssayAndTilCountReportReminderEmailResponse(); }
}

export class SendPdlAssayAndTilCountReportReminderEmail implements IReturn<SendPdlAssayAndTilCountReportReminderEmailResponse>
{
    public patientId?: number;
    public pdlAndTilResultId?: number;

    public constructor(init?: Partial<SendPdlAssayAndTilCountReportReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPdlAssayAndTilCountReportReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPdlAssayAndTilCountReportReminderEmailResponse(); }
}

export class SendImagingBctAndScApprovalEmail implements IReturn<SendImagingBctAndScApprovalEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendImagingBctAndScApprovalEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendImagingBctAndScApprovalEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendImagingBctAndScApprovalEmailResponse(); }
}

export class SendMedHistoryAndConMedsBctAndScApprovalEmail implements IReturn<SendMedHistoryAndConMedsBctAndScApprovalEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendMedHistoryAndConMedsBctAndScApprovalEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMedHistoryAndConMedsBctAndScApprovalEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMedHistoryAndConMedsBctAndScApprovalEmailResponse(); }
}

// @Route("/patient/oc-transfer")
export class QueuePatientOcTransfer implements IReturn<QueuePatientOcTransferResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<QueuePatientOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientOcTransferResponse(); }
}

export class SendSaeNewEmail implements IReturn<SendSaeNewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeNewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNewEmailResponse(); }
}

export class SendSaeUnapprovedEmail implements IReturn<SendSaeUnapprovedEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeUnapprovedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeUnapprovedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeUnapprovedEmailResponse(); }
}

export class SendSaeUnresolvedEmail implements IReturn<SendSaeUnresolvedEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeUnresolvedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeUnresolvedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeUnresolvedEmailResponse(); }
}

export class SendSaeIncompleteEmail implements IReturn<SendSaeIncompleteEmailResponse>
{

    public constructor(init?: Partial<SendSaeIncompleteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeIncompleteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeIncompleteEmailResponse(); }
}

export class SendSaeNotifyInvestigatorEmail implements IReturn<SendSaeNotifyInvestigatorEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyInvestigatorEmailResponse(); }
}

export class SendSaeNotifyStudyChairEmail implements IReturn<SendSaeNotifyStudyChairEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeNotifyStudyChairEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyStudyChairEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyStudyChairEmailResponse(); }
}

export class SendSaeNotifyStudySponsorMsdEmail implements IReturn<SendSaeNotifyStudySponsorMsdEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeNotifyStudySponsorMsdEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyStudySponsorMsdEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyStudySponsorMsdEmailResponse(); }
}

export class SendSaeNotifyStudySponsorPfizerEmail implements IReturn<SendSaeNotifyStudySponsorPfizerEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeNotifyStudySponsorPfizerEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyStudySponsorPfizerEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyStudySponsorPfizerEmailResponse(); }
}

export class SendSaeNotifyMedicalReviewerEmail implements IReturn<SendSaeNotifyMedicalReviewerEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;
    public sendBctEmailsToSharedMailbox: boolean;

    public constructor(init?: Partial<SendSaeNotifyMedicalReviewerEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyMedicalReviewerEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyMedicalReviewerEmailResponse(); }
}

export class SendSaeInvestigatorReviewEmail implements IReturn<SendSaeInvestigatorReviewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public approved: boolean;
    public rejectedAs: RejectedAsType;
    public reasonForRejection: string;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeInvestigatorReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeInvestigatorReviewEmailResponse(); }
}

export class SendSaeTrialTeamRejectionEmail implements IReturn<SendSaeTrialTeamRejectionEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public approved: boolean;
    public rejectedAs: RejectedAsType;
    public reasonForRejection: string;

    public constructor(init?: Partial<SendSaeTrialTeamRejectionEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeTrialTeamRejectionEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeTrialTeamRejectionEmailResponse(); }
}

export class SendSaeMedicalReviewEmail implements IReturn<SendSaeMedicalReviewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public medicalReviewDetails: string;

    public constructor(init?: Partial<SendSaeMedicalReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeMedicalReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeMedicalReviewEmailResponse(); }
}

export class SendSaeBlueCardNotificationEmail implements IReturn<SendSaeBlueCardNotificationEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public medicalReviewDetails: string;

    public constructor(init?: Partial<SendSaeBlueCardNotificationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeBlueCardNotificationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeBlueCardNotificationEmailResponse(); }
}

export class SendSaeDeathNotificationEmail implements IReturn<SendSaeDeathNotificationEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public medicalReviewDetails: string;

    public constructor(init?: Partial<SendSaeDeathNotificationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeDeathNotificationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeDeathNotificationEmailResponse(); }
}

export class SendSaeInvalidEmail implements IReturn<SendSaeInvalidEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeInvalidEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeInvalidEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeInvalidEmailResponse(); }
}

// @Route("/print/screeninglogs/{InstitutionCode}/{DownloadFormat}/", "GET")
export class GetScreeningLogs
{
    public institutionCode: string;
    public downloadFormat: string;

    public constructor(init?: Partial<GetScreeningLogs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScreeningLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class SendScreeningLogReminderEmail implements IReturn<SendScreeningLogReminderEmailResponse>
{
    public code: string;

    public constructor(init?: Partial<SendScreeningLogReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendScreeningLogReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendScreeningLogReminderEmailResponse(); }
}

// @Route("/opms/econsent/settings/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetEConsentSettings implements IReturn<GetEConsentSettingsResponse>
{

    public constructor(init?: Partial<GetEConsentSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEConsentSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetEConsentSettingsResponse(); }
}

export class SendEConsentInvitationEmail implements IReturn<SendEConsentInvitationEmailResponse>
{
    public invitationId: number;

    public constructor(init?: Partial<SendEConsentInvitationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvitationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvitationEmailResponse(); }
}

export class SendEConsentEoiEmail implements IReturn<SendEConsentEoiEmailResponse>
{
    public eoiId: number;

    public constructor(init?: Partial<SendEConsentEoiEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentEoiEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentEoiEmailResponse(); }
}

export class SendEConsentPatientEmail implements IReturn<SendEConsentPatientEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentPatientEmailResponse(); }
}

export class SendEConsentInvestigatorEmail implements IReturn<SendEConsentInvestigatorEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvestigatorEmailResponse(); }
}

export class SendEConsentSuccessEmail implements IReturn<SendEConsentSuccessEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentSuccessEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentSuccessEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentSuccessEmailResponse(); }
}

export class SendEConsentSuccessTrialEmail implements IReturn<SendEConsentSuccessTrialEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentSuccessTrialEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentSuccessTrialEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentSuccessTrialEmailResponse(); }
}

export class SendEConsentRefusedEmail implements IReturn<SendEConsentRefusedEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentRefusedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentRefusedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentRefusedEmailResponse(); }
}

export class SendEConsentRefusedPatientEmail implements IReturn<SendEConsentRefusedPatientEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentRefusedPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentRefusedPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentRefusedPatientEmailResponse(); }
}

// @Route("/report/send-quarteryly-teae-report")
export class SendQuarterlyTeaeReport implements IReturn<SendQuarterlyTeaeReportResponse>, IPost
{

    public constructor(init?: Partial<SendQuarterlyTeaeReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendQuarterlyTeaeReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendQuarterlyTeaeReportResponse(); }
}

// @Route("/report/send-monthyl-saes-email")
export class SendMonthlySaes implements IReturn<SendMonthlySaesResponse>
{
    public startDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<SendMonthlySaes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMonthlySaes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMonthlySaesResponse(); }
}

export class SendSaeSusarEmail implements IReturn<SendSaeSusarEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendSaeSusarEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeSusarEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeSusarEmailResponse(); }
}

export class SendAesEmail implements IReturn<SendAesEmailResponse>
{
    public newDataRequired: boolean;

    public constructor(init?: Partial<SendAesEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendAesEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendAesEmailResponse(); }
}

export class SendAesMissingBctGradesEmail implements IReturn<SendAesMissingBctGradesEmailResponse>
{

    public constructor(init?: Partial<SendAesMissingBctGradesEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendAesMissingBctGradesEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendAesMissingBctGradesEmailResponse(); }
}

export class SendElevatedLiverTestResultsEmail implements IReturn<SendElevatedLiverTestResultsEmailResponse>
{
    public dateEffective?: string;

    public constructor(init?: Partial<SendElevatedLiverTestResultsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendElevatedLiverTestResultsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendElevatedLiverTestResultsEmailResponse(); }
}

// @Route("/api/forms-due-summary-report", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetFormsDueSummary
{
    public institutionCode: string;
    public studyNumber: string;
    public cutOffDate?: string;

    public constructor(init?: Partial<GetFormsDueSummary>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormsDueSummary'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class SendFormsDueSummaryEmail implements IReturn<SendFormsDueSummaryEmailResponse>
{
    public instCode: string;

    public constructor(init?: Partial<SendFormsDueSummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendFormsDueSummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendFormsDueSummaryEmailResponse(); }
}

export class SendDataCleaningReportsEmail implements IReturn<SendDataCleaningReportsEmailResponse>
{

    public constructor(init?: Partial<SendDataCleaningReportsEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDataCleaningReportsEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendDataCleaningReportsEmailResponse(); }
}

export class SendLiverFunctionTestingFormOverdueEmailLoader implements IReturn<SendLiverFunctionTestingFormOverdueEmailLoaderResponse>
{

    public constructor(init?: Partial<SendLiverFunctionTestingFormOverdueEmailLoader>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLiverFunctionTestingFormOverdueEmailLoader'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLiverFunctionTestingFormOverdueEmailLoaderResponse(); }
}

export class SendLiverFunctionTestingFormOverdueEmail implements IReturn<SendLiverFunctionTestingFormOverdueEmailResponse>
{
    public instCode: string;
    public instId?: number;

    public constructor(init?: Partial<SendLiverFunctionTestingFormOverdueEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendLiverFunctionTestingFormOverdueEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendLiverFunctionTestingFormOverdueEmailResponse(); }
}

// @Route("/report/requisition-form", "GET,POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetRequisitionFormData implements IReturn<GetRequisitionFormDataResponse>, IReportRequest
{
    public token: string;
    public studyNumber: string;

    public constructor(init?: Partial<GetRequisitionFormData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequisitionFormData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRequisitionFormDataResponse(); }
}

// @Route("/report/drug-stock", "GET,POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDrugStockData implements IReturn<GetDrugStockDataResponse>, IReportRequest
{
    public token: string;

    public constructor(init?: Partial<GetDrugStockData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugStockData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDrugStockDataResponse(); }
}

// @Route("/report/batch", "GET,POST")
export class GetBatchReportData implements IReturn<GetBatchReportDataResponse>, IReportRequest
{
    public token: string;

    public constructor(init?: Partial<GetBatchReportData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchReportData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBatchReportDataResponse(); }
}

// @Route("/report/monthly-seagen-data", "GET,POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetMonthlySeagenData implements IReturn<GetMonthlySeagenDataResponse>, IReportRequest
{
    public token: string;
    public startDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetMonthlySeagenData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMonthlySeagenData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMonthlySeagenDataResponse(); }
}

// @Route("/report/aesi-data", "GET,POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetAESIData implements IReturn<GetAESIDataResponse>, IReportRequest
{
    public token: string;

    public constructor(init?: Partial<GetAESIData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAESIData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAESIDataResponse(); }
}

// @Route("/report/forms-due-summary", "GET,POST")
export class GetFormsDueSummaryData implements IReturn<GetFormsDueSummaryDataResponse>, IReportRequest
{
    public token: string;
    public institutionCode: string;
    public studyNumber: string;
    public cutOffDate?: string;

    public constructor(init?: Partial<GetFormsDueSummaryData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormsDueSummaryData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetFormsDueSummaryDataResponse(); }
}

// @Route("/get/tp-registration/", "GET")
// @Route("/get/tp-registration/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpRegistrationByInstitutionId implements IReturn<GetTpRegistrationByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpRegistrationByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpRegistrationByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpRegistrationByInstitutionIdResponse(); }
}

// @Route("/get/tp-screening/", "GET")
// @Route("/get/tp-screening/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpScreeningByInstitutionId implements IReturn<GetTpScreeningByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpScreeningByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpScreeningByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpScreeningByInstitutionIdResponse(); }
}

// @Route("/get/tp-drug-management/", "GET")
// @Route("/get/tp-drug-management/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpDrugManagementByInstitutionId implements IReturn<GetTpDrugManagementByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpDrugManagementByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpDrugManagementByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpDrugManagementByInstitutionIdResponse(); }
}

// @Route("/get/tp-sae/", "GET")
// @Route("/get/tp-sae/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpSaeByInstitutionId implements IReturn<GetTpSaeByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpSaeByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpSaeByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpSaeByInstitutionIdResponse(); }
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/drug-management-api/batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatches implements IReturn<GetBatchesResponse>
{

    public constructor(init?: Partial<GetBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesResponse(); }
}

// @Route("/drug-management-api/batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchById implements IReturn<GetBatchByIdResponse>
{
    /** @description The id of the drug batch */
    // @ApiMember(DataType="int", Description="The id of the drug batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchByIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchesByIds implements IReturn<GetBatchesByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetBatchesByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchesByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesByIdsResponse(); }
}

// @Route("/drug-management-api/batches/expiring-at-specified-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetBatchesExpiringAtSpecifiedDate implements IReturn<GetBatchesExpiringAtSpecifiedDateResponse>, IGet
{
    /** @description The date of expiry */
    // @ApiMember(DataType="DateTime", Description="The date of expiry", IsRequired=true, Name="ExpiryDate", ParameterType="query")
    public expiryDate: string;

    /** @description Whether to include batches with no drug remaining (default false) */
    // @ApiMember(DataType="boolean", Description="Whether to include batches with no drug remaining (default false)", Name="IncludeExhaustedBatches", ParameterType="query")
    public includeExhaustedBatches?: boolean;

    public constructor(init?: Partial<GetBatchesExpiringAtSpecifiedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBatchesExpiringAtSpecifiedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBatchesExpiringAtSpecifiedDateResponse(); }
}

// @Route("/drug-management-api/batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetBatch implements IReturn<SetBatchResponse>
{
    /** @description The drug batch form data to save */
    // @ApiMember(DataType="BatchForm", Description="The drug batch form data to save", IsRequired=true, Name="BatchForm", ParameterType="body")
    public batch: Batch;

    public constructor(init?: Partial<SetBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetBatchResponse(); }
}

// @Route("/drug-management-api/depot-batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatches implements IReturn<GetDepotBatchesResponse>
{

    public constructor(init?: Partial<GetDepotBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesResponse(); }
}

// @Route("/drug-management-api/depot-batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchById implements IReturn<GetDepotBatchByIdResponse>
{
    /** @description The id of the depot batch */
    // @ApiMember(DataType="int", Description="The id of the depot batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepotBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchByIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/depotId/{DepotId}", "GET")
// @ApiResponse(Description="Depot Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByDepotId implements IReturn<GetDepotBatchesByDepotIdResponse>
{
    /** @description The id of the depot */
    // @ApiMember(DataType="int", Description="The id of the depot", IsRequired=true, Name="DepotId", ParameterType="path")
    public depotId: number;

    public constructor(init?: Partial<GetDepotBatchesByDepotId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByDepotId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByDepotIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/pharmacyId/{PharmacyId}", "GET")
// @ApiResponse(Description="Pharmacy Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByPharmacyId implements IReturn<GetDepotBatchesByPharmacyIdResponse>
{
    /** @description The id of the pharmacy */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="PharmacyId", ParameterType="path")
    public pharmacyId: number;

    public constructor(init?: Partial<GetDepotBatchesByPharmacyId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByPharmacyId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/drugShipmentId/{DrugShipmentId}", "GET")
// @ApiResponse(Description="Drug Shipment Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByDrugShipmentId implements IReturn<GetDepotBatchesByDrugShipmentIdResponse>
{
    /** @description The id of the drug shipment */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="DrugShipmentId", ParameterType="path")
    public drugShipmentId: number;

    public constructor(init?: Partial<GetDepotBatchesByDrugShipmentId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByDrugShipmentId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByDrugShipmentIdResponse(); }
}

// @Route("/drug-management-api/depot-batches/institution/{InstCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByInstitutionCode implements IReturn<GetDepotBatchesByPharmacyIdResponse>
{
    /** @description The institution code */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstCode", ParameterType="path")
    public instCode: string;

    public constructor(init?: Partial<GetDepotBatchesByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByPharmacyIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesByBatchIds implements IReturn<GetDepotBatchesByBatchIdsResponse>, IGet
{
    public batchIds: number[];

    public constructor(init?: Partial<GetDepotBatchesByBatchIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesByBatchIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesByBatchIdsResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotBatchesWithLowStockByDepotIds implements IReturn<GetDepotBatchesWithLowStockByDepotIdsResponse>, IGet
{
    public depotIds: number[];
    public drugThreshold?: number;

    public constructor(init?: Partial<GetDepotBatchesWithLowStockByDepotIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotBatchesWithLowStockByDepotIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotBatchesWithLowStockByDepotIdsResponse(); }
}

// @Route("/drug-management-api/depot-batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepotBatch implements IReturn<SetDepotBatchResponse>
{
    /** @description The depot batch form data to save */
    // @ApiMember(DataType="DepotBatch", Description="The depot batch form data to save", IsRequired=true, Name="DepotBatch", ParameterType="body")
    public depotBatch: DepotBatch;

    public constructor(init?: Partial<SetDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDepotBatchResponse(); }
}

// @Route("/drug-management-api/depots", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepots implements IReturn<GetDepotsResponse>, IGet
{

    public constructor(init?: Partial<GetDepots>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepots'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotsResponse(); }
}

// @Route("/drug-management-api/depot/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotById implements IReturn<GetDepotByIdResponse>, IGet
{
    /** @description The id of the drug depot */
    // @ApiMember(DataType="int", Description="The id of the drug depot", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDepotById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotByIdResponse(); }
}

// @Route("/drug-management-api/depots/by-drug-under-threshold", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDepotsByDrugUnderThreshold implements IReturn<GetDepotsByDrugUnderThresholdResponse>, IGet
{
    /** @description The threshold to check */
    // @ApiMember(DataType="int", Description="The threshold to check", IsRequired=true, Name="DrugThreshold", ParameterType="query")
    public drugThreshold: number;

    public constructor(init?: Partial<GetDepotsByDrugUnderThreshold>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDepotsByDrugUnderThreshold'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDepotsByDrugUnderThresholdResponse(); }
}

// @Route("/drug-management-api/depot/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDepot implements IReturn<SetDepotResponse>
{
    /** @description The drug depot form data to save */
    // @ApiMember(DataType="DepotForm", Description="The drug depot form data to save", IsRequired=true, Name="DepotForm", ParameterType="body")
    public depot: Depot;

    public constructor(init?: Partial<SetDepot>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDepot'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDepotResponse(); }
}

// @Route("/drug-management-api/drug-dispensations", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensations implements IReturn<GetDrugDispensationsResponse>, IGet
{

    public constructor(init?: Partial<GetDrugDispensations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationsResponse(); }
}

// @Route("/drug-management-api/drug-dispensation/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationById implements IReturn<GetDrugDispensationByIdResponse>, IGet
{
    /** @description The id of the drug dispensation */
    // @ApiMember(DataType="int", Description="The id of the drug dispensation", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugDispensationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationByIdResponse(); }
}

// @Route("/drug-management-api/drug-dispensations/patient-id/{PatientId}", "GET")
// @ApiResponse(Description="Patient id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationByPatientId implements IReturn<GetDrugDispensationByPatientIdResponse>, IGet
{
    /** @description The id of the patient to retrieve drug dispensations for */
    // @ApiMember(DataType="int", Description="The id of the patient to retrieve drug dispensations for", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetDrugDispensationByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationByPatientIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientsWithLateDispensations implements IReturn<GetPatientsWithLateDispensationsResponse>, IGet
{
    public expectedDate?: string;

    public constructor(init?: Partial<GetPatientsWithLateDispensations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientsWithLateDispensations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientsWithLateDispensationsResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetLastDispensationForPatientId implements IReturn<GetLastDispensationForPatientIdResponse>, IGet
{
    public patientId?: number;

    public constructor(init?: Partial<GetLastDispensationForPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLastDispensationForPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetLastDispensationForPatientIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugDispensationsByIds implements IReturn<GetDrugDispensationsByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugDispensationsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugDispensationsByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugDispensationsByIdsResponse(); }
}

// @Route("/drug-management-api/drug-dispensation/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugDispensation implements IReturn<SetDrugDispensationResponse>, IPost
{
    /** @description The patient dispensation form data to save */
    // @ApiMember(DataType="DrugDispensation", Description="The patient dispensation form data to save", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<SetDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugDispensationResponse(); }
}

export class DeleteDrugDispensation implements IReturn<DeleteDrugDispensationResponse>
{
    /** @description The drug dispensation we want to delete */
    // @ApiMember(DataType="DrugDispensation", Description="The drug dispensation we want to delete", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<DeleteDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteDrugDispensationResponse(); }
}

// @Route("/drug-management-api/drug-dispensation/validate", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ValidateDrugDispensation implements IReturn<ValidateDrugDispensationResponse>, IPost
{
    /** @description The drug dispensation form data to validate */
    // @ApiMember(DataType="model", Description="The drug dispensation form data to validate", IsRequired=true, Name="DrugDispensation", ParameterType="body")
    public drugDispensation: DrugDispensation;

    public constructor(init?: Partial<ValidateDrugDispensation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateDrugDispensation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateDrugDispensationResponse(); }
}

// @Route("/drug-management-api/drugs", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugs implements IReturn<GetDrugsResponse>
{

    public constructor(init?: Partial<GetDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugsResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batches", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatches implements IReturn<GetDrugShipmentDepotBatchesResponse>
{

    public constructor(init?: Partial<GetDrugShipmentDepotBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatches'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batch/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchById implements IReturn<GetDrugShipmentDepotBatchByIdResponse>
{
    /** @description The id of the drug shipment depot batch */
    // @ApiMember(DataType="int", Description="The id of the drug shipment depot batch", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchByIdResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batches/pharmacyId/{PharmacyId}", "GET")
// @ApiResponse(Description="Pharmacy Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchesByPharmacyId implements IReturn<GetDrugShipmentDepotBatchesByPharmacyIdResponse>
{
    /** @description The id of the pharmacy */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="PharmacyId", ParameterType="path")
    public pharmacyId: number;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByPharmacyId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByPharmacyId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batches/institution/{InstCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentDepotBatchesByInstitutionCode implements IReturn<GetDrugShipmentDepotBatchesByPharmacyIdResponse>
{
    /** @description The institution code */
    // @ApiMember(DataType="string", Description="The institution code", IsRequired=true, Name="InstCode", ParameterType="path")
    public instCode: string;

    public constructor(init?: Partial<GetDrugShipmentDepotBatchesByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentDepotBatchesByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentDepotBatchesByPharmacyIdResponse(); }
}

// @Route("/drug-management-api/drug-shipment-depot-batch/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentDepotBatch implements IReturn<SetDrugShipmentDepotBatchResponse>
{
    /** @description The drug shipment depot batch form data to save */
    // @ApiMember(DataType="DrugShipmentDepotBatch", Description="The drug shipment depot batch form data to save", IsRequired=true, Name="DrugShipmentDepotBatch", ParameterType="body")
    public drugShipmentDepotBatch: DrugShipmentDepotBatch;

    public constructor(init?: Partial<SetDrugShipmentDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentDepotBatchResponse(); }
}

// @Route("/drug-management-api/drug-shipment-generation/queue", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class StartDrugShipmentGeneration implements IReturn<StartDrugShipmentGenerationResponse>
{

    public constructor(init?: Partial<StartDrugShipmentGeneration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartDrugShipmentGeneration'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new StartDrugShipmentGenerationResponse(); }
}

// @Route("/drug-management-api/drug-shipment-generation/create-from-patient", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class CreateDrugShipmentFromPatient implements IReturn<CreateDrugShipmentFromPatientResponse>, IPost
{
    /** @description The id of the patient */
    // @ApiMember(DataType="int", Description="The id of the patient", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /** @description The drugs to order for the patient */
    // @ApiMember(DataType="model", Description="The drugs to order for the patient", IsRequired=true, Name="Drugs", ParameterType="body")
    public drugs: DrugAmount[];

    public constructor(init?: Partial<CreateDrugShipmentFromPatient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDrugShipmentFromPatient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateDrugShipmentFromPatientResponse(); }
}

// @Route("/drug-management-api/drug-shipments", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipments implements IReturn<GetDrugShipmentsResponse>, IGet
{

    public constructor(init?: Partial<GetDrugShipments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsResponse(); }
}

// @Route("/drug-management-api/drug-shipments/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Institution code invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsByInstitution implements IReturn<GetDrugShipmentsByInstitutionResponse>, IGet
{
    /** @description The institution of the pharmacies */
    // @ApiMember(DataType="string", Description="The institution of the pharmacies", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetDrugShipmentsByInstitution>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsByInstitution'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsByInstitutionResponse(); }
}

// @Route("/drug-management-api/drug-shipment/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentById implements IReturn<GetDrugShipmentByIdResponse>, IGet
{
    /** @description The id of the drug shipment */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentByIdResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsByIds implements IReturn<GetDrugShipmentsByIdsResponse>, IGet
{
    public ids: number[];

    public constructor(init?: Partial<GetDrugShipmentsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsByIdsResponse(); }
}

// @Route("/drug-management-api/drug-shipment/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipment implements IReturn<SetDrugShipmentResponse>, IPost
{
    /** @description The drug shipment form data to save */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to save", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentResponse(); }
}

// @Route("/drug-management-api/drug-shipment/validate", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ValidateDrugShipment implements IReturn<ValidateDrugShipmentResponse>, IPost
{
    /** @description The drug shipment form data to validate */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to validate", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<ValidateDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateDrugShipmentResponse(); }
}

// @Route("/drug-management-api/drug-shipment/set-status", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentStatus implements IReturn<SetDrugShipmentStatusResponse>, IPost
{
    /** @description The drug shipment form data to save */
    // @ApiMember(DataType="DrugShipmentForm", Description="The drug shipment form data to save", IsRequired=true, Name="DrugShipmentForm", ParameterType="body")
    public drugShipment: DrugShipment;

    public constructor(init?: Partial<SetDrugShipmentStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentStatusResponse(); }
}

// @Route("/drug-management-api/drug-shipment/delete", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class DeleteDrugShipment implements IReturn<DeleteDrugShipmentResponse>, IPost
{
    /** @description The drug shipment id to delete */
    // @ApiMember(DataType="int", Description="The drug shipment id to delete", IsRequired=true, Name="DrugShipmentId", ParameterType="body")
    public drugShipmentId: number;

    public constructor(init?: Partial<DeleteDrugShipment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDrugShipment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DeleteDrugShipmentResponse(); }
}

// @Route("/drug-management-api/drug-shipment/verify", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetDrugShipmentVerify implements IReturn<SetDrugShipmentVerifyResponse>, IPost
{
    /** @description The drug shipment form data to verify */
    // @ApiMember(DataType="model", Description="The drug shipment form data to verify", IsRequired=true, Name="DrugShipmentVerify", ParameterType="body")
    public drugShipmentVerify: DrugShipmentVerify;

    public constructor(init?: Partial<SetDrugShipmentVerify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDrugShipmentVerify'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetDrugShipmentVerifyResponse(); }
}

// @Route("/drug-management-api/drug-shipments/not-shipped-by-expected-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsNotShippedByExpectedDate implements IReturn<GetDrugShipmentsNotShippedByExpectedDateResponse>, IGet
{
    /** @description The date to check from */
    // @ApiMember(DataType="DateTime", Description="The date to check from", IsRequired=true, Name="ExpectedShippingDate", ParameterType="query")
    public expectedShippingDate: string;

    public constructor(init?: Partial<GetDrugShipmentsNotShippedByExpectedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsNotShippedByExpectedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsNotShippedByExpectedDateResponse(); }
}

// @Route("/drug-management-api/drug-shipments/not-received-by-expected-date", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentsNotReceivedByExpectedDate implements IReturn<GetDrugShipmentsNotReceivedByExpectedDateResponse>, IGet
{
    /** @description The date to check from */
    // @ApiMember(DataType="DateTime", Description="The date to check from", IsRequired=true, Name="ExpectedReceptionDate", ParameterType="query")
    public expectedReceptionDate: string;

    public constructor(init?: Partial<GetDrugShipmentsNotReceivedByExpectedDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentsNotReceivedByExpectedDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDrugShipmentsNotReceivedByExpectedDateResponse(); }
}

// @Route("/drug-management-api/drug-shipment-order-form/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetDrugShipmentOrderFormById implements IReturn<Blob>, IGet
{
    /** @description The id of the drug shipment */
    // @ApiMember(DataType="int", Description="The id of the drug shipment", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetDrugShipmentOrderFormById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDrugShipmentOrderFormById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/drug-management-api/order-forms", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetOrderForms implements IReturn<GetOrderFormsResponse>
{

    public constructor(init?: Partial<GetOrderForms>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetOrderFormsResponse(); }
}

// @Route("/drug-management-api/patient-drugs", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientDrugs implements IReturn<GetPatientDrugsResponse>, IGet
{

    public constructor(init?: Partial<GetPatientDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientDrugs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientDrugsResponse(); }
}

// @Route("/drug-management-api/patient-drugs/patient-id/{PatientId}", "GET")
// @ApiResponse(Description="Patient id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPatientDrugsByPatientId implements IReturn<GetPatientDrugsByPatientIdResponse>, IGet
{
    /** @description The id of the patient to retrieve patient drugs for */
    // @ApiMember(DataType="int", Description="The id of the patient to retrieve patient drugs for", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;

    public constructor(init?: Partial<GetPatientDrugsByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatientDrugsByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatientDrugsByPatientIdResponse(); }
}

// @Route("/drug-management-api/patient-drugs/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPatientDrugs implements IReturn<SetPatientDrugsResponse>, IPost
{
    /** @description The patient drug form data to save */
    // @ApiMember(DataType="model", Description="The patient drug form data to save", IsRequired=true, Name="PatientDrugs", ParameterType="body")
    public patientDrugs: PatientDrug[];

    public constructor(init?: Partial<SetPatientDrugs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPatientDrugs'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPatientDrugsResponse(); }
}

export class GetPharmacistsByInstCode implements IReturn<GetPharmacistsByInstCodeResponse>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetPharmacistsByInstCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacistsByInstCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacistsByInstCodeResponse(); }
}

export class GetPharmacistById implements IReturn<GetPharmacistByIdResponse>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetPharmacistById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacistById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacistByIdResponse(); }
}

// @Route("/drug-management-api/pharmacies", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacies implements IReturn<GetPharmaciesResponse>, IGet
{

    public constructor(init?: Partial<GetPharmacies>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacies'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmaciesResponse(); }
}

// @Route("/drug-management-api/pharmacy/id/{Id}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyById implements IReturn<GetPharmacyByIdResponse>, IGet
{
    /** @description The id of the pharmacy */
    // @ApiMember(DataType="int", Description="The id of the pharmacy", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetPharmacyById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyByIdResponse(); }
}

// @Route("/drug-management-api/pharmacy/institution/{InstitutionCode}", "GET")
// @ApiResponse(Description="Id invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyByInstitutionCode implements IReturn<GetPharmacyByInstitutionCodeResponse>, IGet
{
    /** @description The institution code of the pharmacy */
    // @ApiMember(DataType="string", Description="The institution code of the pharmacy", IsRequired=true, Name="InstitutionCode", ParameterType="path")
    public institutionCode: string;

    public constructor(init?: Partial<GetPharmacyByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyByInstitutionCodeResponse(); }
}

// @ApiResponse(Description="Ids invalid", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetPharmacyBatchesDrugRemainingByBatchIds implements IReturn<GetPharmacyBatchesDrugRemainingByBatchIdsResponse>, IGet
{
    public batchIds: number[];
    public includeExhaustedBatches?: boolean;

    public constructor(init?: Partial<GetPharmacyBatchesDrugRemainingByBatchIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPharmacyBatchesDrugRemainingByBatchIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPharmacyBatchesDrugRemainingByBatchIdsResponse(); }
}

// @Route("/drug-management-api/pharmacy/save", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class SetPharmacy implements IReturn<SetPharmacyResponse>, IPost
{
    /** @description The pharmacy form data to save */
    // @ApiMember(DataType="model", Description="The pharmacy form data to save", IsRequired=true, Name="PharmacyForm", ParameterType="body")
    public pharmacy: Pharmacy;

    public constructor(init?: Partial<SetPharmacy>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPharmacy'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetPharmacyResponse(); }
}

// @Route("/drug-management-api/pharmacy/quarantine-stock", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantinePharmacyStock implements IReturn<QuarantinePharmacyStockResponse>, IPost
{
    /** @description The pharmacy id to quarantine */
    // @ApiMember(DataType="integer", Description="The pharmacy id to quarantine", IsRequired=true, Name="Id", ParameterType="body")
    public id: number;

    public constructor(init?: Partial<QuarantinePharmacyStock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantinePharmacyStock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantinePharmacyStockResponse(); }
}

// @Route("/drug-management-api/pharmacy/quarantine-depot-batches", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantinePharmacyDepotBatch implements IReturn<QuarantinePharmacyDepotBatchResponse>, IPost
{
    /** @description The pharmacy depot batch ids to quarantine */
    // @ApiMember(DataType="integer[]", Description="The pharmacy depot batch ids to quarantine", IsRequired=true, Name="Ids", ParameterType="body")
    public ids: number[];

    public constructor(init?: Partial<QuarantinePharmacyDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantinePharmacyDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantinePharmacyDepotBatchResponse(); }
}

// @Route("/drug-management-api/pharmacy/action-quarantined-stock", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ActionQuarantinedStock implements IReturn<ActionQuarantinedStockResponse>, IPost
{
    /** @description The pharmacy id to quarantine */
    // @ApiMember(DataType="integer", Description="The pharmacy id to quarantine", IsRequired=true, Name="Id", ParameterType="body")
    public id: number;

    /** @description The action to take on the pharmacy's stock */
    // @ApiMember(DataType="integer", Description="The action to take on the pharmacy's stock", IsRequired=true, Name="QuarantinedStockAction", ParameterType="body")
    public quarantinedStockAction: QuarantinedStockAction;

    public constructor(init?: Partial<ActionQuarantinedStock>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActionQuarantinedStock'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActionQuarantinedStockResponse(); }
}

// @Route("/drug-management-api/pharmacy/action-depot-batches", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantineRemovePharmacyDepotBatch implements IReturn<QuarantineRemovePharmacyDepotBatchResponse>, IPost
{
    /** @description The pharmacy id related to these depot batches */
    // @ApiMember(DataType="integer", Description="The pharmacy id related to these depot batches", IsRequired=true, Name="PharmacyId", ParameterType="body")
    public pharmacyId: number;

    /** @description A dictionary of the depot batch ids correlating to the new state of the unit count */
    // @ApiMember(DataType="dictionary", Description="A dictionary of the depot batch ids correlating to the new state of the unit count", IsRequired=true, Name="DepotBatchStates", ParameterType="body")
    public depotBatchStates: { [index: number]: QuarantinedStockAction; };

    public constructor(init?: Partial<QuarantineRemovePharmacyDepotBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantineRemovePharmacyDepotBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantineRemovePharmacyDepotBatchResponse(); }
}

// @Route("/drug-management-api/pharmacy/quarantine-batches", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class QuarantinePharmacyBatches implements IReturn<QuarantinePharmacyBatchesResponse>, IPost
{
    /** @description The pharmacy id related to these batches */
    // @ApiMember(DataType="integer", Description="The pharmacy id related to these batches", IsRequired=true, Name="PharmacyId", ParameterType="body")
    public pharmacyId: number;

    /** @description The batch ids to quarantine at the pharmacy */
    // @ApiMember(DataType="array", Description="The batch ids to quarantine at the pharmacy", IsRequired=true, Name="Ids", ParameterType="body")
    public ids: number[];

    public constructor(init?: Partial<QuarantinePharmacyBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuarantinePharmacyBatches'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuarantinePharmacyBatchesResponse(); }
}

// @Route("/drug-management-api/pharmacy/action-quarantined-batches", "POST")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class ActionQuarantinedPharmacyBatches implements IReturn<ActionQuarantinedPharmacyBatchesResponse>, IPost
{
    /** @description The pharmacy id related to these batches */
    // @ApiMember(DataType="integer", Description="The pharmacy id related to these batches", IsRequired=true, Name="PharmacyId", ParameterType="body")
    public pharmacyId: number;

    /** @description A dictionary of the batch ids correlating to the new state of the unit count */
    // @ApiMember(DataType="model", Description="A dictionary of the batch ids correlating to the new state of the unit count", IsRequired=true, Name="BatchActions", ParameterType="body")
    public batchActions: { [index: number]: QuarantinedStockAction; };

    public constructor(init?: Partial<ActionQuarantinedPharmacyBatches>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActionQuarantinedPharmacyBatches'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActionQuarantinedPharmacyBatchesResponse(); }
}

// @Route("/drug-management-api/timepoints", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepoints implements IReturn<GetTimepointsResponse>
{

    public constructor(init?: Partial<GetTimepoints>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimepoints'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTimepointsResponse(); }
}

// @Route("/drug-management-api/timepoints/treatment/{TreatmentId}", "GET")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTimepointsByTreatmentId implements IReturn<GetTimepointsResponse>
{
    /** @description The treatment id to get timepoints for */
    // @ApiMember(DataType="int", Description="The treatment id to get timepoints for", IsRequired=true, Name="TreatmentId", ParameterType="path")
    public treatmentId: number;

    public constructor(init?: Partial<GetTimepointsByTreatmentId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimepointsByTreatmentId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTimepointsResponse(); }
}

// @Route("/opms/invitation/collection", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetCollection implements IReturn<InvitationGetCollectionResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetCollectionResponse(); }
}

// @Route("/opms/invitation/single/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleById implements IReturn<InvitationGetSingleByIdResponse>, IGet
{
    public id?: number;

    public constructor(init?: Partial<InvitationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByIdResponse(); }
}

// @Route("/opms/invitation/single/{Guid}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleByGuid implements IReturn<InvitationGetSingleByGuidResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<InvitationGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByGuidResponse(); }
}

// @Route("/opms/invitation/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationSave implements IReturn<InvitationSaveResponse>, IPost
{
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvitationSaveResponse(); }
}

// @Route("/opms/invitation/delete/{Id}", "DELETE")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationDelete implements IReturn<InvitationDeleteResponse>, IDelete
{
    public id?: number;

    public constructor(init?: Partial<InvitationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new InvitationDeleteResponse(); }
}

// @Route("/opms/invitation/investigators", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetInvestigators implements IReturn<InvitationGetInvestigatorsResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetInvestigators>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetInvestigators'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetInvestigatorsResponse(); }
}

// @Route("/opms/picf/{InstCode}/{PatientConsentId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetPicfs implements IReturn<GetPicfsResponse>, IGet
{
    public instCode: string;
    public patientConsentId?: number;

    public constructor(init?: Partial<GetPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPicfsResponse(); }
}

// @Route("/opms/picf/download/all/{InstCode}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicfs implements IReturn<Blob>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetDownloadPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/picf/download/{InstCode}/{PicfId}/{PicfTypeId}/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicf implements IReturn<Blob>, IGet
{
    public instCode: string;
    public picfId?: number;
    public picfTypeId?: number;

    public constructor(init?: Partial<GetDownloadPicf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/eoi/single/id/{Id}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiGetSingleById implements IReturn<EoiSingleResponse>, IEoiGetSingleById
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiSingleResponse(); }
}

// @Route("/opms/eoi/collection", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollection implements IReturn<EoiCollectionResponse>, IEoiGetCollection
{

    public constructor(init?: Partial<EoiGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

// @Route("/opms/eoi/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollectionByInstitutionCode implements IReturn<EoiCollectionResponse>, IEoiGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<EoiGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

// @Route("/opms/eoi/save", "POST")
// @Api(Description="Eoi")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiPostSave implements IReturn<EoiSingleResponse>
{
    // @DataMember(Order=1)
    public eoi: Eoi;

    // @DataMember(Order=2)
    public invitationGuid: string;

    public constructor(init?: Partial<EoiPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EoiSingleResponse(); }
}

// @Route("/opms/eoi/delete/{Id}", "DELETE")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=500)
// @DataContract
export class EoiDelete implements IReturn<EoiDeleteResponse>, IEoiDelete
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EoiDeleteResponse(); }
}

// @Route("/opms/eoi/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class EoiSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<EoiSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patientconsent/single/id/{Id}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleById implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleById
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

// @Route("/opms/patientconsent/single/guid/{Guid}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleByGuid implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleByGuid
{
    // @DataMember(Order=1)
    public guid: string;

    public constructor(init?: Partial<PatientConsentGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

// @Route("/opms/patientconsent/collection", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollection implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollection
{

    public constructor(init?: Partial<PatientConsentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

// @Route("/opms/patientconsent/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollectionByInstitutionCode implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<PatientConsentGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

// @Route("/opms/patientconsent/save", "POST")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentPostSave implements IReturn<PatientConsentSingleResponse>
{
    // @DataMember(Order=1)
    public patientConsent: PatientConsent;

    // @DataMember(Order=1)
    public emailRequired: boolean;

    public constructor(init?: Partial<PatientConsentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

// @Route("/opms/patientconsent/delete/{Id}", "DELETE")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientConsentDelete implements IReturn<PatientConsentDeleteResponse>, IPatientConsentDelete
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientConsentDeleteResponse(); }
}

// @Route("/opms/patient-consent/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<PatientConsentSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-reminder", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendReminder implements IReturn<ResponseStatus>, IPost
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentSendReminder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendReminder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-verification-code", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendVerificationCode implements IReturn<PatientConsentSendVerificationCodeResponse>, IPost
{
    public phoneNumber: string;

    public constructor(init?: Partial<PatientConsentSendVerificationCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendVerificationCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSendVerificationCodeResponse(); }
}

// @Route("/opms/patient-consent/view-consent-file/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentViewConsentFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentViewConsentFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentViewConsentFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/sae/medicalreview/{PatientStudyNumber}/{SaeNumber}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewSingleByPatientStudyNumber implements IReturn<SaeMedicalReviewSingleByPatientStudyNumberResponse>
{
    /** @description The patient study number of the sae */
    // @DataMember(Order=1)
    // @ApiMember(Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="string")
    public patientStudyNumber: string;

    /** @description The sae number */
    // @DataMember(Order=2)
    // @ApiMember(Description="The sae number", IsRequired=true, Name="SaeNumber", ParameterType="int")
    public saeNumber: number;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeMedicalReviewSingleByPatientStudyNumberResponse(); }
}

// @Route("/opms/sae/medicalreview/save-narrative-summary", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewNarrativeSummaryPostSave implements IReturn<SaeMedicalReviewNarrativeSummaryPostSaveResponse>
{
    /** @description The medical review id of the narrative to save */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The medical review id of the narrative to save", IsRequired=true, Name="MedicalReviewId", ParameterType="body")
    public medicalReviewId: number;

    /** @description The narrative to save */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The narrative to save", IsRequired=true, Name="NarrativeSummary", ParameterType="body")
    public narrativeSummary: string;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewNarrativeSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewNarrativeSummaryPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewPostSave implements IReturn<SaeMedicalReviewPostSaveResponse>
{
    /** @description The medical review to save */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The medical review to save", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/validate", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateMedicalReview implements IReturn<SaeValidateMedicalReviewResponse>
{
    /** @description The medical review */
    // @DataMember(Order=1)
    // @ApiMember(DataType="MedicalReview", Description="The medical review", IsRequired=true, Name="MedicalReview", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeValidateMedicalReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateMedicalReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateMedicalReviewResponse(); }
}

// @Route("/opms/sae/query/{PatientStudyNumber}/{SaeNumber}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeQueryCollectionByPatientSaeNumber implements IReturn<SaeQueryCollectionByPatientSaeNumberResponse>
{
    /** @description The patient study number of the sae */
    // @DataMember(Order=1)
    // @ApiMember(Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="string")
    public patientStudyNumber: string;

    /** @description The sae number */
    // @DataMember(Order=2)
    // @ApiMember(Description="The sae number", IsRequired=true, Name="SaeNumber", ParameterType="int")
    public saeNumber: number;

    public constructor(init?: Partial<SaeQueryCollectionByPatientSaeNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeQueryCollectionByPatientSaeNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeQueryCollectionByPatientSaeNumberResponse(); }
}

// @Route("/opms/sae/query/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeQueryPostSave implements IReturn<SaeQueryPostSaveResponse>
{
    /** @description The queries to save */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The queries to save", IsRequired=true, Name="Queries", ParameterType="body")
    public queries: Query[];

    public constructor(init?: Partial<SaeQueryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeQueryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeQueryPostSaveResponse(); }
}

// @Route("/api/sae/collection", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollection implements IReturn<SaeCollectionResponse>, ISaeGetCollection
{

    public constructor(init?: Partial<SaeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

// @Route("/api/sae/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollectionByInstitutionCode implements IReturn<SaeCollectionResponse>, ISaeGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<SaeGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

// @Route("/api/sae/single/patient-study-number/{PatientStudyNumber}/sae-number/{SaeNumber}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=500)
// @DataContract
export class SaeGetSingleByPatientStudyNumber implements IReturn<SaeSingleResponse>, ISaeGetSingleByPatientStudyNumber
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public saeNumber: number;

    public constructor(init?: Partial<SaeGetSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeSingleResponse(); }
}

// @Route("/opms/sae/collection/status", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetCollectionByStatus implements IReturn<SaeGetCollectionByStatusResponse>
{
    /** @description The status of the saes */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The status of the saes", IsRequired=true, Name="Status", ParameterType="body")
    public status: SaeStatus;

    public constructor(init?: Partial<SaeGetCollectionByStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeGetCollectionByStatusResponse(); }
}

// @Route("/opms/sae/incomplete/forms", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetIncompleteForms implements IReturn<SaeGetIncompleteFormsResponse>
{

    public constructor(init?: Partial<SaeGetIncompleteForms>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetIncompleteForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeGetIncompleteFormsResponse(); }
}

// @Route("/opms/sae/webaction", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeWebActionSave implements IReturn<SaeWebActionSaveResponse>
{
    /** @description The sae web action */
    // @DataMember(Order=1)
    // @ApiMember(Description="The sae web action", IsRequired=true, Name="WebAction", ParameterType="model")
    public webAction: WebAction;

    public constructor(init?: Partial<SaeWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeWebActionSaveResponse(); }
}

// @Route("/opms/sae/webaction/medicalreview", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewWebActionSave implements IReturn<SaeMedicalReviewWebActionSaveResponse>
{
    /** @description The patient study number of the sae */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /** @description The sae number of the sae */
    // @DataMember(Order=2)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /** @description The medical review form from which the sae web action will be based off */
    // @DataMember(Order=3)
    // @ApiMember(Description="The medical review form from which the sae web action will be based off", IsRequired=true, Name="SaeMedicalReviewForm", ParameterType="model")
    public medicalReview: SaeMedicalReviewForm;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewWebActionSaveResponse(); }
}

// @Route("/opms/sae/send-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeSendNotification implements IReturn<SaeSendNotificationResponse>
{
    /** @description The patient study number of the sae */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /** @description The sae number of the sae */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /** @description The notification form */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public notificationForm: SaeNotificationForm;

    public constructor(init?: Partial<SaeSendNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeSendNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeSendNotificationResponse(); }
}

// @Route("/opms/sae/validate-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateNotificationForm implements IReturn<SaeValidateNotificationFormResponse>
{
    /** @description The notification form */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public form: SaeNotificationForm;

    public constructor(init?: Partial<SaeValidateNotificationForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateNotificationForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateNotificationFormResponse(); }
}

// @Route("/opms/sae/validate-rejection", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateRejectionForm implements IReturn<SaeValidateRejectionFormResponse>
{
    /** @description The rejection form */
    // @DataMember(Order=1)
    // @ApiMember(DataType="WebAction", Description="The rejection form", IsRequired=true, Name="RejectionForm", ParameterType="body")
    public form: WebAction;

    public constructor(init?: Partial<SaeValidateRejectionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateRejectionForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateRejectionFormResponse(); }
}

// @Route("/opms/sae/open-clinica/document", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    /** @description The file name of the document in openclinica */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The file name of the document in openclinica", IsRequired=true, Name="FileName", ParameterType="body")
    public fileName: string;

    public constructor(init?: Partial<SaeGetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/sae/file/download/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class DownloadInvalidSaeFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadInvalidSaeFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadInvalidSaeFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/sae/file/view/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetInvalidSaeFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetInvalidSaeFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvalidSaeFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/sae/invalid-form/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeInvalidFormPostSave implements IReturn<SaeInvalidFormPostSaveResponse>
{
    /** @description The invalid sae form to save */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The invalid sae form to save", IsRequired=true, Name="InvalidSaeForm", ParameterType="body")
    public invalidSaeForm: InvalidSaeForm;

    public constructor(init?: Partial<SaeInvalidFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeInvalidFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeInvalidFormPostSaveResponse(); }
}

// @Route("/opms/sae/invalid-form/validate", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateInvalidForm implements IReturn<SaeValidateInvalidFormResponse>
{
    /** @description The invalid sae form to validate */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The invalid sae form to validate", IsRequired=true, Name="InvalidSaeForm", ParameterType="body")
    public invalidSaeForm: InvalidSaeForm;

    public constructor(init?: Partial<SaeValidateInvalidForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateInvalidForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateInvalidFormResponse(); }
}

// @Route("/opms/screeninglog/single/id/{Id}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleById implements IReturn<ScreeningLogSingleResponse>, IScreeningLogGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

// @Route("/opms/screeninglog/collection", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollection implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollection
{

    public constructor(init?: Partial<ScreeningLogGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

// @Route("/opms/screeninglog/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollectionByInstitutionId implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollectionByInstitutionId
{
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningLogGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

// @Route("/opms/screeninglog/save", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostSave implements IReturn<ScreeningLogSingleResponse>
{
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

// @Route("/opms/screeninglog/delete/{ScreeningLogId}", "DELETE")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogDelete implements IReturn<ScreeningLogDeleteResponse>, IScreeningLogDelete
{
    // @DataMember(Order=1)
    public screeningLogId: number;

    public constructor(init?: Partial<ScreeningLogDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ScreeningLogDeleteResponse(); }
}

// @Route("/opms/screeninglog/validate", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostValidate implements IReturn<ScreeningLogFormValidationResponse>
{
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogFormValidationResponse(); }
}

// @Route("/opms/screeninglog/single/patient-id/{PatientId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleByPatientId implements IReturn<ScreeningLogSingleByPatientIdResponse>, IScreeningLogGetSingleByPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetSingleByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogSingleByPatientIdResponse(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    // @DataMember(Order=1)
    public countryId: number;

    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    // @DataMember(Order=1)
    public institutionId: number;

    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    // @DataMember(Order=1)
    public countryCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public patient: Patient;

    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public patient: Patient;

    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public institutionId?: number;

    // @DataMember(Order=5)
    public createPatient?: boolean;

    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public institutionCode: string;

    // @DataMember(Order=5)
    public createPatient?: boolean;

    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    // @DataMember(Order=1)
    public event: Event;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    // @DataMember(Order=1)
    public event: Event;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/single/ids", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByIds implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/single/codes", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByCodes implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/collection", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByIds implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByCodes implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/source-document/save", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/save/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithIds implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/save/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithCodes implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/delete", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDelete implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithId implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/delete/ids", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithIds implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/delete/codes", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteByCodes implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/validate", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/opms/form/source-document/validate/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithIds implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/opms/form/source-document/validate/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithCodes implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/opms/form/waiver-source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentGetSingleById implements IReturn<WaiverSourceDocumentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<WaiverSourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSourceDocumentSingleResponse(); }
}

// @Route("/opms/form/waiver-source-document/single/ids", "GET")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentGetSingleByIds implements IReturn<WaiverSourceDocumentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<WaiverSourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSourceDocumentSingleResponse(); }
}

// @Route("/opms/form/waiver-source-document/single/codes", "GET")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentGetSingleByCodes implements IReturn<WaiverSourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<WaiverSourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSourceDocumentSingleResponse(); }
}

// @Route("/opms/form/waiver-source-document/collection", "GET")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentGetCollection implements IReturn<WaiverSourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<WaiverSourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/waiver-source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentGetCollectionByIds implements IReturn<WaiverSourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverSourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/waiver-source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentGetCollectionByCodes implements IReturn<WaiverSourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverSourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/waiver-source-document/save", "POST")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentPostSave implements IReturn<WaiverSourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    public constructor(init?: Partial<WaiverSourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSourceDocumentSingleResponse(); }
}

// @Route("/opms/form/waiver-source-document/save/ids", "POST")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentPostSaveWithIds implements IReturn<WaiverSourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<WaiverSourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSourceDocumentSingleResponse(); }
}

// @Route("/opms/form/waiver-source-document/save/codes", "POST")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentPostSaveWithCodes implements IReturn<WaiverSourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<WaiverSourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSourceDocumentSingleResponse(); }
}

// @Route("/opms/form/waiver-source-document/delete", "DELETE")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentDelete implements IReturn<WaiverSourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    public constructor(init?: Partial<WaiverSourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverSourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/waiver-source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentDeleteWithId implements IReturn<WaiverSourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<WaiverSourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverSourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/waiver-source-document/delete/ids", "DELETE")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentDeleteWithIds implements IReturn<WaiverSourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<WaiverSourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverSourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/waiver-source-document/delete/codes", "DELETE")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentDeleteByCodes implements IReturn<WaiverSourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<WaiverSourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverSourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/waiver-source-document/validate", "POST")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentPostValidate implements IReturn<WaiverSourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    public constructor(init?: Partial<WaiverSourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSourceDocumentValidationResponse(); }
}

// @Route("/opms/form/waiver-source-document/validate/ids", "POST")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentPostValidateWithIds implements IReturn<WaiverSourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverSourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSourceDocumentValidationResponse(); }
}

// @Route("/opms/form/waiver-source-document/validate/codes", "POST")
// @Api(Description="Form - Waiver Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverSourceDocumentPostValidateWithCodes implements IReturn<WaiverSourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: WaiverSourceDocument;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverSourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverSourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSourceDocumentValidationResponse(); }
}

// @Route("/opms/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/opms/form/concomitant-medication/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByIds implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/opms/form/concomitant-medication/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByCodes implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/opms/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

// @Route("/opms/form/concomitant-medication/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByIds implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

// @Route("/opms/form/concomitant-medication/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByCodes implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

// @Route("/opms/form/concomitant-medication/save", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/opms/form/concomitant-medication/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithIds implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/opms/form/concomitant-medication/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithCodes implements IReturn<ConcomitantMedicationSingleResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

// @Route("/opms/form/concomitant-medication/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDelete implements IReturn<ConcomitantMedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

// @Route("/opms/form/concomitant-medication/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithId implements IReturn<ConcomitantMedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

// @Route("/opms/form/concomitant-medication/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithIds implements IReturn<ConcomitantMedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

// @Route("/opms/form/concomitant-medication/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteByCodes implements IReturn<ConcomitantMedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

// @Route("/opms/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

// @Route("/opms/form/concomitant-medication/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithIds implements IReturn<ConcomitantMedicationValidationResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

// @Route("/opms/form/concomitant-medication/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithCodes implements IReturn<ConcomitantMedicationValidationResponse>
{
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

// @Route("/opms/form/consent/single/id/{Id}", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentGetSingleById implements IReturn<ConsentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConsentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/single/ids", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentGetSingleByIds implements IReturn<ConsentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConsentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/single/codes", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentGetSingleByCodes implements IReturn<ConsentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConsentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/collection", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentGetCollection implements IReturn<ConsentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConsentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentCollectionResponse(); }
}

// @Route("/opms/form/consent/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentGetCollectionByIds implements IReturn<ConsentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentCollectionResponse(); }
}

// @Route("/opms/form/consent/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentGetCollectionByCodes implements IReturn<ConsentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentCollectionResponse(); }
}

// @Route("/opms/form/consent/save", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentPostSave implements IReturn<ConsentSingleResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    public constructor(init?: Partial<ConsentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/save/ids", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentPostSaveWithIds implements IReturn<ConsentSingleResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConsentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/save/codes", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentPostSaveWithCodes implements IReturn<ConsentSingleResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConsentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/delete", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDelete implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    public constructor(init?: Partial<ConsentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDeleteWithId implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConsentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/delete/ids", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDeleteWithIds implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConsentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/delete/codes", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDeleteByCodes implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConsentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/validate", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentPostValidate implements IReturn<ConsentValidationResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    public constructor(init?: Partial<ConsentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentValidationResponse(); }
}

// @Route("/opms/form/consent/validate/ids", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentPostValidateWithIds implements IReturn<ConsentValidationResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentValidationResponse(); }
}

// @Route("/opms/form/consent/validate/codes", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentPostValidateWithCodes implements IReturn<ConsentValidationResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentValidationResponse(); }
}

// @Route("/opms/form/demographics/single/id/{Id}", "GET")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicsGetSingleById implements IReturn<DemographicsSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DemographicsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicsSingleResponse(); }
}

// @Route("/opms/form/demographics/single/ids", "GET")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicsGetSingleByIds implements IReturn<DemographicsSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DemographicsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicsSingleResponse(); }
}

// @Route("/opms/form/demographics/single/codes", "GET")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicsGetSingleByCodes implements IReturn<DemographicsSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DemographicsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicsSingleResponse(); }
}

// @Route("/opms/form/demographics/collection", "GET")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicsGetCollection implements IReturn<DemographicsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DemographicsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicsCollectionResponse(); }
}

// @Route("/opms/form/demographics/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicsGetCollectionByIds implements IReturn<DemographicsCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicsCollectionResponse(); }
}

// @Route("/opms/form/demographics/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicsGetCollectionByCodes implements IReturn<DemographicsCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicsCollectionResponse(); }
}

// @Route("/opms/form/demographics/save", "POST")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicsPostSave implements IReturn<DemographicsSingleResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    public constructor(init?: Partial<DemographicsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicsSingleResponse(); }
}

// @Route("/opms/form/demographics/save/ids", "POST")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicsPostSaveWithIds implements IReturn<DemographicsSingleResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DemographicsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicsSingleResponse(); }
}

// @Route("/opms/form/demographics/save/codes", "POST")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicsPostSaveWithCodes implements IReturn<DemographicsSingleResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DemographicsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicsSingleResponse(); }
}

// @Route("/opms/form/demographics/delete", "DELETE")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicsDelete implements IReturn<DemographicsDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    public constructor(init?: Partial<DemographicsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicsDeleteResponse(); }
}

// @Route("/opms/form/demographics/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicsDeleteWithId implements IReturn<DemographicsDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DemographicsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicsDeleteResponse(); }
}

// @Route("/opms/form/demographics/delete/ids", "DELETE")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicsDeleteWithIds implements IReturn<DemographicsDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DemographicsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicsDeleteResponse(); }
}

// @Route("/opms/form/demographics/delete/codes", "DELETE")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicsDeleteByCodes implements IReturn<DemographicsDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DemographicsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicsDeleteResponse(); }
}

// @Route("/opms/form/demographics/validate", "POST")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicsPostValidate implements IReturn<DemographicsValidationResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    public constructor(init?: Partial<DemographicsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicsValidationResponse(); }
}

// @Route("/opms/form/demographics/validate/ids", "POST")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicsPostValidateWithIds implements IReturn<DemographicsValidationResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicsValidationResponse(); }
}

// @Route("/opms/form/demographics/validate/codes", "POST")
// @Api(Description="Form - Demographics")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicsPostValidateWithCodes implements IReturn<DemographicsValidationResponse>
{
    // @DataMember(Order=1)
    public form: Demographics;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicsValidationResponse(); }
}

// @Route("/opms/form/eligibility-checklist/single/id/{Id}", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetSingleById implements IReturn<EligibilityChecklistSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityChecklistGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

// @Route("/opms/form/eligibility-checklist/single/ids", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetSingleByIds implements IReturn<EligibilityChecklistSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityChecklistGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

// @Route("/opms/form/eligibility-checklist/single/codes", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetSingleByCodes implements IReturn<EligibilityChecklistSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityChecklistGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

// @Route("/opms/form/eligibility-checklist/collection", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetCollection implements IReturn<EligibilityChecklistCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EligibilityChecklistGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistCollectionResponse(); }
}

// @Route("/opms/form/eligibility-checklist/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetCollectionByIds implements IReturn<EligibilityChecklistCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistCollectionResponse(); }
}

// @Route("/opms/form/eligibility-checklist/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetCollectionByCodes implements IReturn<EligibilityChecklistCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistCollectionResponse(); }
}

// @Route("/opms/form/eligibility-checklist/save", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostSave implements IReturn<EligibilityChecklistSingleResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

// @Route("/opms/form/eligibility-checklist/save/ids", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostSaveWithIds implements IReturn<EligibilityChecklistSingleResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityChecklistPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

// @Route("/opms/form/eligibility-checklist/save/codes", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostSaveWithCodes implements IReturn<EligibilityChecklistSingleResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityChecklistPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

// @Route("/opms/form/eligibility-checklist/delete", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDelete implements IReturn<EligibilityChecklistDeleteResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

// @Route("/opms/form/eligibility-checklist/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDeleteWithId implements IReturn<EligibilityChecklistDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityChecklistDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

// @Route("/opms/form/eligibility-checklist/delete/ids", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDeleteWithIds implements IReturn<EligibilityChecklistDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

// @Route("/opms/form/eligibility-checklist/delete/codes", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDeleteByCodes implements IReturn<EligibilityChecklistDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

// @Route("/opms/form/eligibility-checklist/validate", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostValidate implements IReturn<EligibilityChecklistValidationResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistValidationResponse(); }
}

// @Route("/opms/form/eligibility-checklist/validate/ids", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostValidateWithIds implements IReturn<EligibilityChecklistValidationResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistValidationResponse(); }
}

// @Route("/opms/form/eligibility-checklist/validate/codes", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostValidateWithCodes implements IReturn<EligibilityChecklistValidationResponse>
{
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistValidationResponse(); }
}

// @Route("/opms/form/general/single/id/{Id}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleById implements IReturn<GeneralSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

// @Route("/opms/form/general/single/ids", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByIds implements IReturn<GeneralSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

// @Route("/opms/form/general/single/codes", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByCodes implements IReturn<GeneralSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

// @Route("/opms/form/general/collection", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollection implements IReturn<GeneralCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<GeneralGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

// @Route("/opms/form/general/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByIds implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

// @Route("/opms/form/general/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByCodes implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

// @Route("/opms/form/general/save", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSave implements IReturn<GeneralSingleResponse>
{
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

// @Route("/opms/form/general/save/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithIds implements IReturn<GeneralSingleResponse>
{
    // @DataMember(Order=1)
    public form: General;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

// @Route("/opms/form/general/save/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithCodes implements IReturn<GeneralSingleResponse>
{
    // @DataMember(Order=1)
    public form: General;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

// @Route("/opms/form/general/delete", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDelete implements IReturn<GeneralDeleteResponse>
{
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

// @Route("/opms/form/general/delete/id/{Id}", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithId implements IReturn<GeneralDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

// @Route("/opms/form/general/delete/ids", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithIds implements IReturn<GeneralDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

// @Route("/opms/form/general/delete/codes", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteByCodes implements IReturn<GeneralDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

// @Route("/opms/form/general/validate", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidate implements IReturn<GeneralValidationResponse>
{
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

// @Route("/opms/form/general/validate/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithIds implements IReturn<GeneralValidationResponse>
{
    // @DataMember(Order=1)
    public form: General;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

// @Route("/opms/form/general/validate/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithCodes implements IReturn<GeneralValidationResponse>
{
    // @DataMember(Order=1)
    public form: General;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentGetSingleById implements IReturn<ImagingAndTumourAssessmentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingAndTumourAssessmentSingleResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/single/ids", "GET")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentGetSingleByIds implements IReturn<ImagingAndTumourAssessmentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ImagingAndTumourAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingAndTumourAssessmentSingleResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/single/codes", "GET")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentGetSingleByCodes implements IReturn<ImagingAndTumourAssessmentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ImagingAndTumourAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingAndTumourAssessmentSingleResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/collection", "GET")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentGetCollection implements IReturn<ImagingAndTumourAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ImagingAndTumourAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingAndTumourAssessmentCollectionResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentGetCollectionByIds implements IReturn<ImagingAndTumourAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingAndTumourAssessmentCollectionResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentGetCollectionByCodes implements IReturn<ImagingAndTumourAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImagingAndTumourAssessmentCollectionResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/save", "POST")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentPostSave implements IReturn<ImagingAndTumourAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    public constructor(init?: Partial<ImagingAndTumourAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingAndTumourAssessmentSingleResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/save/ids", "POST")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentPostSaveWithIds implements IReturn<ImagingAndTumourAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ImagingAndTumourAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingAndTumourAssessmentSingleResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/save/codes", "POST")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentPostSaveWithCodes implements IReturn<ImagingAndTumourAssessmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ImagingAndTumourAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingAndTumourAssessmentSingleResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/delete", "DELETE")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentDelete implements IReturn<ImagingAndTumourAssessmentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    public constructor(init?: Partial<ImagingAndTumourAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImagingAndTumourAssessmentDeleteResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentDeleteWithId implements IReturn<ImagingAndTumourAssessmentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImagingAndTumourAssessmentDeleteResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentDeleteWithIds implements IReturn<ImagingAndTumourAssessmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImagingAndTumourAssessmentDeleteResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentDeleteByCodes implements IReturn<ImagingAndTumourAssessmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImagingAndTumourAssessmentDeleteResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/validate", "POST")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentPostValidate implements IReturn<ImagingAndTumourAssessmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    public constructor(init?: Partial<ImagingAndTumourAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingAndTumourAssessmentValidationResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/validate/ids", "POST")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentPostValidateWithIds implements IReturn<ImagingAndTumourAssessmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingAndTumourAssessmentValidationResponse(); }
}

// @Route("/opms/form/imaging-and-tumour-assessment/validate/codes", "POST")
// @Api(Description="Form - Imaging And Tumour Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImagingAndTumourAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ImagingAndTumourAssessmentPostValidateWithCodes implements IReturn<ImagingAndTumourAssessmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: ImagingAndTumourAssessment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImagingAndTumourAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImagingAndTumourAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImagingAndTumourAssessmentValidationResponse(); }
}

// @Route("/opms/form/laboratory-assessments/single/id/{Id}", "GET")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsGetSingleById implements IReturn<LaboratoryAssessmentsSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LaboratoryAssessmentsSingleResponse(); }
}

// @Route("/opms/form/laboratory-assessments/single/ids", "GET")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsGetSingleByIds implements IReturn<LaboratoryAssessmentsSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<LaboratoryAssessmentsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LaboratoryAssessmentsSingleResponse(); }
}

// @Route("/opms/form/laboratory-assessments/single/codes", "GET")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsGetSingleByCodes implements IReturn<LaboratoryAssessmentsSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<LaboratoryAssessmentsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LaboratoryAssessmentsSingleResponse(); }
}

// @Route("/opms/form/laboratory-assessments/collection", "GET")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsGetCollection implements IReturn<LaboratoryAssessmentsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<LaboratoryAssessmentsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LaboratoryAssessmentsCollectionResponse(); }
}

// @Route("/opms/form/laboratory-assessments/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsGetCollectionByIds implements IReturn<LaboratoryAssessmentsCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LaboratoryAssessmentsCollectionResponse(); }
}

// @Route("/opms/form/laboratory-assessments/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsCollectionResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsGetCollectionByCodes implements IReturn<LaboratoryAssessmentsCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LaboratoryAssessmentsCollectionResponse(); }
}

// @Route("/opms/form/laboratory-assessments/save", "POST")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsPostSave implements IReturn<LaboratoryAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    public constructor(init?: Partial<LaboratoryAssessmentsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LaboratoryAssessmentsSingleResponse(); }
}

// @Route("/opms/form/laboratory-assessments/save/ids", "POST")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsPostSaveWithIds implements IReturn<LaboratoryAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<LaboratoryAssessmentsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LaboratoryAssessmentsSingleResponse(); }
}

// @Route("/opms/form/laboratory-assessments/save/codes", "POST")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsSingleResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsPostSaveWithCodes implements IReturn<LaboratoryAssessmentsSingleResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<LaboratoryAssessmentsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LaboratoryAssessmentsSingleResponse(); }
}

// @Route("/opms/form/laboratory-assessments/delete", "DELETE")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsDelete implements IReturn<LaboratoryAssessmentsDeleteResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    public constructor(init?: Partial<LaboratoryAssessmentsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new LaboratoryAssessmentsDeleteResponse(); }
}

// @Route("/opms/form/laboratory-assessments/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsDeleteWithId implements IReturn<LaboratoryAssessmentsDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new LaboratoryAssessmentsDeleteResponse(); }
}

// @Route("/opms/form/laboratory-assessments/delete/ids", "DELETE")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsDeleteWithIds implements IReturn<LaboratoryAssessmentsDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new LaboratoryAssessmentsDeleteResponse(); }
}

// @Route("/opms/form/laboratory-assessments/delete/codes", "DELETE")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsDeleteResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsDeleteByCodes implements IReturn<LaboratoryAssessmentsDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new LaboratoryAssessmentsDeleteResponse(); }
}

// @Route("/opms/form/laboratory-assessments/validate", "POST")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsPostValidate implements IReturn<LaboratoryAssessmentsValidationResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    public constructor(init?: Partial<LaboratoryAssessmentsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LaboratoryAssessmentsValidationResponse(); }
}

// @Route("/opms/form/laboratory-assessments/validate/ids", "POST")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsPostValidateWithIds implements IReturn<LaboratoryAssessmentsValidationResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LaboratoryAssessmentsValidationResponse(); }
}

// @Route("/opms/form/laboratory-assessments/validate/codes", "POST")
// @Api(Description="Form - Laboratory Assessments")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.LaboratoryAssessmentsValidationResponse)", StatusCode=500)
// @DataContract
export class LaboratoryAssessmentsPostValidateWithCodes implements IReturn<LaboratoryAssessmentsValidationResponse>
{
    // @DataMember(Order=1)
    public form: LaboratoryAssessments;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<LaboratoryAssessmentsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LaboratoryAssessmentsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LaboratoryAssessmentsValidationResponse(); }
}

// @Route("/opms/form/medical-condition/single/id/{Id}", "GET")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionGetSingleById implements IReturn<MedicalConditionSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalConditionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalConditionSingleResponse(); }
}

// @Route("/opms/form/medical-condition/single/ids", "GET")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionGetSingleByIds implements IReturn<MedicalConditionSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalConditionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalConditionSingleResponse(); }
}

// @Route("/opms/form/medical-condition/single/codes", "GET")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionGetSingleByCodes implements IReturn<MedicalConditionSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalConditionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalConditionSingleResponse(); }
}

// @Route("/opms/form/medical-condition/collection", "GET")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionGetCollection implements IReturn<MedicalConditionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicalConditionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalConditionCollectionResponse(); }
}

// @Route("/opms/form/medical-condition/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionGetCollectionByIds implements IReturn<MedicalConditionCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalConditionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalConditionCollectionResponse(); }
}

// @Route("/opms/form/medical-condition/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionGetCollectionByCodes implements IReturn<MedicalConditionCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalConditionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalConditionCollectionResponse(); }
}

// @Route("/opms/form/medical-condition/save", "POST")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionPostSave implements IReturn<MedicalConditionSingleResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    public constructor(init?: Partial<MedicalConditionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalConditionSingleResponse(); }
}

// @Route("/opms/form/medical-condition/save/ids", "POST")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionPostSaveWithIds implements IReturn<MedicalConditionSingleResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalConditionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalConditionSingleResponse(); }
}

// @Route("/opms/form/medical-condition/save/codes", "POST")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionPostSaveWithCodes implements IReturn<MedicalConditionSingleResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalConditionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalConditionSingleResponse(); }
}

// @Route("/opms/form/medical-condition/delete", "DELETE")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionDelete implements IReturn<MedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    public constructor(init?: Partial<MedicalConditionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/medical-condition/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionDeleteWithId implements IReturn<MedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalConditionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/medical-condition/delete/ids", "DELETE")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionDeleteWithIds implements IReturn<MedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalConditionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/medical-condition/delete/codes", "DELETE")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionDeleteByCodes implements IReturn<MedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalConditionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/medical-condition/validate", "POST")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionPostValidate implements IReturn<MedicalConditionValidationResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    public constructor(init?: Partial<MedicalConditionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalConditionValidationResponse(); }
}

// @Route("/opms/form/medical-condition/validate/ids", "POST")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionPostValidateWithIds implements IReturn<MedicalConditionValidationResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalConditionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalConditionValidationResponse(); }
}

// @Route("/opms/form/medical-condition/validate/codes", "POST")
// @Api(Description="Form - Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalConditionValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalConditionPostValidateWithCodes implements IReturn<MedicalConditionValidationResponse>
{
    // @DataMember(Order=1)
    public form: MedicalCondition;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalConditionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalConditionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalConditionValidationResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/single/id/{Id}", "GET")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsGetSingleById implements IReturn<MedicalHistoryAndConMedsSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndConMedsSingleResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/single/ids", "GET")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsGetSingleByIds implements IReturn<MedicalHistoryAndConMedsSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryAndConMedsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndConMedsSingleResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/single/codes", "GET")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsGetSingleByCodes implements IReturn<MedicalHistoryAndConMedsSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryAndConMedsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndConMedsSingleResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/collection", "GET")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsGetCollection implements IReturn<MedicalHistoryAndConMedsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicalHistoryAndConMedsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndConMedsCollectionResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsGetCollectionByIds implements IReturn<MedicalHistoryAndConMedsCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndConMedsCollectionResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsGetCollectionByCodes implements IReturn<MedicalHistoryAndConMedsCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryAndConMedsCollectionResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/save", "POST")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsPostSave implements IReturn<MedicalHistoryAndConMedsSingleResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    public constructor(init?: Partial<MedicalHistoryAndConMedsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndConMedsSingleResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/save/ids", "POST")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsPostSaveWithIds implements IReturn<MedicalHistoryAndConMedsSingleResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryAndConMedsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndConMedsSingleResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/save/codes", "POST")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsSingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsPostSaveWithCodes implements IReturn<MedicalHistoryAndConMedsSingleResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryAndConMedsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndConMedsSingleResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/delete", "DELETE")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsDelete implements IReturn<MedicalHistoryAndConMedsDeleteResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    public constructor(init?: Partial<MedicalHistoryAndConMedsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryAndConMedsDeleteResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsDeleteWithId implements IReturn<MedicalHistoryAndConMedsDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryAndConMedsDeleteResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/delete/ids", "DELETE")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsDeleteWithIds implements IReturn<MedicalHistoryAndConMedsDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryAndConMedsDeleteResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/delete/codes", "DELETE")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsDeleteByCodes implements IReturn<MedicalHistoryAndConMedsDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryAndConMedsDeleteResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/validate", "POST")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsPostValidate implements IReturn<MedicalHistoryAndConMedsValidationResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    public constructor(init?: Partial<MedicalHistoryAndConMedsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndConMedsValidationResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/validate/ids", "POST")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsPostValidateWithIds implements IReturn<MedicalHistoryAndConMedsValidationResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndConMedsValidationResponse(); }
}

// @Route("/opms/form/medical-history-and-con-meds/validate/codes", "POST")
// @Api(Description="Form - Medical History And Con Meds")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryAndConMedsValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryAndConMedsPostValidateWithCodes implements IReturn<MedicalHistoryAndConMedsValidationResponse>
{
    // @DataMember(Order=1)
    public form: MedicalHistoryAndConMeds;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryAndConMedsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryAndConMedsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryAndConMedsValidationResponse(); }
}

// @Route("/opms/form/non-target-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionGetSingleById implements IReturn<NonTargetLesionSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonTargetLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonTargetLesionSingleResponse(); }
}

// @Route("/opms/form/non-target-lesion/single/ids", "GET")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionGetSingleByIds implements IReturn<NonTargetLesionSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<NonTargetLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonTargetLesionSingleResponse(); }
}

// @Route("/opms/form/non-target-lesion/single/codes", "GET")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionGetSingleByCodes implements IReturn<NonTargetLesionSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<NonTargetLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonTargetLesionSingleResponse(); }
}

// @Route("/opms/form/non-target-lesion/collection", "GET")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionGetCollection implements IReturn<NonTargetLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<NonTargetLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonTargetLesionCollectionResponse(); }
}

// @Route("/opms/form/non-target-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionGetCollectionByIds implements IReturn<NonTargetLesionCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<NonTargetLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonTargetLesionCollectionResponse(); }
}

// @Route("/opms/form/non-target-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionGetCollectionByCodes implements IReturn<NonTargetLesionCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<NonTargetLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NonTargetLesionCollectionResponse(); }
}

// @Route("/opms/form/non-target-lesion/save", "POST")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionPostSave implements IReturn<NonTargetLesionSingleResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    public constructor(init?: Partial<NonTargetLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonTargetLesionSingleResponse(); }
}

// @Route("/opms/form/non-target-lesion/save/ids", "POST")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionPostSaveWithIds implements IReturn<NonTargetLesionSingleResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<NonTargetLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonTargetLesionSingleResponse(); }
}

// @Route("/opms/form/non-target-lesion/save/codes", "POST")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionPostSaveWithCodes implements IReturn<NonTargetLesionSingleResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<NonTargetLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonTargetLesionSingleResponse(); }
}

// @Route("/opms/form/non-target-lesion/delete", "DELETE")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionDelete implements IReturn<NonTargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    public constructor(init?: Partial<NonTargetLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new NonTargetLesionDeleteResponse(); }
}

// @Route("/opms/form/non-target-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionDeleteWithId implements IReturn<NonTargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<NonTargetLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new NonTargetLesionDeleteResponse(); }
}

// @Route("/opms/form/non-target-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionDeleteWithIds implements IReturn<NonTargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<NonTargetLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new NonTargetLesionDeleteResponse(); }
}

// @Route("/opms/form/non-target-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionDeleteByCodes implements IReturn<NonTargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<NonTargetLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new NonTargetLesionDeleteResponse(); }
}

// @Route("/opms/form/non-target-lesion/validate", "POST")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionPostValidate implements IReturn<NonTargetLesionValidationResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    public constructor(init?: Partial<NonTargetLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonTargetLesionValidationResponse(); }
}

// @Route("/opms/form/non-target-lesion/validate/ids", "POST")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionPostValidateWithIds implements IReturn<NonTargetLesionValidationResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<NonTargetLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonTargetLesionValidationResponse(); }
}

// @Route("/opms/form/non-target-lesion/validate/codes", "POST")
// @Api(Description="Form - Non Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.NonTargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class NonTargetLesionPostValidateWithCodes implements IReturn<NonTargetLesionValidationResponse>
{
    // @DataMember(Order=1)
    public form: NonTargetLesion;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<NonTargetLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NonTargetLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NonTargetLesionValidationResponse(); }
}

// @Route("/opms/form/other-medical-condition/single/id/{Id}", "GET")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionGetSingleById implements IReturn<OtherMedicalConditionSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherMedicalConditionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherMedicalConditionSingleResponse(); }
}

// @Route("/opms/form/other-medical-condition/single/ids", "GET")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionGetSingleByIds implements IReturn<OtherMedicalConditionSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OtherMedicalConditionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherMedicalConditionSingleResponse(); }
}

// @Route("/opms/form/other-medical-condition/single/codes", "GET")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionGetSingleByCodes implements IReturn<OtherMedicalConditionSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OtherMedicalConditionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherMedicalConditionSingleResponse(); }
}

// @Route("/opms/form/other-medical-condition/collection", "GET")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionGetCollection implements IReturn<OtherMedicalConditionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<OtherMedicalConditionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherMedicalConditionCollectionResponse(); }
}

// @Route("/opms/form/other-medical-condition/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionGetCollectionByIds implements IReturn<OtherMedicalConditionCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherMedicalConditionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherMedicalConditionCollectionResponse(); }
}

// @Route("/opms/form/other-medical-condition/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionGetCollectionByCodes implements IReturn<OtherMedicalConditionCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherMedicalConditionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherMedicalConditionCollectionResponse(); }
}

// @Route("/opms/form/other-medical-condition/save", "POST")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionPostSave implements IReturn<OtherMedicalConditionSingleResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    public constructor(init?: Partial<OtherMedicalConditionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherMedicalConditionSingleResponse(); }
}

// @Route("/opms/form/other-medical-condition/save/ids", "POST")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionPostSaveWithIds implements IReturn<OtherMedicalConditionSingleResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OtherMedicalConditionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherMedicalConditionSingleResponse(); }
}

// @Route("/opms/form/other-medical-condition/save/codes", "POST")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionPostSaveWithCodes implements IReturn<OtherMedicalConditionSingleResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OtherMedicalConditionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherMedicalConditionSingleResponse(); }
}

// @Route("/opms/form/other-medical-condition/delete", "DELETE")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionDelete implements IReturn<OtherMedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    public constructor(init?: Partial<OtherMedicalConditionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherMedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/other-medical-condition/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionDeleteWithId implements IReturn<OtherMedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherMedicalConditionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherMedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/other-medical-condition/delete/ids", "DELETE")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionDeleteWithIds implements IReturn<OtherMedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OtherMedicalConditionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherMedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/other-medical-condition/delete/codes", "DELETE")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionDeleteByCodes implements IReturn<OtherMedicalConditionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OtherMedicalConditionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherMedicalConditionDeleteResponse(); }
}

// @Route("/opms/form/other-medical-condition/validate", "POST")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionPostValidate implements IReturn<OtherMedicalConditionValidationResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    public constructor(init?: Partial<OtherMedicalConditionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherMedicalConditionValidationResponse(); }
}

// @Route("/opms/form/other-medical-condition/validate/ids", "POST")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionPostValidateWithIds implements IReturn<OtherMedicalConditionValidationResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherMedicalConditionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherMedicalConditionValidationResponse(); }
}

// @Route("/opms/form/other-medical-condition/validate/codes", "POST")
// @Api(Description="Form - Other Medical Condition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherMedicalConditionValidationResponse)", StatusCode=500)
// @DataContract
export class OtherMedicalConditionPostValidateWithCodes implements IReturn<OtherMedicalConditionValidationResponse>
{
    // @DataMember(Order=1)
    public form: OtherMedicalCondition;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherMedicalConditionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherMedicalConditionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherMedicalConditionValidationResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentGetSingleById implements IReturn<PathologyAndPriorTreatmentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyAndPriorTreatmentSingleResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/single/ids", "GET")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentGetSingleByIds implements IReturn<PathologyAndPriorTreatmentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PathologyAndPriorTreatmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyAndPriorTreatmentSingleResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/single/codes", "GET")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentGetSingleByCodes implements IReturn<PathologyAndPriorTreatmentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PathologyAndPriorTreatmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyAndPriorTreatmentSingleResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/collection", "GET")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentGetCollection implements IReturn<PathologyAndPriorTreatmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PathologyAndPriorTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyAndPriorTreatmentCollectionResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentGetCollectionByIds implements IReturn<PathologyAndPriorTreatmentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyAndPriorTreatmentCollectionResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentGetCollectionByCodes implements IReturn<PathologyAndPriorTreatmentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PathologyAndPriorTreatmentCollectionResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/save", "POST")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentPostSave implements IReturn<PathologyAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    public constructor(init?: Partial<PathologyAndPriorTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyAndPriorTreatmentSingleResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/save/ids", "POST")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentPostSaveWithIds implements IReturn<PathologyAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PathologyAndPriorTreatmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyAndPriorTreatmentSingleResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/save/codes", "POST")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentPostSaveWithCodes implements IReturn<PathologyAndPriorTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PathologyAndPriorTreatmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyAndPriorTreatmentSingleResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/delete", "DELETE")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentDelete implements IReturn<PathologyAndPriorTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    public constructor(init?: Partial<PathologyAndPriorTreatmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyAndPriorTreatmentDeleteResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentDeleteWithId implements IReturn<PathologyAndPriorTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyAndPriorTreatmentDeleteResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/delete/ids", "DELETE")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentDeleteWithIds implements IReturn<PathologyAndPriorTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyAndPriorTreatmentDeleteResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/delete/codes", "DELETE")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentDeleteByCodes implements IReturn<PathologyAndPriorTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PathologyAndPriorTreatmentDeleteResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/validate", "POST")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentPostValidate implements IReturn<PathologyAndPriorTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    public constructor(init?: Partial<PathologyAndPriorTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyAndPriorTreatmentValidationResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/validate/ids", "POST")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentPostValidateWithIds implements IReturn<PathologyAndPriorTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyAndPriorTreatmentValidationResponse(); }
}

// @Route("/opms/form/pathology-and-prior-treatment/validate/codes", "POST")
// @Api(Description="Form - Pathology And Prior Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PathologyAndPriorTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class PathologyAndPriorTreatmentPostValidateWithCodes implements IReturn<PathologyAndPriorTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: PathologyAndPriorTreatment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PathologyAndPriorTreatmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PathologyAndPriorTreatmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PathologyAndPriorTreatmentValidationResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/single/id/{Id}", "GET")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultGetSingleById implements IReturn<PdlAndTilResultSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PdlAndTilResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/single/ids", "GET")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultGetSingleByIds implements IReturn<PdlAndTilResultSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PdlAndTilResultGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/single/codes", "GET")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultGetSingleByCodes implements IReturn<PdlAndTilResultSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PdlAndTilResultGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/collection", "GET")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultGetCollection implements IReturn<PdlAndTilResultCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PdlAndTilResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultCollectionResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultGetCollectionByIds implements IReturn<PdlAndTilResultCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultCollectionResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultCollectionResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultGetCollectionByCodes implements IReturn<PdlAndTilResultCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultCollectionResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/save", "POST")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultPostSave implements IReturn<PdlAndTilResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    public constructor(init?: Partial<PdlAndTilResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/save/ids", "POST")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultPostSaveWithIds implements IReturn<PdlAndTilResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PdlAndTilResultPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/save/codes", "POST")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultPostSaveWithCodes implements IReturn<PdlAndTilResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PdlAndTilResultPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/delete", "DELETE")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultDelete implements IReturn<PdlAndTilResultDeleteResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    public constructor(init?: Partial<PdlAndTilResultDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultDeleteWithId implements IReturn<PdlAndTilResultDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PdlAndTilResultDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/delete/ids", "DELETE")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultDeleteWithIds implements IReturn<PdlAndTilResultDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/delete/codes", "DELETE")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultDeleteByCodes implements IReturn<PdlAndTilResultDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/validate", "POST")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultPostValidate implements IReturn<PdlAndTilResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    public constructor(init?: Partial<PdlAndTilResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultValidationResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/validate/ids", "POST")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultPostValidateWithIds implements IReturn<PdlAndTilResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultValidationResponse(); }
}

// @Route("/opms/form/pdl-and-til-result/validate/codes", "POST")
// @Api(Description="Form - Pdl And Til Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultValidationResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultPostValidateWithCodes implements IReturn<PdlAndTilResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResult;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultValidationResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/single/id/{Id}", "GET")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormGetSingleById implements IReturn<PdlAndTilResultFormSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PdlAndTilResultFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultFormSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/single/ids", "GET")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormGetSingleByIds implements IReturn<PdlAndTilResultFormSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PdlAndTilResultFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultFormSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/single/codes", "GET")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormGetSingleByCodes implements IReturn<PdlAndTilResultFormSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PdlAndTilResultFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultFormSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/collection", "GET")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormGetCollection implements IReturn<PdlAndTilResultFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PdlAndTilResultFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultFormCollectionResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormGetCollectionByIds implements IReturn<PdlAndTilResultFormCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultFormCollectionResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormGetCollectionByCodes implements IReturn<PdlAndTilResultFormCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PdlAndTilResultFormCollectionResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/save", "POST")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormPostSave implements IReturn<PdlAndTilResultFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    public constructor(init?: Partial<PdlAndTilResultFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultFormSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/save/ids", "POST")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormPostSaveWithIds implements IReturn<PdlAndTilResultFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PdlAndTilResultFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultFormSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/save/codes", "POST")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormSingleResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormPostSaveWithCodes implements IReturn<PdlAndTilResultFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PdlAndTilResultFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultFormSingleResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/delete", "DELETE")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormDelete implements IReturn<PdlAndTilResultFormDeleteResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    public constructor(init?: Partial<PdlAndTilResultFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultFormDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormDeleteWithId implements IReturn<PdlAndTilResultFormDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PdlAndTilResultFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultFormDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/delete/ids", "DELETE")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormDeleteWithIds implements IReturn<PdlAndTilResultFormDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultFormDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/delete/codes", "DELETE")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormDeleteByCodes implements IReturn<PdlAndTilResultFormDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PdlAndTilResultFormDeleteResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/validate", "POST")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormPostValidate implements IReturn<PdlAndTilResultFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    public constructor(init?: Partial<PdlAndTilResultFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultFormValidationResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/validate/ids", "POST")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormPostValidateWithIds implements IReturn<PdlAndTilResultFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultFormValidationResponse(); }
}

// @Route("/opms/form/pdl-and-til-result-form/validate/codes", "POST")
// @Api(Description="Form - Pdl And Til Result Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PdlAndTilResultFormValidationResponse)", StatusCode=500)
// @DataContract
export class PdlAndTilResultFormPostValidateWithCodes implements IReturn<PdlAndTilResultFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: PdlAndTilResultForm;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PdlAndTilResultFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PdlAndTilResultFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PdlAndTilResultFormValidationResponse(); }
}

// @Route("/opms/form/previous-treatment/single/id/{Id}", "GET")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentGetSingleById implements IReturn<PreviousTreatmentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreviousTreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousTreatmentSingleResponse(); }
}

// @Route("/opms/form/previous-treatment/single/ids", "GET")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentGetSingleByIds implements IReturn<PreviousTreatmentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreviousTreatmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousTreatmentSingleResponse(); }
}

// @Route("/opms/form/previous-treatment/single/codes", "GET")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentGetSingleByCodes implements IReturn<PreviousTreatmentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreviousTreatmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousTreatmentSingleResponse(); }
}

// @Route("/opms/form/previous-treatment/collection", "GET")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentGetCollection implements IReturn<PreviousTreatmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PreviousTreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousTreatmentCollectionResponse(); }
}

// @Route("/opms/form/previous-treatment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentGetCollectionByIds implements IReturn<PreviousTreatmentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousTreatmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousTreatmentCollectionResponse(); }
}

// @Route("/opms/form/previous-treatment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentGetCollectionByCodes implements IReturn<PreviousTreatmentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousTreatmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreviousTreatmentCollectionResponse(); }
}

// @Route("/opms/form/previous-treatment/save", "POST")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentPostSave implements IReturn<PreviousTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    public constructor(init?: Partial<PreviousTreatmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousTreatmentSingleResponse(); }
}

// @Route("/opms/form/previous-treatment/save/ids", "POST")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentPostSaveWithIds implements IReturn<PreviousTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreviousTreatmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousTreatmentSingleResponse(); }
}

// @Route("/opms/form/previous-treatment/save/codes", "POST")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentPostSaveWithCodes implements IReturn<PreviousTreatmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreviousTreatmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousTreatmentSingleResponse(); }
}

// @Route("/opms/form/previous-treatment/delete", "DELETE")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentDelete implements IReturn<PreviousTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    public constructor(init?: Partial<PreviousTreatmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousTreatmentDeleteResponse(); }
}

// @Route("/opms/form/previous-treatment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentDeleteWithId implements IReturn<PreviousTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreviousTreatmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousTreatmentDeleteResponse(); }
}

// @Route("/opms/form/previous-treatment/delete/ids", "DELETE")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentDeleteWithIds implements IReturn<PreviousTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreviousTreatmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousTreatmentDeleteResponse(); }
}

// @Route("/opms/form/previous-treatment/delete/codes", "DELETE")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentDeleteResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentDeleteByCodes implements IReturn<PreviousTreatmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreviousTreatmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreviousTreatmentDeleteResponse(); }
}

// @Route("/opms/form/previous-treatment/validate", "POST")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentPostValidate implements IReturn<PreviousTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    public constructor(init?: Partial<PreviousTreatmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousTreatmentValidationResponse(); }
}

// @Route("/opms/form/previous-treatment/validate/ids", "POST")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentPostValidateWithIds implements IReturn<PreviousTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousTreatmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousTreatmentValidationResponse(); }
}

// @Route("/opms/form/previous-treatment/validate/codes", "POST")
// @Api(Description="Form - Previous Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreviousTreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class PreviousTreatmentPostValidateWithCodes implements IReturn<PreviousTreatmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: PreviousTreatment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreviousTreatmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreviousTreatmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreviousTreatmentValidationResponse(); }
}

// @Route("/opms/form/target-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetLesionGetSingleById implements IReturn<TargetLesionSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TargetLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetLesionSingleResponse(); }
}

// @Route("/opms/form/target-lesion/single/ids", "GET")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetLesionGetSingleByIds implements IReturn<TargetLesionSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TargetLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetLesionSingleResponse(); }
}

// @Route("/opms/form/target-lesion/single/codes", "GET")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetLesionGetSingleByCodes implements IReturn<TargetLesionSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TargetLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetLesionSingleResponse(); }
}

// @Route("/opms/form/target-lesion/collection", "GET")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class TargetLesionGetCollection implements IReturn<TargetLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TargetLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetLesionCollectionResponse(); }
}

// @Route("/opms/form/target-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class TargetLesionGetCollectionByIds implements IReturn<TargetLesionCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TargetLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetLesionCollectionResponse(); }
}

// @Route("/opms/form/target-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class TargetLesionGetCollectionByCodes implements IReturn<TargetLesionCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TargetLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TargetLesionCollectionResponse(); }
}

// @Route("/opms/form/target-lesion/save", "POST")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetLesionPostSave implements IReturn<TargetLesionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    public constructor(init?: Partial<TargetLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetLesionSingleResponse(); }
}

// @Route("/opms/form/target-lesion/save/ids", "POST")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetLesionPostSaveWithIds implements IReturn<TargetLesionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TargetLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetLesionSingleResponse(); }
}

// @Route("/opms/form/target-lesion/save/codes", "POST")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionSingleResponse)", StatusCode=500)
// @DataContract
export class TargetLesionPostSaveWithCodes implements IReturn<TargetLesionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TargetLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetLesionSingleResponse(); }
}

// @Route("/opms/form/target-lesion/delete", "DELETE")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class TargetLesionDelete implements IReturn<TargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    public constructor(init?: Partial<TargetLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TargetLesionDeleteResponse(); }
}

// @Route("/opms/form/target-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class TargetLesionDeleteWithId implements IReturn<TargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TargetLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TargetLesionDeleteResponse(); }
}

// @Route("/opms/form/target-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class TargetLesionDeleteWithIds implements IReturn<TargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TargetLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TargetLesionDeleteResponse(); }
}

// @Route("/opms/form/target-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class TargetLesionDeleteByCodes implements IReturn<TargetLesionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TargetLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TargetLesionDeleteResponse(); }
}

// @Route("/opms/form/target-lesion/validate", "POST")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class TargetLesionPostValidate implements IReturn<TargetLesionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    public constructor(init?: Partial<TargetLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetLesionValidationResponse(); }
}

// @Route("/opms/form/target-lesion/validate/ids", "POST")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class TargetLesionPostValidateWithIds implements IReturn<TargetLesionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TargetLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetLesionValidationResponse(); }
}

// @Route("/opms/form/target-lesion/validate/codes", "POST")
// @Api(Description="Form - Target Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TargetLesionValidationResponse)", StatusCode=500)
// @DataContract
export class TargetLesionPostValidateWithCodes implements IReturn<TargetLesionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TargetLesion;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TargetLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TargetLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TargetLesionValidationResponse(); }
}

// @Route("/opms/form/tumour-block/single/id/{Id}", "GET")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockGetSingleById implements IReturn<TumourBlockSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourBlockGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSingleResponse(); }
}

// @Route("/opms/form/tumour-block/single/ids", "GET")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockGetSingleByIds implements IReturn<TumourBlockSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TumourBlockGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSingleResponse(); }
}

// @Route("/opms/form/tumour-block/single/codes", "GET")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockGetSingleByCodes implements IReturn<TumourBlockSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TumourBlockGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSingleResponse(); }
}

// @Route("/opms/form/tumour-block/collection", "GET")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockGetCollection implements IReturn<TumourBlockCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TumourBlockGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockCollectionResponse(); }
}

// @Route("/opms/form/tumour-block/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockGetCollectionByIds implements IReturn<TumourBlockCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockCollectionResponse(); }
}

// @Route("/opms/form/tumour-block/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockGetCollectionByCodes implements IReturn<TumourBlockCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockCollectionResponse(); }
}

// @Route("/opms/form/tumour-block/save", "POST")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockPostSave implements IReturn<TumourBlockSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    public constructor(init?: Partial<TumourBlockPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSingleResponse(); }
}

// @Route("/opms/form/tumour-block/save/ids", "POST")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockPostSaveWithIds implements IReturn<TumourBlockSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TumourBlockPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSingleResponse(); }
}

// @Route("/opms/form/tumour-block/save/codes", "POST")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockPostSaveWithCodes implements IReturn<TumourBlockSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TumourBlockPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSingleResponse(); }
}

// @Route("/opms/form/tumour-block/delete", "DELETE")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockDelete implements IReturn<TumourBlockDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    public constructor(init?: Partial<TumourBlockDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockDeleteResponse(); }
}

// @Route("/opms/form/tumour-block/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockDeleteWithId implements IReturn<TumourBlockDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourBlockDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockDeleteResponse(); }
}

// @Route("/opms/form/tumour-block/delete/ids", "DELETE")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockDeleteWithIds implements IReturn<TumourBlockDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TumourBlockDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockDeleteResponse(); }
}

// @Route("/opms/form/tumour-block/delete/codes", "DELETE")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockDeleteByCodes implements IReturn<TumourBlockDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TumourBlockDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockDeleteResponse(); }
}

// @Route("/opms/form/tumour-block/validate", "POST")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockPostValidate implements IReturn<TumourBlockValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    public constructor(init?: Partial<TumourBlockPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockValidationResponse(); }
}

// @Route("/opms/form/tumour-block/validate/ids", "POST")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockPostValidateWithIds implements IReturn<TumourBlockValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockValidationResponse(); }
}

// @Route("/opms/form/tumour-block/validate/codes", "POST")
// @Api(Description="Form - Tumour Block")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockPostValidateWithCodes implements IReturn<TumourBlockValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlock;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockValidationResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/single/id/{Id}", "GET")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentGetSingleById implements IReturn<TumourBlockShipmentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourBlockShipmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockShipmentSingleResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/single/ids", "GET")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentGetSingleByIds implements IReturn<TumourBlockShipmentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TumourBlockShipmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockShipmentSingleResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/single/codes", "GET")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentGetSingleByCodes implements IReturn<TumourBlockShipmentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TumourBlockShipmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockShipmentSingleResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/collection", "GET")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentGetCollection implements IReturn<TumourBlockShipmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TumourBlockShipmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockShipmentCollectionResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentGetCollectionByIds implements IReturn<TumourBlockShipmentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockShipmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockShipmentCollectionResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentGetCollectionByCodes implements IReturn<TumourBlockShipmentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockShipmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockShipmentCollectionResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/save", "POST")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentPostSave implements IReturn<TumourBlockShipmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    public constructor(init?: Partial<TumourBlockShipmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockShipmentSingleResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/save/ids", "POST")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentPostSaveWithIds implements IReturn<TumourBlockShipmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TumourBlockShipmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockShipmentSingleResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/save/codes", "POST")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentPostSaveWithCodes implements IReturn<TumourBlockShipmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TumourBlockShipmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockShipmentSingleResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/delete", "DELETE")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentDelete implements IReturn<TumourBlockShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    public constructor(init?: Partial<TumourBlockShipmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockShipmentDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentDeleteWithId implements IReturn<TumourBlockShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourBlockShipmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockShipmentDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/delete/ids", "DELETE")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentDeleteWithIds implements IReturn<TumourBlockShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TumourBlockShipmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockShipmentDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/delete/codes", "DELETE")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentDeleteByCodes implements IReturn<TumourBlockShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TumourBlockShipmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockShipmentDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/validate", "POST")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentPostValidate implements IReturn<TumourBlockShipmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    public constructor(init?: Partial<TumourBlockShipmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockShipmentValidationResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/validate/ids", "POST")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentPostValidateWithIds implements IReturn<TumourBlockShipmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockShipmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockShipmentValidationResponse(); }
}

// @Route("/opms/form/tumour-block-shipment/validate/codes", "POST")
// @Api(Description="Form - Tumour Block Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockShipmentValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockShipmentPostValidateWithCodes implements IReturn<TumourBlockShipmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockShipment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockShipmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockShipmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockShipmentValidationResponse(); }
}

// @Route("/opms/form/tumour-block-submission/single/id/{Id}", "GET")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionGetSingleById implements IReturn<TumourBlockSubmissionSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourBlockSubmissionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSubmissionSingleResponse(); }
}

// @Route("/opms/form/tumour-block-submission/single/ids", "GET")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionGetSingleByIds implements IReturn<TumourBlockSubmissionSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TumourBlockSubmissionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSubmissionSingleResponse(); }
}

// @Route("/opms/form/tumour-block-submission/single/codes", "GET")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionGetSingleByCodes implements IReturn<TumourBlockSubmissionSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TumourBlockSubmissionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSubmissionSingleResponse(); }
}

// @Route("/opms/form/tumour-block-submission/collection", "GET")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionGetCollection implements IReturn<TumourBlockSubmissionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TumourBlockSubmissionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSubmissionCollectionResponse(); }
}

// @Route("/opms/form/tumour-block-submission/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionGetCollectionByIds implements IReturn<TumourBlockSubmissionCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockSubmissionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSubmissionCollectionResponse(); }
}

// @Route("/opms/form/tumour-block-submission/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionCollectionResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionGetCollectionByCodes implements IReturn<TumourBlockSubmissionCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockSubmissionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TumourBlockSubmissionCollectionResponse(); }
}

// @Route("/opms/form/tumour-block-submission/save", "POST")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionPostSave implements IReturn<TumourBlockSubmissionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    public constructor(init?: Partial<TumourBlockSubmissionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSubmissionSingleResponse(); }
}

// @Route("/opms/form/tumour-block-submission/save/ids", "POST")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionPostSaveWithIds implements IReturn<TumourBlockSubmissionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TumourBlockSubmissionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSubmissionSingleResponse(); }
}

// @Route("/opms/form/tumour-block-submission/save/codes", "POST")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionPostSaveWithCodes implements IReturn<TumourBlockSubmissionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TumourBlockSubmissionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSubmissionSingleResponse(); }
}

// @Route("/opms/form/tumour-block-submission/delete", "DELETE")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionDelete implements IReturn<TumourBlockSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    public constructor(init?: Partial<TumourBlockSubmissionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-submission/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionDeleteWithId implements IReturn<TumourBlockSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TumourBlockSubmissionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-submission/delete/ids", "DELETE")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionDeleteWithIds implements IReturn<TumourBlockSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TumourBlockSubmissionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-submission/delete/codes", "DELETE")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionDeleteByCodes implements IReturn<TumourBlockSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TumourBlockSubmissionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TumourBlockSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tumour-block-submission/validate", "POST")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionPostValidate implements IReturn<TumourBlockSubmissionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    public constructor(init?: Partial<TumourBlockSubmissionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSubmissionValidationResponse(); }
}

// @Route("/opms/form/tumour-block-submission/validate/ids", "POST")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionPostValidateWithIds implements IReturn<TumourBlockSubmissionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockSubmissionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSubmissionValidationResponse(); }
}

// @Route("/opms/form/tumour-block-submission/validate/codes", "POST")
// @Api(Description="Form - Tumour Block Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TumourBlockSubmissionValidationResponse)", StatusCode=500)
// @DataContract
export class TumourBlockSubmissionPostValidateWithCodes implements IReturn<TumourBlockSubmissionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TumourBlockSubmission;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TumourBlockSubmissionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TumourBlockSubmissionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TumourBlockSubmissionValidationResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/single/id/{Id}", "GET")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaGetSingleById implements IReturn<FailedEligibilityCriteriaSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FailedEligibilityCriteriaSingleResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/single/ids", "GET")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaGetSingleByIds implements IReturn<FailedEligibilityCriteriaSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<FailedEligibilityCriteriaGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FailedEligibilityCriteriaSingleResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/single/codes", "GET")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaGetSingleByCodes implements IReturn<FailedEligibilityCriteriaSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<FailedEligibilityCriteriaGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FailedEligibilityCriteriaSingleResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/collection", "GET")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaGetCollection implements IReturn<FailedEligibilityCriteriaCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<FailedEligibilityCriteriaGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FailedEligibilityCriteriaCollectionResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaGetCollectionByIds implements IReturn<FailedEligibilityCriteriaCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FailedEligibilityCriteriaCollectionResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaCollectionResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaGetCollectionByCodes implements IReturn<FailedEligibilityCriteriaCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FailedEligibilityCriteriaCollectionResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/save", "POST")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaPostSave implements IReturn<FailedEligibilityCriteriaSingleResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    public constructor(init?: Partial<FailedEligibilityCriteriaPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FailedEligibilityCriteriaSingleResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/save/ids", "POST")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaPostSaveWithIds implements IReturn<FailedEligibilityCriteriaSingleResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<FailedEligibilityCriteriaPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FailedEligibilityCriteriaSingleResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/save/codes", "POST")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaPostSaveWithCodes implements IReturn<FailedEligibilityCriteriaSingleResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<FailedEligibilityCriteriaPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FailedEligibilityCriteriaSingleResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/delete", "DELETE")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaDelete implements IReturn<FailedEligibilityCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    public constructor(init?: Partial<FailedEligibilityCriteriaDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FailedEligibilityCriteriaDeleteResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaDeleteWithId implements IReturn<FailedEligibilityCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FailedEligibilityCriteriaDeleteResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/delete/ids", "DELETE")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaDeleteWithIds implements IReturn<FailedEligibilityCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FailedEligibilityCriteriaDeleteResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/delete/codes", "DELETE")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaDeleteByCodes implements IReturn<FailedEligibilityCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FailedEligibilityCriteriaDeleteResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/validate", "POST")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaPostValidate implements IReturn<FailedEligibilityCriteriaValidationResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    public constructor(init?: Partial<FailedEligibilityCriteriaPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FailedEligibilityCriteriaValidationResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/validate/ids", "POST")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaPostValidateWithIds implements IReturn<FailedEligibilityCriteriaValidationResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FailedEligibilityCriteriaValidationResponse(); }
}

// @Route("/opms/form/failed-eligibility-criteria/validate/codes", "POST")
// @Api(Description="Form - Failed Eligibility Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FailedEligibilityCriteriaValidationResponse)", StatusCode=500)
// @DataContract
export class FailedEligibilityCriteriaPostValidateWithCodes implements IReturn<FailedEligibilityCriteriaValidationResponse>
{
    // @DataMember(Order=1)
    public form: FailedEligibilityCriteria;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<FailedEligibilityCriteriaPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FailedEligibilityCriteriaPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FailedEligibilityCriteriaValidationResponse(); }
}

// @Route("/opms/form/patient-status-criteria/single/id/{Id}", "GET")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaGetSingleById implements IReturn<PatientStatusCriteriaSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStatusCriteriaGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusCriteriaSingleResponse(); }
}

// @Route("/opms/form/patient-status-criteria/single/ids", "GET")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaGetSingleByIds implements IReturn<PatientStatusCriteriaSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientStatusCriteriaGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusCriteriaSingleResponse(); }
}

// @Route("/opms/form/patient-status-criteria/single/codes", "GET")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaGetSingleByCodes implements IReturn<PatientStatusCriteriaSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientStatusCriteriaGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusCriteriaSingleResponse(); }
}

// @Route("/opms/form/patient-status-criteria/collection", "GET")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaGetCollection implements IReturn<PatientStatusCriteriaCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PatientStatusCriteriaGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusCriteriaCollectionResponse(); }
}

// @Route("/opms/form/patient-status-criteria/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaGetCollectionByIds implements IReturn<PatientStatusCriteriaCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusCriteriaGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusCriteriaCollectionResponse(); }
}

// @Route("/opms/form/patient-status-criteria/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaGetCollectionByCodes implements IReturn<PatientStatusCriteriaCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusCriteriaGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusCriteriaCollectionResponse(); }
}

// @Route("/opms/form/patient-status-criteria/save", "POST")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaPostSave implements IReturn<PatientStatusCriteriaSingleResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    public constructor(init?: Partial<PatientStatusCriteriaPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusCriteriaSingleResponse(); }
}

// @Route("/opms/form/patient-status-criteria/save/ids", "POST")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaPostSaveWithIds implements IReturn<PatientStatusCriteriaSingleResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientStatusCriteriaPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusCriteriaSingleResponse(); }
}

// @Route("/opms/form/patient-status-criteria/save/codes", "POST")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaPostSaveWithCodes implements IReturn<PatientStatusCriteriaSingleResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientStatusCriteriaPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusCriteriaSingleResponse(); }
}

// @Route("/opms/form/patient-status-criteria/delete", "DELETE")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaDelete implements IReturn<PatientStatusCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    public constructor(init?: Partial<PatientStatusCriteriaDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusCriteriaDeleteResponse(); }
}

// @Route("/opms/form/patient-status-criteria/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaDeleteWithId implements IReturn<PatientStatusCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStatusCriteriaDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusCriteriaDeleteResponse(); }
}

// @Route("/opms/form/patient-status-criteria/delete/ids", "DELETE")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaDeleteWithIds implements IReturn<PatientStatusCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientStatusCriteriaDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusCriteriaDeleteResponse(); }
}

// @Route("/opms/form/patient-status-criteria/delete/codes", "DELETE")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaDeleteByCodes implements IReturn<PatientStatusCriteriaDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientStatusCriteriaDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusCriteriaDeleteResponse(); }
}

// @Route("/opms/form/patient-status-criteria/validate", "POST")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaPostValidate implements IReturn<PatientStatusCriteriaValidationResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    public constructor(init?: Partial<PatientStatusCriteriaPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusCriteriaValidationResponse(); }
}

// @Route("/opms/form/patient-status-criteria/validate/ids", "POST")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaPostValidateWithIds implements IReturn<PatientStatusCriteriaValidationResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusCriteriaPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusCriteriaValidationResponse(); }
}

// @Route("/opms/form/patient-status-criteria/validate/codes", "POST")
// @Api(Description="Form - Patient Status Criteria")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusCriteriaValidationResponse)", StatusCode=500)
// @DataContract
export class PatientStatusCriteriaPostValidateWithCodes implements IReturn<PatientStatusCriteriaValidationResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusCriteria;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusCriteriaPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusCriteriaPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusCriteriaValidationResponse(); }
}

// @Route("/opms/form/patient-status-form/single/id/{Id}", "GET")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormGetSingleById implements IReturn<PatientStatusFormSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStatusFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusFormSingleResponse(); }
}

// @Route("/opms/form/patient-status-form/single/ids", "GET")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormGetSingleByIds implements IReturn<PatientStatusFormSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientStatusFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusFormSingleResponse(); }
}

// @Route("/opms/form/patient-status-form/single/codes", "GET")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormGetSingleByCodes implements IReturn<PatientStatusFormSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientStatusFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusFormSingleResponse(); }
}

// @Route("/opms/form/patient-status-form/collection", "GET")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormGetCollection implements IReturn<PatientStatusFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PatientStatusFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusFormCollectionResponse(); }
}

// @Route("/opms/form/patient-status-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormGetCollectionByIds implements IReturn<PatientStatusFormCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusFormCollectionResponse(); }
}

// @Route("/opms/form/patient-status-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormGetCollectionByCodes implements IReturn<PatientStatusFormCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStatusFormCollectionResponse(); }
}

// @Route("/opms/form/patient-status-form/save", "POST")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormPostSave implements IReturn<PatientStatusFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    public constructor(init?: Partial<PatientStatusFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusFormSingleResponse(); }
}

// @Route("/opms/form/patient-status-form/save/ids", "POST")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormPostSaveWithIds implements IReturn<PatientStatusFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientStatusFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusFormSingleResponse(); }
}

// @Route("/opms/form/patient-status-form/save/codes", "POST")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormPostSaveWithCodes implements IReturn<PatientStatusFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientStatusFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusFormSingleResponse(); }
}

// @Route("/opms/form/patient-status-form/delete", "DELETE")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormDelete implements IReturn<PatientStatusFormDeleteResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    public constructor(init?: Partial<PatientStatusFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusFormDeleteResponse(); }
}

// @Route("/opms/form/patient-status-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormDeleteWithId implements IReturn<PatientStatusFormDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStatusFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusFormDeleteResponse(); }
}

// @Route("/opms/form/patient-status-form/delete/ids", "DELETE")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormDeleteWithIds implements IReturn<PatientStatusFormDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientStatusFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusFormDeleteResponse(); }
}

// @Route("/opms/form/patient-status-form/delete/codes", "DELETE")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormDeleteByCodes implements IReturn<PatientStatusFormDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientStatusFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientStatusFormDeleteResponse(); }
}

// @Route("/opms/form/patient-status-form/validate", "POST")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormPostValidate implements IReturn<PatientStatusFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    public constructor(init?: Partial<PatientStatusFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusFormValidationResponse(); }
}

// @Route("/opms/form/patient-status-form/validate/ids", "POST")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormPostValidateWithIds implements IReturn<PatientStatusFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusFormValidationResponse(); }
}

// @Route("/opms/form/patient-status-form/validate/codes", "POST")
// @Api(Description="Form - Patient Status Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientStatusFormValidationResponse)", StatusCode=500)
// @DataContract
export class PatientStatusFormPostValidateWithCodes implements IReturn<PatientStatusFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: PatientStatusForm;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientStatusFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStatusFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientStatusFormValidationResponse(); }
}

// @Route("/opms/form/waiver/single/id/{Id}", "GET")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverGetSingleById implements IReturn<WaiverSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<WaiverGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSingleResponse(); }
}

// @Route("/opms/form/waiver/single/ids", "GET")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverGetSingleByIds implements IReturn<WaiverSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<WaiverGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSingleResponse(); }
}

// @Route("/opms/form/waiver/single/codes", "GET")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverGetSingleByCodes implements IReturn<WaiverSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<WaiverGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverSingleResponse(); }
}

// @Route("/opms/form/waiver/collection", "GET")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverGetCollection implements IReturn<WaiverCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<WaiverGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverCollectionResponse(); }
}

// @Route("/opms/form/waiver/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverGetCollectionByIds implements IReturn<WaiverCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverCollectionResponse(); }
}

// @Route("/opms/form/waiver/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverGetCollectionByCodes implements IReturn<WaiverCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverCollectionResponse(); }
}

// @Route("/opms/form/waiver/save", "POST")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverPostSave implements IReturn<WaiverSingleResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    public constructor(init?: Partial<WaiverPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSingleResponse(); }
}

// @Route("/opms/form/waiver/save/ids", "POST")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverPostSaveWithIds implements IReturn<WaiverSingleResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<WaiverPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSingleResponse(); }
}

// @Route("/opms/form/waiver/save/codes", "POST")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverPostSaveWithCodes implements IReturn<WaiverSingleResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<WaiverPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverSingleResponse(); }
}

// @Route("/opms/form/waiver/delete", "DELETE")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverDelete implements IReturn<WaiverDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    public constructor(init?: Partial<WaiverDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverDeleteResponse(); }
}

// @Route("/opms/form/waiver/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverDeleteWithId implements IReturn<WaiverDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<WaiverDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverDeleteResponse(); }
}

// @Route("/opms/form/waiver/delete/ids", "DELETE")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverDeleteWithIds implements IReturn<WaiverDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<WaiverDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverDeleteResponse(); }
}

// @Route("/opms/form/waiver/delete/codes", "DELETE")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverDeleteByCodes implements IReturn<WaiverDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<WaiverDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverDeleteResponse(); }
}

// @Route("/opms/form/waiver/validate", "POST")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverPostValidate implements IReturn<WaiverValidationResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    public constructor(init?: Partial<WaiverPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverValidationResponse(); }
}

// @Route("/opms/form/waiver/validate/ids", "POST")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverPostValidateWithIds implements IReturn<WaiverValidationResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverValidationResponse(); }
}

// @Route("/opms/form/waiver/validate/codes", "POST")
// @Api(Description="Form - Waiver")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverPostValidateWithCodes implements IReturn<WaiverValidationResponse>
{
    // @DataMember(Order=1)
    public form: Waiver;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverValidationResponse(); }
}

// @Route("/opms/form/waiver-form/single/id/{Id}", "GET")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverFormGetSingleById implements IReturn<WaiverFormSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<WaiverFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverFormSingleResponse(); }
}

// @Route("/opms/form/waiver-form/single/ids", "GET")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverFormGetSingleByIds implements IReturn<WaiverFormSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<WaiverFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverFormSingleResponse(); }
}

// @Route("/opms/form/waiver-form/single/codes", "GET")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverFormGetSingleByCodes implements IReturn<WaiverFormSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<WaiverFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverFormSingleResponse(); }
}

// @Route("/opms/form/waiver-form/collection", "GET")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverFormGetCollection implements IReturn<WaiverFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<WaiverFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverFormCollectionResponse(); }
}

// @Route("/opms/form/waiver-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverFormGetCollectionByIds implements IReturn<WaiverFormCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverFormCollectionResponse(); }
}

// @Route("/opms/form/waiver-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormCollectionResponse)", StatusCode=500)
// @DataContract
export class WaiverFormGetCollectionByCodes implements IReturn<WaiverFormCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WaiverFormCollectionResponse(); }
}

// @Route("/opms/form/waiver-form/save", "POST")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverFormPostSave implements IReturn<WaiverFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    public constructor(init?: Partial<WaiverFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverFormSingleResponse(); }
}

// @Route("/opms/form/waiver-form/save/ids", "POST")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverFormPostSaveWithIds implements IReturn<WaiverFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<WaiverFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverFormSingleResponse(); }
}

// @Route("/opms/form/waiver-form/save/codes", "POST")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormSingleResponse)", StatusCode=500)
// @DataContract
export class WaiverFormPostSaveWithCodes implements IReturn<WaiverFormSingleResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<WaiverFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverFormSingleResponse(); }
}

// @Route("/opms/form/waiver-form/delete", "DELETE")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverFormDelete implements IReturn<WaiverFormDeleteResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    public constructor(init?: Partial<WaiverFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverFormDeleteResponse(); }
}

// @Route("/opms/form/waiver-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverFormDeleteWithId implements IReturn<WaiverFormDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<WaiverFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverFormDeleteResponse(); }
}

// @Route("/opms/form/waiver-form/delete/ids", "DELETE")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverFormDeleteWithIds implements IReturn<WaiverFormDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<WaiverFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverFormDeleteResponse(); }
}

// @Route("/opms/form/waiver-form/delete/codes", "DELETE")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormDeleteResponse)", StatusCode=500)
// @DataContract
export class WaiverFormDeleteByCodes implements IReturn<WaiverFormDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<WaiverFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new WaiverFormDeleteResponse(); }
}

// @Route("/opms/form/waiver-form/validate", "POST")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverFormPostValidate implements IReturn<WaiverFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    public constructor(init?: Partial<WaiverFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverFormValidationResponse(); }
}

// @Route("/opms/form/waiver-form/validate/ids", "POST")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverFormPostValidateWithIds implements IReturn<WaiverFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverFormValidationResponse(); }
}

// @Route("/opms/form/waiver-form/validate/codes", "POST")
// @Api(Description="Form - Waiver Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.WaiverFormValidationResponse)", StatusCode=500)
// @DataContract
export class WaiverFormPostValidateWithCodes implements IReturn<WaiverFormValidationResponse>
{
    // @DataMember(Order=1)
    public form: WaiverForm;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<WaiverFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WaiverFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WaiverFormValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/event-state/id/{Id}", "GET")
// @Api(Description="")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=500)
// @DataContract
export class EventStatesById implements IReturn<EventStateResponse>, IPatientGetEventStatesById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventStatesById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventStatesById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventStateResponse(); }
}

// @Route("/opms/event-state/study-number/{StudyNumber}", "GET")
// @Api(Description="")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=500)
// @DataContract
export class EventStatesByStudyNumber implements IReturn<EventStateResponse>, IPatientGetEventStatesByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<EventStatesByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventStatesByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventStateResponse(); }
}

// @Route("/opms/form-state/ids/{Id}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=500)
// @DataContract
export class FormStatesByIds implements IReturn<FormStateResponse>, IPatientGetFormStatesByIds
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=1)
    public eventDefinitionId?: number;

    // @DataMember(Order=1)
    public eventRepeat?: number;

    public constructor(init?: Partial<FormStatesByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormStatesByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormStateResponse(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class GetInstitutionPersonnel implements IReturn<InstitutionPersonnelResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnel'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelResponse(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetTrialPersonnelHasActiveRole implements IReturn<TrialPersonnelActiveRoleResponse>, IGet
{
    public personId: number;
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetTrialPersonnelHasActiveRole>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrialPersonnelHasActiveRole'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrialPersonnelActiveRoleResponse(); }
}

