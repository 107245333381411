/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type / create the client used to communicate to the API.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to type / create the history used within the app.
 */
import { History, createBrowserHistory } from 'history';

/*
 * Used to type / create a redux store; 
 */
import { Store, Middleware, compose, createStore, applyMiddleware } from 'redux';

/*
 * Used to connect history to store.
 */
import { createReduxHistoryContext } from 'redux-first-history';

/*
 * Used to create the redux middleware that handles running all side effect logic.
 */
import { createLogicMiddleware, Logic, LogicMiddleware } from 'redux-logic';

/*
 * Used to listen to async requests handled through redux side effects.
 */
import createReduxPromiseListener, { ReduxPromiseListener } from 'redux-promise-listener';

/*
 * Used to connect redux with redux dev tools.
 */
import { composeWithDevTools } from 'redux-devtools-extension';

/*
 * Used to help type actions created by immer-reducer
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to type theme objects for MUI.
 */
import { ThemeOptions, Theme, createMuiTheme } from '@material-ui/core';

/*
 * Used for extending base class:
 *      - ReducerRegistry
 * Used to subscribe the store to updates from a reducer registry.
 */
import {
    subscribeToReducerRegistry,
    combineLogics,
    combineReducers,
    ReducerRegistry
} from '@ngt/reducer-registry-logics';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type form metadata
 */
import * as Dtos from './api/dtos';

/*
 * Used to register the authenticatedUser reducer;
 */
import registerAuthenticatedUserReducer from './store/modules/authenticatedUser';

/*
 * Used to register the institution reducer;
 */
import registerMasterGroupReducer from './store/modules/data/masterGroup';

/*
 * Used to register the institutions reducer;
 */
import registerMasterGroupsReducer from './store/modules/data/masterGroups';

/*
 * Used to register the institution reducer;
 */
import registerCollaboratingGroupReducer from './store/modules/data/collaboratingGroup';

/*
 * Used to register the institutions reducer;
 */
import registerCollaboratingGroupsReducer from './store/modules/data/collaboratingGroups';

/*
 * Used to register the institution reducer;
 */
import registerCountryReducer from './store/modules/data/country';

/*
 * Used to register the institutions reducer;
 */
import registerCountriesReducer from './store/modules/data/countries';

/*
 * Used to register the institution reducer;
 */
import registerInstitutionReducer from './store/modules/data/institution';

/*
 * Used to register the institutions reducer;
 */
import registerInstitutionsReducer from './store/modules/data/institutions';

/*
 * Used to register the patient reducer;
 */
import registerPatientReducer from './store/modules/data/patient';

/*
 * Used to register the patients reducer;
 */
import registerPatientsReducer from './store/modules/data/patients';

/*
 * Used to register the event reducer;
 */
import registerEventReducer from './store/modules/data/event';

/*
 * Used to register the events reducer;
 */
import registerEventsReducer from './store/modules/data/events';

/*
 * Used to register the eventDefinition reducer;
 */
import registerEventDefinitionReducer from './store/modules/configuration/eventDefinition';

/*
 * Used to register the eventDefinitions reducer;
 */
import registerEventDefinitionsReducer from './store/modules/configuration/eventDefinitions';

/*
 * Used to register the formDefinition reducer;
 */
import registerFormDefinitionReducer from './store/modules/configuration/formDefinition';

/*
 * Used to register the formDefinitions reducer;
 */
import registerFormDefinitionsReducer from './store/modules/configuration/formDefinitions';

/*
 * Used to register the patientState reducer;
 */
import registerPatientStateReducer from './store/modules/configuration/patientState';

/*
 * Used to register the patientStates reducer;
 */
import registerPatientStatesReducer from './store/modules/configuration/patientStates';

/*
 * Used to register the treatment reducer;
 */
import registerTreatmentReducer from './store/modules/configuration/treatment';

/*
 * Used to register the treatments reducer;
 */
import registerTreatmentsReducer from './store/modules/configuration/treatments';

/*
 * Used to register the form reducer;
 */
import registerFormReducer, { FormReducer } from './store/modules/data/form';

/*
 * Used to register the forms reducer;
 */
import registerFormsReducer, { FormsReducer } from './store/modules/data/forms';

/*
 * Used to register the forms reducer;
 */
import registerLookupsReducer from './store/modules/utility/lookups';

/*
 * Used to register the forms reducer;
 */
import registerPatientValidationReducer from './store/modules/utility/patientValidation';

/*
 * Used to register form reducers and their actions. 
 */
import ActionReducerRegistry from './utilities/ActionRegistryReducer';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the route parameters that are used by
 * the OPMS.
 */
interface IOnlinePatientManagementRouteParameterOptions {
    /**
     * The route parameter name for Event Definition ID
     */
    eventDefinitionId: string;

    /**
     * The route parameter name for Event Definition Code
     */
    eventDefinitionCode: string;

    /**
     * The route parameter name for Form Definition ID
     */
    formDefinitionId: string;

    /**
     * The route parameter name for Form Definition Code
     */
    formDefinitionCode: string;

    /**
     * The route parameter name for Mater Group ID
     */
    masterGroupId: string;

    /**
     * The route parameter name for Master Group Code
     */
    masterGroupCode: string;

    /**
     * The route parameter name for Collaborating Group ID
     */
    collaboratingGroupId: string;

    /**
     * The route parameter name for Collaborating Group Code
     */
    collaboratingGroupCode: string;

    /**
     * The route parameter name for Country ID
     */
    countryId: string;

    /**
     * The route parameter name for Country Code
     */
    countryCode: string;

    /**
     * The route parameter name for Institution ID
     */
    institutionId: string;

    /**
     * The route parameter name for Institution Code
     */
    institutionCode: string;

    /**
     * The route parameter name for Patient ID
     */
    patientId: string;

    /**
     * The route parameter name for Patient Study Number
     */
    patientStudyNumber: string;

    /**
     * The route parameter name for Event ID
     */
    eventId: string;

    /**
     * The route parameter name for Event Repeat Number
     */
    eventRepeat: string;

    /**
     * The route parameter name for Form ID
     */
    formId: string;

    /**
     * The route parameter name for Form Repeat Number
     */
    formRepeat: string;

    /**
     * The route parameter name for Patient State ID
     */
    patientStateId: string;

    /**
     * The route parameter name for Treatment ID
     */
    treatmentId: string;
};

interface IOnlinePatientManagementColorOptions {
    primary: {
        50: string,
        100: string,
        200: string,
        300: string,
        400: string,
    };
    primaryVariant: {
        50: string,
        100: string,
        200: string,
        300: string,
        400: string,
    };
    secondary: {
        50: string,
        100: string,
        200: string,
        300: string,
        400: string,
    };
    secondaryVariant: {
        50: string,
        100: string,
        200: string,
        300: string,
        400: string,
    };
    tertiary: {
        50: string,
        100: string,
        200: string,
        300: string,
        400: string,
    };
}

interface IOnlinePatientManagementThemeOptions {
    beforeThemeCreated?: (themeOptions: ThemeOptions) => ThemeOptions;
    afterThemeCreated?: (theme: Theme) => Theme;
}

interface IOnlinePatientManagementReducerOptions {
    authenticatedUser?: boolean | null;
    masterGroup?: boolean | null;
    masterGroups?: boolean | null;
    collaboratingGroup?: boolean | null;
    collaboratingGroups?: boolean | null;
    country?: boolean | null;
    countries?: boolean | null;
    institution?: boolean | null;
    institutions?: boolean | null;
    patient?: boolean | null;
    patients?: boolean | null;
    event?: boolean | null;
    events?: boolean | null;
    form?: boolean | null;
    forms?: boolean | null;

    eventDefinition?: boolean | null;
    eventDefinitions?: boolean | null;
    formDefinition?: boolean | null;
    formDefinitions?: boolean | null;
    patientState?: boolean | null;
    patientStates?: boolean | null;
    treatment?: boolean | null;
    treatments?: boolean | null;

    patientValidation?: boolean | null;
    lookups?: boolean | null;
};

interface IOnlinePatientManagementStoreOptions {
    preloadState?: any | null;
    onMiddlewaresCombined?: (middlewares: Middleware[]) => Middleware[];
    afterStoreCreated?: (store: Store) => Store;
    initialiseReducers?: boolean | null | IOnlinePatientManagementReducerOptions;
};

interface IOnlinePatientManagementTrialOptions {
    organisationLogo?: string;
    organisationLogoMobile?: string;
    organisationName?: string;
    trialName?: string;
    protocolId?: string;
    homePageUrl?: string;
}

export interface IOnlinePatientManagementOptions {
    /**
     * The Reducer Registry to use throughout the application.
     * 
     * (Automatically created if not provided).
     */
    reducerRegistry?: ReducerRegistry | null;

    /**
     * The ServiceStack client to use throughout the application.
     *
     * (Automatically created if not provided).
     */
    serviceStackClient?: JsonServiceClient | null;

    /**
     * The History to use throughout the application.
     *
     * (Automatically created if not provided).
     */
    history?: History | null;

    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    storeOptions?: IOnlinePatientManagementStoreOptions | null;

    /**
     * The configuration options for route parameters used throughout the app.
     *
     * (Defaults used if not provided).
     */
    routeParameters?: Partial<IOnlinePatientManagementRouteParameterOptions> | null;

    /**
     * The configuration options to use when creating the theme.
     *
     * (Defaults used if not provided).
     */
    themeOptions?: Partial<IOnlinePatientManagementThemeOptions> | null;

    /**
     * The configuration options to use when creating the theme colors.
     *
     * (Defaults used if not provided).
     */
    colorOptions?: Partial<IOnlinePatientManagementColorOptions> | null;

    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    dtos: Record<string, any>;

    /**
     * The configuration options for the trial name and logo.
     *
     * (Defaults used if not provided).
     */
    trialOptions?: IOnlinePatientManagementTrialOptions | null;

    formMetadata?: Dtos.FormMetadata[] | null;

    extensions?: IOnlinePatientManagementExtension[] | null;
}

export interface IOnlinePatientManagementExtension {
    initialiseReducers?: (opms: OnlinePatientManagement) => void;
    ProviderComponent?: React.ComponentType<{ children: React.ReactNode }>;
    ContentComponent?: React.ComponentType<{ children: React.ReactNode }>;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */


/**
 * This class handles the global context used by the OPMS.
 */
export class OnlinePatientManagement {
    /**
     * The name of the trial.
     */
    public trialName: string;

    /**
     * The protocol id of the trial without the name at the end.
     */
    public protocolId: string;

    /**
     * The name of the organisation running the trial.
     */
    public organisationName: string;

    /**
     * The logo for the organisation running the trial.
     */
    public organisationLogo: string | null;

    /**
     * This is for the trial portal.
     */
    public homePageUrl: string;

    /**
     * The logo for the organisation running the trial.
     */
    public organisationLogoMobile: string | null;

    /**
     * Basic metadata about the forms available in the OPMS.
     */
    public formMetadata: Dtos.FormMetadata[];

    /**
     * The Reducer Registry used throughout the app.
     * 
     * Allows reducers and their associated logic to be registered which automatically
     * adds them to the redux store.
     */
    public reducerRegistry: ReducerRegistry;

    /**
     * The Form Reducer Registry used throughout the app.
     * 
     * Allows reducers and their associated logic and actions to be registered which automatically
     * adds them to the redux store.
     */
    public formReducerRegistry: ActionReducerRegistry<ActionCreators<typeof FormReducer>>;

    /**
     * The Forms Reducer Registry used throughout the app.
     * 
     * Allows reducers and their associated logic and actions to be registered which automatically
     * adds them to the redux store.
     */
    public formsReducerRegistry: ActionReducerRegistry<ActionCreators<typeof FormsReducer>>;

    /**
     * The ServiceStack client used throughout the app.
     * 
     * Allows for requests to be sent to the server side ServiceStack instance.
     */
    public serviceStackClient: JsonServiceClient;

    /**
     * The History used throughout the app.
     * 
     * Allows for the router and other components to control the navigation of the
     * app.
     */
    public history: History;

    /**
     * The Redux Store used throughout the app.
     * 
     * Allows for the management of a global application state.
     */
    public store: Store;

    /**
     * The Logic Middleware used within the Redux Store.
     * 
     * Allows for side effects to be run based off action types received by the
     * Redux Store.
     */
    public logicMiddleware: LogicMiddleware;

    /**
     * The Redux Promise Listener used within the Redux Store.
     * 
     * Allows for actions with resultant actions (i.e. load -> loadSuccess) to be
     * transformed into promises (async / await);
     */
    public reduxPromiseListener: ReduxPromiseListener;

    /**
     * The names of all the Route Parameters used throughout the app.
     * 
     * Allows for the naming of the Route Parameters to be changed.
     */
    public routeParameters: IOnlinePatientManagementRouteParameterOptions;

    /**
     * The colors used throughout the app.
     * 
     * Allows for global theming and styling to applied to the app.
     */
    public colors: IOnlinePatientManagementColorOptions;

    /**
     * The MUI theme used throughout the app.
     * 
     * Allows for global theming and styling to applied to the app.
     */
    public theme: Theme;

    /**
     * The DTOs used throughout the app.
     * 
     * Allows for typed communication with the server side ServiceStack instance.
     */
    public dtos: any;

    /**
     * The Extensions applied to the OPMS.
     */
    public extensions: IOnlinePatientManagementExtension[];

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IOnlinePatientManagementOptions) {
        this.initialiseExtensions(options?.extensions);
        this.initialiseTrialInformation(options?.trialOptions);
        this.initialiseFormMetadata(options?.formMetadata);
        this.initialiseReducerRegistry(options?.reducerRegistry);
        this.initialiseServiceStackClient(options?.serviceStackClient);
        this.initialiseHistory(options?.history);
        this.initialiseDtos(options?.dtos);
        this.initialiseRouteParameters(options?.routeParameters);
        this.initialiseColors(options?.colorOptions);
        this.initialiseTheme(options?.themeOptions);
        this.initialiseStore(options?.storeOptions, this.extensions);
    }

    /**
     * This method initialises the trial information.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseExtensions(extensions?: IOnlinePatientManagementExtension[] | null) {
        this.extensions = extensions ?? [];
    }

    /**
     * This method initialises the trial information.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseTrialInformation(trialOptions?: IOnlinePatientManagementTrialOptions | null) {
        this.trialName = trialOptions?.trialName ?? 'Unknown Trial';
        this.protocolId = trialOptions?.protocolId ?? 'Unknown Protocol Id';
        this.organisationName = trialOptions?.organisationName ?? 'Unknown Organisation';
        this.organisationLogo = trialOptions?.organisationLogo ?? null;
        this.organisationLogoMobile = trialOptions?.organisationLogoMobile ?? null;
        this.homePageUrl = trialOptions?.homePageUrl ?? '/';
    }

    /**
     * This method initialises the form metadata.
     * @param trialOptions Configured Trial Options.
     */
    private initialiseFormMetadata(formMetadata?: Dtos.FormMetadata[] | null) {
        this.formMetadata = formMetadata ?? [];
    }

    /**
     * This method initialises the Reducer Registry.
     * @param reducerRegistry Configured reducer registry.
     */
    private initialiseReducerRegistry(reducerRegistry?: ReducerRegistry | null) {

        if (reducerRegistry) {
            // If reducer registry has been provided, use it.

            // Check that the reducer registry is of the correct object type.
            if (reducerRegistry instanceof ReducerRegistry) {
                throw new Error("The provided Reducer Registry was not of the correct type.")
            }

            this.reducerRegistry = reducerRegistry;
        }
        else {
            // If no reducer registry was provided, create a new empty registry.
            this.reducerRegistry = new ReducerRegistry();
        }
    }

    /**
     * This method initialises the ServiceStack client.
     * @param serviceStackClient Configured ServiceStack Client.
     */
    private initialiseServiceStackClient(serviceStackClient?: JsonServiceClient | null) {

        if (serviceStackClient) {
            // If ServiceStack Client has been provided, use it.

            // Check that the ServiceStack Client is of the correct object type.
            if (serviceStackClient instanceof JsonServiceClient) {
                throw new Error("The provided ServiceStack Client was not of the correct type.")
            }

            this.serviceStackClient = serviceStackClient;
        }
        else {
            // If no ServiceStack Client was provided, create a new Servicestack Client.
            this.serviceStackClient = new JsonServiceClient();
        }
    }

    /**
     * This method initialises the History.
     * @param history Configured history.
     */
    private initialiseHistory(history?: History | null) {
        if (history) {
            // If history has been provided, use it.
            this.history = history;
        }
        else {
            // If no history was provided, create a new history.
            this.history = createBrowserHistory();
        }
    }

    /**
     * This method initialises the DTOs.
     * @param dtos Configured DTOs
     */
    private initialiseDtos(dtos?: Record<string, any> | null) {
        if (dtos) {
            // If DTOs have been provided, use it.
            this.dtos = dtos;
        }
        else {
            // If no DTOs were provided, throw errors.
            throw new Error("There was no DTOs provided.")
        }
    }

    /**
     * This method initialises the Route Parameter Names.
     * @param routeParameters Configured Route Parameter Names.
     */
    private initialiseRouteParameters(routeParameters?: Partial<IOnlinePatientManagementRouteParameterOptions> | null) {
        this.routeParameters = {
            eventDefinitionId: routeParameters?.eventDefinitionId ?? 'eventDefinitionId',
            eventDefinitionCode: routeParameters?.eventDefinitionCode ?? 'eventDefinitionCode',
            formDefinitionId: routeParameters?.formDefinitionId ?? 'formDefinitionId',
            formDefinitionCode: routeParameters?.formDefinitionCode ?? 'formDefinitionCode',
            masterGroupId: routeParameters?.masterGroupId ?? 'masterGroupId',
            masterGroupCode: routeParameters?.masterGroupCode ?? 'masterGroupCode',
            collaboratingGroupId: routeParameters?.collaboratingGroupId ?? 'collaboratingGroupId',
            collaboratingGroupCode: routeParameters?.collaboratingGroupCode ?? 'collaboratingGroupCode',
            countryId: routeParameters?.countryId ?? 'countryId',
            countryCode: routeParameters?.countryCode ?? 'countryCode',
            institutionId: routeParameters?.institutionId ?? 'institutionId',
            institutionCode: routeParameters?.institutionCode ?? 'institutionCode',
            patientId: routeParameters?.patientId ?? 'patientId',
            patientStudyNumber: routeParameters?.patientStudyNumber ?? 'patientStudyNumber',
            eventId: routeParameters?.eventId ?? 'eventId',
            eventRepeat: routeParameters?.eventRepeat ?? 'eventRepeat',
            formId: routeParameters?.formId ?? 'formId',
            formRepeat: routeParameters?.formRepeat ?? 'formRepeat',
            patientStateId: routeParameters?.patientStateId ?? 'patientStateId',
            treatmentId: routeParameters?.treatmentId ?? 'treatmentId'
        }
    }

    /**
     * This method initialises the Colors.
     * @param colorOptions Configured Color Options
     */
    private initialiseColors(colorOptions?: Partial<IOnlinePatientManagementColorOptions> | null | undefined) {

        // Create default color options
        let defaultColorOptions: IOnlinePatientManagementColorOptions = {
            primary: {
                50: '#d3eece',
                100: '#a7dd9c',
                200: '#7bcc6b',
                300: '#4fbb39',
                400: '#987ba7',
            },
            primaryVariant: {
                50: '#d8e5da',
                100: '#b1cbb5',
                200: '#8bb18f',
                300: '#64976a',
                400: '#3d7d45'
            },
            secondary: {
                50: '#e4e4e4',
                100: '#cacaca',
                200: '#afafaf',
                300: '#959595',
                400: '#7a7a7a'
            },
            secondaryVariant: {
                50: '#dddedf',
                100: '#bbbdbf',
                200: '#989b9f',
                300: '#767a7f',
                400: '#54595f'
            },
            tertiary: {
                50: '#b3b9bd',
                100: '#a7adb2',
                200: '#9aa1a7',
                300: '#8e969c',
                400: '#818a91'
            },
        };

        if (colorOptions?.primary) {
            defaultColorOptions.primary = colorOptions.primary;
        }

        if (colorOptions?.primaryVariant) {
            defaultColorOptions.primaryVariant = colorOptions.primaryVariant;
        }

        if (colorOptions?.secondary) {
            defaultColorOptions.secondary = colorOptions.secondary;
        }

        if (colorOptions?.secondaryVariant) {
            defaultColorOptions.secondaryVariant = colorOptions.secondaryVariant;
        }

        if (colorOptions?.tertiary) {
            defaultColorOptions.tertiary = colorOptions.tertiary;
        }

        // Set Colors;
        this.colors = defaultColorOptions;
    }

    /**
 * This method initialises the Theme.
 * @param themeOptions Configured Theme Options
 */
    private initialiseTheme(themeOptions?: IOnlinePatientManagementThemeOptions | null) {

        // Create default MUI Theme Options
        let muiThemeOptions: ThemeOptions = {
            typography: {
                h1: {
                    fontSize: '2rem',
                    color: 'secondary'
                },
                h2: {
                    fontSize: '1.5rem',
                    color: 'primary'
                },
                h3: {
                    fontSize: '1.25rem',
                    color: 'black'
                },
                h4: {
                    fontSize: '1.20rem'
                },
                h5: {
                    fontSize: '1.15rem'
                },
                h6: {
                    fontSize: '1.10rem'
                }
            },
            palette: {
                primary: {
                    main: this.colors.primary[400]
                },
                secondary: {
                    main: this.colors.secondary[400]
                }
            }
        };

        // Update MUI Theme Options using event function if provided.
        if (themeOptions?.beforeThemeCreated) {
            muiThemeOptions = themeOptions.beforeThemeCreated(muiThemeOptions);
        }

        // Create MUI Theme from MUI Theme Options.
        let muiTheme = createMuiTheme(muiThemeOptions);

        // Update MUI Theme using event function if provided.
        if (themeOptions?.afterThemeCreated) {
            muiTheme = themeOptions.afterThemeCreated(muiTheme);
        }

        // Set MUI Theme;
        this.theme = muiTheme;
    }

    /**
     * This method initialises the Reducers in the Reducer Registry.
     * @param reducerOptions Configured Reducer Options.
     */
    private initialiseReducers(reducerOptions?: IOnlinePatientManagementReducerOptions | boolean | null, extensions?: IOnlinePatientManagementExtension[] | null) {
        if (extensions) {
            extensions.forEach((extension) => {
                if (extension.initialiseReducers) {
                    extension.initialiseReducers(this);
                }
            });
        }

        // Check if reducer initialisation was requested to be skipped.
        if (reducerOptions === false) {
            return;
        }

        if (reducerOptions === true || reducerOptions?.authenticatedUser !== false) {
            // Register Authenticated User Reducer if requested.
            registerAuthenticatedUserReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.masterGroup !== false) {
            // Register MasterGroup Reducer if requested.
            registerMasterGroupReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.masterGroups !== false) {
            // Register MasterGroups Reducer if requested.
            registerMasterGroupsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.collaboratingGroup !== false) {
            // Register CollaboratingGroup Reducer if requested.
            registerCollaboratingGroupReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.collaboratingGroups !== false) {
            // Register CollaboratingGroups Reducer if requested.
            registerCollaboratingGroupsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.country !== false) {
            // Register Country Reducer if requested.
            registerCountryReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.countries !== false) {
            // Register Countries Reducer if requested.
            registerCountriesReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.institution !== false) {
            // Register Institution Reducer if requested.
            registerInstitutionReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.institutions !== false) {
            // Register Institutions Reducer if requested.
            registerInstitutionsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.patient !== false) {
            // Register Patient Reducer if requested.
            registerPatientReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.patients !== false) {
            // Register Patients Reducer if requested.
            registerPatientsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.eventDefinition !== false) {
            // Register EventDefinition Reducer if requested.
            registerEventDefinitionReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.eventDefinitions !== false) {
            // Register EventDefinitions Reducer if requested.
            registerEventDefinitionsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.formDefinition !== false) {
            // Register FormDefinition Reducer if requested.
            registerFormDefinitionReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.formDefinitions !== false) {
            // Register FormDefinitions Reducer if requested.
            registerFormDefinitionsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.patientState !== false) {
            // Register PatientState Reducer if requested.
            registerPatientStateReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.patientStates !== false) {
            // Register PatientStates Reducer if requested.
            registerPatientStatesReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.treatment !== false) {
            // Register Treatment Reducer if requested.
            registerTreatmentReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.treatments !== false) {
            // Register Treatments Reducer if requested.
            registerTreatmentsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.event !== false) {
            // Register Event Reducer if requested.
            registerEventReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.events !== false) {
            // Register Events Reducer if requested.
            registerEventsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.lookups !== false) {
            // Register Events Reducer if requested.
            registerLookupsReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.patientValidation !== false) {
            // Register Events Reducer if requested.
            registerPatientValidationReducer(this.serviceStackClient, this.reducerRegistry);
        }

        if (reducerOptions === true || reducerOptions?.form !== false) {
            this.formReducerRegistry = new ActionReducerRegistry<ActionCreators<typeof FormReducer>>();

            this.formMetadata.forEach(formMetadata => {
                // Register Form Reducer if requested.
                registerFormReducer(formMetadata, this.dtos, this.serviceStackClient, this.formReducerRegistry);
            });

            const formReducers = combineReducers(this.formReducerRegistry.getReducers());
            const formLogics = combineLogics(this.formReducerRegistry.getSideEffects());

            this.reducerRegistry.register('form', formReducers, formLogics);

            this.formReducerRegistry.setOnRegister((reducers, sideEffects) => {
                const reducer = combineReducers(reducers)
                const logic = combineLogics(sideEffects)

                this.reducerRegistry.register('form', reducer, logic);
            });
        }

        if (reducerOptions === true || reducerOptions?.forms !== false) {
            this.formsReducerRegistry = new ActionReducerRegistry<ActionCreators<typeof FormsReducer>>();

            this.formMetadata.forEach(formMetadata => {
                // Register Forms Reducer if requested.
                registerFormsReducer(formMetadata, this.dtos, this.serviceStackClient, this.formsReducerRegistry);
            });

            const formsReducers = combineReducers(this.formsReducerRegistry.getReducers());
            const formsLogics = combineLogics(this.formsReducerRegistry.getSideEffects());

            this.reducerRegistry.register('forms', formsReducers, formsLogics);

            this.formsReducerRegistry.setOnRegister((reducers, sideEffects) => {
                const reducer = combineReducers(reducers)
                const logic = combineLogics(sideEffects)

                this.reducerRegistry.register('forms', reducer, logic);
            });
        }
    }

    /**
     * This method initialises the Redux Store.
     * @param storeOptions Configured Redux Store Options
     */
    private initialiseStore(storeOptions?: IOnlinePatientManagementStoreOptions | null, extensions?: IOnlinePatientManagementExtension[] | null) {
        this.initialiseReducers(storeOptions?.initialiseReducers, extensions);

        // Create connected history and associated middleware.
        const {
            createReduxHistory,
            routerMiddleware,
            routerReducer
        } = createReduxHistoryContext({
            history: this.history,
            reduxTravelling: process.env.NODE_ENV !== 'production'
            // others options if needed
        });

        // Register Router Reducer in Reducer Registry.
        this.reducerRegistry.register('router', routerReducer);

        // Combine all currently registered reducers to create the root reducer.
        const reducer = combineReducers(
            this.reducerRegistry.getReducers(),
            storeOptions?.preloadState
        );

        // Create logic middleware with the currently registered logic.
        this.logicMiddleware = createLogicMiddleware(
            combineLogics(this.reducerRegistry.getSideEffects())
        );

        // Create Redux Promise Listener to turn async side effects into promises.
        this.reduxPromiseListener = createReduxPromiseListener();

        // Setup the default middleware used by the online patient management.
        let middleware: Middleware[] = [];

        if (process.env.NODE_ENV === 'production') {
            // Setup production middleware
            applyMiddleware(routerMiddleware);
            middleware.push(this.logicMiddleware);
            middleware.push(this.reduxPromiseListener.middleware);
        }
        else {
            // Setup development middleware
            const logger = require('redux-logger').default;

            middleware.push(routerMiddleware);
            middleware.push(this.logicMiddleware);
            middleware.push(this.reduxPromiseListener.middleware);
            middleware.push(logger);
        }

        // Update middleware using event function if provided
        if (storeOptions?.onMiddlewaresCombined) {
            middleware = storeOptions?.onMiddlewaresCombined(middleware);
        }

        // Connect the redux devtools extension if not production
        const composeEnhancers =
            process.env.NODE_ENV !== 'production' ?
                composeWithDevTools :
                compose;

        // Create store
        this.store = createStore(
            reducer,
            storeOptions?.preloadState as any,
            composeEnhancers(applyMiddleware(...middleware as any) as any)
        );

        // Bind on reducer registration call back to the reducer registry to allow for
        // updating of reducers while the store is in use.
        subscribeToReducerRegistry(
            this.store,
            this.reducerRegistry,
            this.logicMiddleware,
            storeOptions?.preloadState
        );
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OnlinePatientManagement;