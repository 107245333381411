/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the depot management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core/styles';

/**
 * Used to format text.
 */
import Typography from '@material-ui/core/Typography';

import { RequestState } from '@ngt/request-utilities';

import { Button, Paper } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import { CollapsibleTable, PatientInformation, PatientContext, PageLayout } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import DrugDispensationModal from '../modals/DrugDispensationModal';
import { useDrugDispensations } from '../hooks/useDrugDispensations';
import { useTimepoints } from '../hooks/useTimepoints';
import { useDrugDispensation } from '../hooks/useDrugDispensation';
import { useDrugShipmentDepotBatches } from '../hooks/useDrugShipmentDepotBatches';
import { usePharmacy } from '../hooks/usePharmacy';
import DrugBreadcrumbs from '../components/DrugBreadcrumbs';
import { useDrugs } from '../hooks/useDrugs';
import { usePatientDrugs } from '../hooks/usePatientDrugs';
import PatientDrugsModal from '../modals/PatientDrugsModal';
import { useLoading } from '../hooks/useLoading';
import DrugDispensationDeleteButton from '../components/DrugDispensationDeleteButton';
import DrugDispensationDeleteModal from '../modals/DrugDispensationDeleteModal';
import DrugManagementContext from '../context/DrugManagementContext';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugDispensationsParams {
    institutionCode?: string;
    patientStudyNumber?: string;
}

interface IDrugDispensationsProps {
    canAdministerDrugManagement: boolean;
    canDispenseDrug: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    heading: {
        marginTop: theme.spacing(4)
    },
    buttonSet: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(3),
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const useDispensationColumns = (
    drugDispensations: Dtos.DrugDispensation[] | null,
    canAdministerDrugManagement: boolean | null,
    setDrugDispensationDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setDrugDispensationDelete: React.Dispatch<React.SetStateAction<Dtos.DrugDispensation | null>>
    ) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.DrugDispensation>> = [
            {
                title: 'Date of Dispensation',
                field: 'dateDispensation',
                render: row => row.dateDispensation ?
                    DateTime.fromISO(row.dateDispensation).toFormat('dd/MM/yyyy') :
                    null
            },
            {
                title: 'Timepoint',
                field: 'visit',
                render: dispensation => dispensation.timepoint.value
            },
            {
                title: 'Action',
                field: 'action',
                render: dispensation => `${dispensation.depotBatch.batch.drug.drugName} (${dispensation.drugUnits} ${dispensation.depotBatch.batch.drug.containerName}${(dispensation.drugUnits ?? 0) > 1 ? 's' : ''}) dispensed (Expires ${DateTime.fromISO(dispensation.depotBatch.batch.dateExpiration!!).toFormat('dd/MM/yyyy')})`
            },
            {
                title: '',
                field: 'delete',
                render: (dispensation: Dtos.DrugDispensation) => {
                    return (
                        <>
                            {
                                <DrugDispensationDeleteButton
                                    drugDispensation={dispensation}
                                    setDrugDispensationDeleteModalOpen={setDrugDispensationDeleteModalOpen}
                                    canAdministerDrugManagement={canAdministerDrugManagement}
                                    setDrugDispensationDelete={setDrugDispensationDelete}
                                />
                            }
                        </>
                    );
                }
            }
        ]

        return cols;
    }, 
    [
        drugDispensations,
        setDrugDispensationDeleteModalOpen,
        canAdministerDrugManagement,
        setDrugDispensationDelete
    ]);

    return columns as any;
};

const DrugDispensations: React.FunctionComponent<IDrugDispensationsProps> = ({
    canAdministerDrugManagement,
    canDispenseDrug
}) => {
    const classes = useStyles();

    const params = useParams<IDrugDispensationsParams>();

    const drugManagementContext = React.useContext(DrugManagementContext);
    const { patient } = React.useContext(PatientContext);
    const [pharmacy, , , , , pharmacyActions] = usePharmacy();

    const [drugDispensations, drugDispensationsLoadState, drugDispensationsActions] = useDrugDispensations();
    const [drugShipmentDepotBatches, drugShipmentDepotBatchesLoadState, drugShipmentDepotBatchesActions] = useDrugShipmentDepotBatches();
    const [drugDispensation, drugDispensationLoadState, , , drugDispensationActions] = useDrugDispensation();
    const [drugs, drugLoadState, drugActions] = useDrugs();
    const [patientDrugs, patientDrugsLoadState, , patientDrugsActions] = usePatientDrugs();
    const [drugDispensationDeleteModalOpen, setDrugDispensationDeleteModalOpen] = React.useState(false);
    const [timepoints, timepointsLoadState, timepointsActions] = useTimepoints();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [patientDrugsModalOpen, setPatientDrugsModalOpen] = React.useState(false);

    const [drugDispensationDelete, setDrugDispensationDelete] = React.useState<Dtos.DrugDispensation | null>(null);

    const [drugDispensationForm, setDrugDispensationForm] = React.useState<Dtos.DrugDispensation | null>(null);
    const [patientDrugsForm, setPatientDrugsForm] = React.useState<Dtos.PatientDrug[] | null>(null);

    React.useEffect(() => {
        patientDrugsActions.clear();
    }, []);

    React.useEffect(() => {
        if (patient &&
            patient.id) {
            drugDispensationsActions.loadByPatientId(patient.id);
        }

        return () => {
            drugDispensationsActions.clear();
        };
    }, [patient]);

    React.useEffect(() => {
        if (params.institutionCode) {
            pharmacyActions.loadByInstCode(params.institutionCode);
            drugShipmentDepotBatchesActions.loadByInstCode(params.institutionCode);
        }

        return () => {
            pharmacyActions.clear();
            drugShipmentDepotBatchesActions.clear();
        }
    }, [params.institutionCode]);

    React.useEffect(() => {
        if (patient &&
            patient.treatmentId) {
            timepointsActions.loadByTreatmentId(patient.treatmentId);
        }
    }, [patient]);

    React.useEffect(() => {
        drugActions.load();

        return () => {
            drugActions.clear();
        };
    }, []);

    React.useEffect(() => {
        if (drugDispensationLoadState.state === RequestState.Success) {
            setDrugDispensationForm(drugDispensation);

            setModalOpen(true);
        }
    }, [drugDispensationLoadState.state]);

    React.useEffect(() => {
        if (patientDrugsLoadState.state === RequestState.Success) {
            const patientDrugForm: Dtos.PatientDrug[] = [];

            if (patientDrugs &&
                patientDrugs.length > 0) {
                patientDrugForm.push(...patientDrugs);
            }

            if (drugs &&
                patientDrugForm.length < drugs.length) {
                drugs
                    .filter(d => !patientDrugForm.some(pdf => pdf.drugId === d.id))
                    .forEach(d => {
                        const patientDrug: Dtos.PatientDrug = new Dtos.PatientDrug({
                            patientId: patient?.id,
                            drugId: d.id,
                            patientTreatmentStatus: Dtos.PatientTreatmentStatus.OnTreatment,
                            drug: d
                        });

                        patientDrugForm.push(patientDrug);
                    });
            }

            setPatientDrugsForm(patientDrugForm);
            setPatientDrugsModalOpen(true);
        }
    }, [patientDrugs, patientDrugsLoadState, drugs, setPatientDrugsForm, patient, setPatientDrugsModalOpen]);

    const onFormSave = React.useCallback(() => {
        setModalOpen(false);

        if (patient &&
            patient.id) {
            drugDispensationActions.clear();
            drugDispensationsActions.loadByPatientId(patient.id);
        }
    }, [patient]);

    const onPatientDrugsStatusFormSave = React.useCallback(() => {
        setPatientDrugsModalOpen(false);
        setPatientDrugsForm(null);
        patientDrugsActions.clear();
    }, [setPatientDrugsForm, patientDrugsActions]);

    const addDispensationClick = React.useCallback(() => {
        setDrugDispensationForm(new Dtos.DrugDispensation({
            patientId: patient?.id,
            pharmacyId: pharmacy?.id
        }));

        setModalOpen(true);
    }, [setModalOpen, patient, pharmacy]);

    const editPatientTreatmentStatusClick = React.useCallback(() => {
        if (patient &&
            patient.id) {
            patientDrugsActions.clear();
            patientDrugsActions.load(patient.id);
        }
    }, [patient]);

    const onDrugDispensationDelete = React.useCallback(() => {
        setDrugDispensationDeleteModalOpen(false);
        if (patient &&
            patient.id) {
            drugDispensationActions.clear();
            drugDispensationsActions.loadByPatientId(patient.id);
            setDrugDispensationDelete(null);
        }
    }, []);

    //i'm not a fan of this. it's fairly trog specific to say that only admins can edit a dispensation
    //what we should probably do is add a can edit dispensation permission, but that's a job for another day

    const onRowClick = React.useCallback((_event?: React.MouseEvent,
        rowData?: Dtos.DrugDispensation) => {
        if (!!canAdministerDrugManagement &&
            rowData &&
            rowData.id) {
            drugDispensationActions.clear();
            drugDispensationActions.load(rowData.id);
        }
    }, [canAdministerDrugManagement]);

    const dispensationColumns = useDispensationColumns(drugDispensations, canAdministerDrugManagement, setDrugDispensationDeleteModalOpen, setDrugDispensationDelete);

    const drugDispensationsLoading = useLoading(drugDispensations,
        drugDispensationsLoadState);

    const patientCaption = drugManagementContext.patientCaption;

    //this is here for general cleanup in case something is loaded into redux and never reset
    React.useEffect(() => {
        return () => {
            setDrugDispensationDelete(null);
            setPatientDrugsForm(null);
            patientDrugsActions.clear();
            drugDispensationActions.clear();
            timepointsActions.clear();
        }
    }, []);

    return (
        <>
            <DrugDispensationModal
                drugDispensation={drugDispensationForm}
                timepoints={timepoints}
                timepointsLoadState={timepointsLoadState}
                drugShipmentDepotBatches={drugShipmentDepotBatches}
                drugShipmentDepotBatchesLoadState={drugShipmentDepotBatchesLoadState}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onFormSave={onFormSave}
                drugDispensationActions={drugDispensationActions}
            />
            <PatientDrugsModal
                patientDrugs={patientDrugsForm}
                patientDrugsLoadState={patientDrugsLoadState}
                drugs={drugs}
                drugLoadState={drugLoadState}
                modalOpen={patientDrugsModalOpen}
                setModalOpen={setPatientDrugsModalOpen}
                onFormSave={onPatientDrugsStatusFormSave}
                patientDrugsActions={patientDrugsActions}
            />
            {
                drugDispensationDelete ?
                    <DrugDispensationDeleteModal
                        drugDispensation={drugDispensationDelete}
                        modalOpen={drugDispensationDeleteModalOpen}
                        setModalOpen={setDrugDispensationDeleteModalOpen}
                        onFormSave={onDrugDispensationDelete}
                        drugDispensationActions={drugDispensationActions}
                    /> :
                    null
            }
            <PageLayout
                breadcrumbs={
                    <DrugBreadcrumbs
                        institutionCode={pharmacy?.institutionCode}
                        institutionName={pharmacy?.pharmacyName}
                        showInstitution={true}
                    />
                }
                heading='Drug Dispensations'
            >
                <Paper
                    className={classes.container}
                >
                    <PatientInformation
                        patient={patient}
                        patientCaption={patientCaption}
                    />
                    {/* <Typography
                        variant="h1"
                        color="secondary"
                        className={classes.heading}
                    >
                        Drug Dispensations
                    </Typography> */}
                    <div
                        className={classes.buttonSet}
                    >
                        {
                            !!canAdministerDrugManagement && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={editPatientTreatmentStatusClick}
                                    className={classes.button}
                                    disabled={!patient || !patient.id || !drugs}
                                >
                                    Edit Patient Treatment Status
                                </Button>
                            )
                        }
                        {
                            !!canDispenseDrug && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={addDispensationClick}
                                >
                                    Add Dispensation
                                </Button>
                            )
                        }
                    </div>
                    <CollapsibleTable
                        title="Dispensation"
                        loading={drugDispensationsLoading}
                        data={drugDispensations || []}
                        columns={dispensationColumns}
                        onRowClick={onRowClick}
                    />
                </Paper>
            </PageLayout>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default DrugDispensations;