/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae summary buttons component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Link, useHistory } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { InstitutionContext, OnlinePatientManagementContext, ProgressButton, useSnackbar } from '@ngt/opms'

import { AlertTitle } from '@material-ui/lab';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';

import SaeReviewContext from '../context/SaeReviewContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeSummaryButtonsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    buttonSet: {
        textAlign: 'right',
        marginTop: theme.spacing(0)
    },
    button: {
        marginLeft: theme.spacing(1),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    hidden: {
        display: 'none'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform
];

const SaeSummaryButtons: React.FunctionComponent<ISaeSummaryButtonsProps> = ({
}) => {
    const classes = useStyles();

    const { institution } = React.useContext(InstitutionContext);

    const { sae, loadState, actions: saeActions } = React.useContext(SaeContext);

    const [[canViewSae, canReviewSae, canAdministerSae, canMedicallyReviewSae], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, false);

    const contextSaeReview = React.useContext(SaeReviewContext);

    const hasMedicalReview = contextSaeReview?.hasMedicalReview;

    const history = useHistory();

    const onMarkAsInvalidClick = React.useCallback(() => {
        history.push(`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}/invalid`);
    }, [history, institution, sae]);

    const resetSaeClick = React.useCallback(() => {
        saeActions.performAction(new Dtos.WebAction({
            patientStudyNumber: sae?.patient.studyNumber,
            saeNumber: sae?.saeNumber,
            saeFollowUpNumber: sae?.form.followUpNumber,
            actionType: Dtos.SaeWebActionTypeEnum.Reset,
            notes: '',
            recipients: ''
        }));

        saeActions.load();
    }, [sae, saeActions]);

    const saeStatus = React.useMemo(() => {
        return sae?.status;
    }, [sae]);

    return <>
        <div className={classes.buttonSet}>
            {
                !!canAdministerSae &&
                saeStatus != null &&
                [Dtos.SaeStatus.Added, Dtos.SaeStatus.New, Dtos.SaeStatus.AwaitingForReview].includes(saeStatus) &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}/notify/investigators`}
                >
                    Notify Investigators
                </Button>
            }

            {
                !!canMedicallyReviewSae &&
                !!hasMedicalReview &&
                sae?.medicalReviewStatus === Dtos.SaeMedicalReviewStatus.Pending &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}/mreview`}
                >
                    Medical Review
                </Button>
            }

            {
                !!canAdministerSae &&
                !!hasMedicalReview &&
                saeStatus != null &&
                saeStatus != Dtos.SaeStatus.Invalid &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}/notify/mreviewer`}
                >
                    Notify Medical Reviewers
                </Button>
            }

            {
                !!canAdministerSae &&
                saeStatus != null &&
                [Dtos.SaeStatus.Approved, Dtos.SaeStatus.Rejected, Dtos.SaeStatus.AwaitingForReview].includes(saeStatus) &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={resetSaeClick}
                >
                    Reset SAE Status
                </Button>
            }

            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={`#ActionHistory`}
            >
                Action History
            </Button>

            {
                !!canAdministerSae &&
                saeStatus != null &&
                saeStatus != Dtos.SaeStatus.Invalid &&
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onMarkAsInvalidClick}
                >
                    Mark as Invalid
                </Button>
            }
        </div>
    </>;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeSummaryButtons;