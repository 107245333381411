/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';

import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IInputRenderProps } from '../../../form/components/Input';

import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = Partial<Omit<MuiTextFieldProps, 'type'>> & IInputRenderProps<number, Dtos.IValidationError> & { numberFormat: Omit<NumberFormatProps, 'format'> & { format?: (val: number | string | undefined) => number | string | undefined } }

export interface INumericProps extends TextFieldsProps {
}

interface IInternalNumberFormatProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { value: number | undefined } }) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const InternalNumberFormat: React.FunctionComponent<IInternalNumberFormatProps> = ({
    inputRef,
    onChange,
    ...rest
}) => {
    const onValueChange = React.useCallback((values: NumberFormatValues) => {
        onChange({
            target: {
                value: values.floatValue,
            },
        })
    }, [onChange]);

    return (
        <NumberFormat
            {...rest}
            getInputRef={inputRef}
            onValueChange={onValueChange}
        />
    );
}


const Numeric: React.FunctionComponent<INumericProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    fullWidth,
    onChange,
    InputProps,
    inputProps,
    numberFormat,
    ...rest
}) => {
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
        
        const newValue = !isNaN(+event.target.value) ? +event.target.value : undefined;

        onInputChange(newValue || newValue as any === 0 ? newValue : undefined as any);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
    }, [inputProps?.onBlur, onBlur]);

    return (
        <MuiTextField
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            InputProps={{
                ...InputProps,
                inputComponent: InputProps?.inputComponent === undefined ? InternalNumberFormat : InputProps?.inputComponent
            }}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined,
                ...numberFormat
            }}
            name={name}
            value={value ?? NaN}
            onChange={onChangeCombined}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Numeric;
