/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae notification form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Permission } from '@ngt/opms-bctapi';

import {
    Checkbox,
    InstitutionContext,
    Form,
    SubmitButton,
    Field,
    Text,
    TextArea,
    FieldGroup,
    FormGrid,
    IFormGridCell,
    Input,
    FileUpload,

    ResponseStatus,
    RouteLoading,
    OnlinePatientManagementContext
} from '@ngt/opms';

import { makeStyles, Button } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useSaeReviewers } from '../hooks/useSaeReviewers';

import useSaeNotificationForm from '../hooks/useSaeNotificationForm';

import { CheckboxListWrapper } from './CheckboxListWrapper';
import { RequestState } from '@ngt/request-utilities';
import SaeReviewContext from '../context/SaeReviewContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface PersonnelLabelComponentProps {
    personnel: Dtos.InstitutionPersonnel;
}

export interface AdditionalDataComponents {
    FormStart?: React.ComponentType<AdditionalDataProps>;
    BeforeRecipients?: React.ComponentType<AdditionalDataProps>;
    BeforeCcRecipients?: React.ComponentType<AdditionalDataProps>;
    BeforeNotes?: React.ComponentType<AdditionalDataProps>;
    BeforeStudyChair?: React.ComponentType<AdditionalDataProps>;
    BeforeSponsor?: React.ComponentType<AdditionalDataProps>;
    BeforeDocuments?: React.ComponentType<AdditionalDataProps>;
    BeforeSubmit?: React.ComponentType<AdditionalDataProps>;
    FormEnd?: React.ComponentType<AdditionalDataProps>;
}

export interface AdditionalDataProps {
    notificationType?: Dtos.SaeNotificationType;
}

interface ISaeNotificationFormProps {
    notifyMode: string;
    PersonnelLabelComponent?: React.ComponentType<PersonnelLabelComponentProps>;
    setInitialFormValues?: (initialValues: Dtos.SaeNotificationForm) => Dtos.SaeNotificationForm;
    AdditionalDataComponents?: AdditionalDataComponents
}

interface INotificationForm {
    recipients: number[];
    ccRecipients: Record<number, boolean>;
    notes: string;
}

const DEFAULT_PERSONNEL_LABEL_COMPONENT: React.FC<PersonnelLabelComponentProps> = ({
    personnel
}) => {
    return (
        <>
            {personnel.name}
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    },
    alert: {
        margin: theme.spacing(0, 0, 3, 0),
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform
];

const SaeNotificationForm: React.FunctionComponent<ISaeNotificationFormProps> = ({
    notifyMode,
    PersonnelLabelComponent,
    setInitialFormValues,
    AdditionalDataComponents
}) => {
    const classes = useStyles();

    const { institution } = React.useContext(InstitutionContext);

    const {
        investigatorRoles,
        medicalReviewerRoles,
        studyChairRoles,
        studySponsor
    } = React.useContext(SaeReviewContext);

    const notificationType = React.useMemo(() => {
        if (notifyMode === 'investigators') {
            return Dtos.SaeNotificationType.NotifyInvestigator
        } else if (notifyMode === 'mreviewer') {
            return Dtos.SaeNotificationType.NotifyMedicalReviewer
        }
    }, [notifyMode])

    const [saeReviewers, saeReviewersLoadState, saeReviewersActions] = useSaeReviewers(notificationType === Dtos.SaeNotificationType.NotifyInvestigator ? institution?.code ?? '' : '',
        notificationType === Dtos.SaeNotificationType.NotifyInvestigator ? investigatorRoles : medicalReviewerRoles,
        (notificationType === Dtos.SaeNotificationType.NotifyInvestigator && investigatorRoles.length > 0) || (notificationType === Dtos.SaeNotificationType.NotifyMedicalReviewer && medicalReviewerRoles.length > 0) ? true : false);

    const [studyChairs, studyChairsLoadState, studyChairsActions] = useSaeReviewers('', studyChairRoles, notificationType === Dtos.SaeNotificationType.NotifyInvestigator && studyChairRoles?.length > 0 ? true : false);

    const LabelComponent = PersonnelLabelComponent ?? DEFAULT_PERSONNEL_LABEL_COMPONENT;

    const reviewersValues = React.useMemo(() => {
        if (!saeReviewers) {
            return [];
        }

        return Object.keys(saeReviewers).map(i => ({ 
            value: parseInt(i, 10), 
            label: <LabelComponent personnel={saeReviewers[i as any]} /> 
        }));
    }, [saeReviewers, LabelComponent]);

    const {
        labels,
        handleSubmit,
        onFormCancel,
        onFormSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useSaeNotificationForm({ formType: undefined, afterFormSave: undefined, onCancel: undefined, readOnly: false });

    const columns: Array<IFormGridCell<Dtos.SaeDocumentForm>> = [
        {
            name: 'name',
            content: (
                <Input component={Text} />
            )
        },
        {
            name: 'file',
            content: (
                <Input
                    component={FileUpload}
                    existingLink={undefined}
                    existingDownloadLink={undefined} 
                />
            )
        }
    ]

    const initialNotificationForm: Dtos.SaeNotificationForm = React.useMemo(() => {
        const formValues: Dtos.SaeNotificationForm = {
            recipients: [],
            ccRecipients: [],
            notificationType: notificationType as Dtos.SaeNotificationType,
            notes: '',
            notifyStudyChair: notificationType === Dtos.SaeNotificationType.NotifyInvestigator && Object.keys(studyChairs).length > 0 ? true : false,
            notifySponsor: notificationType === Dtos.SaeNotificationType.NotifyInvestigator && studySponsor ? true : false,
            attachments: []
        }

        return setInitialFormValues ? setInitialFormValues(formValues) : formValues;
    }, [notificationType, studyChairs, setInitialFormValues]);

    if (saeReviewersLoadState.state === RequestState.Pending) {
        return (
            <>
                <RouteLoading />
            </>
        );
    }

    const additionalDataProps = {
        notificationType
    };

    return (
        reviewersValues?.length ?
        <>
            <Form
                initialValues={initialNotificationForm}
                onValidate={validate}
                validateOn="onChange"
                allowSubmit={undefined}
                fieldErrors={'default'}
                onSubmit={handleSubmit}
                labels={labels}
                lookups={undefined}
                readOnly={false}
                onSubmitFailed={onFormSubmitFailure}
                onSubmitValidationFailed={onFormSubmitValidationFailure}
            >
                {
                    AdditionalDataComponents?.FormStart && (
                        <AdditionalDataComponents.FormStart {...additionalDataProps} />
                    )
                }

                <div
                    className={classes.container}
                >
                    <Typography
                        variant="h2"
                        color="primary"
                    >
                        Please select recipients from the list below, enter notes if required, and click Send.
                    </Typography>
                </div>
                
                <FieldGroup>

                    {
                        AdditionalDataComponents?.BeforeRecipients && (
                            <AdditionalDataComponents.BeforeRecipients {...additionalDataProps} />
                        )
                    }

                    <Field
                        name="recipients"
                        component={CheckboxListWrapper}
                        values={reviewersValues as any}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        simpleLabel
                    />

                    {
                        AdditionalDataComponents?.BeforeCcRecipients && (
                            <AdditionalDataComponents.BeforeCcRecipients {...additionalDataProps} />
                        )
                    }

                    <Field
                        name="ccRecipients"
                        component={CheckboxListWrapper}
                        values={reviewersValues as any}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        simpleLabel
                    />

                    {
                        AdditionalDataComponents?.BeforeNotes && (
                            <AdditionalDataComponents.BeforeNotes {...additionalDataProps} />
                        )
                    }

                    <Field
                        name="notes"
                        component={TextArea}
                        rows={10}
                        rowsMax={20}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        variant="outlined"
                        simpleLabel
                    />

                    {
                        notificationType === Dtos.SaeNotificationType.NotifyInvestigator && <>

                            {
                                AdditionalDataComponents?.BeforeStudyChair && (
                                    <AdditionalDataComponents.BeforeStudyChair {...additionalDataProps} />
                                )
                            }
                    
                            <Field
                                name="notifyStudyChair"
                                component={Checkbox}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                inputLabel={`Notify Study Chair & Deputy Study Chair ${Object.keys(studyChairs).length > 0 ? "(" + Object.keys(studyChairs).map(x => studyChairs[x as any as number].name).join(", ") + ")" : ""}`}
                                paddingBottom={0}
                                disabled={Object.keys(studyChairs).length === 0}
                            />


                            {
                                AdditionalDataComponents?.BeforeSponsor && (
                                    <AdditionalDataComponents.BeforeSponsor {...additionalDataProps} />
                                )
                            }
                
                            <Field
                                name="notifySponsor"
                                component={Checkbox}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                inputLabel={`Notify Sponsor ${studySponsor ? "(" + studySponsor + ")" : ""}`}
                                paddingTop={0}
                                disabled={!studySponsor}
                            />
                        </>
                    }

                    {
                        AdditionalDataComponents?.BeforeDocuments && (
                            <AdditionalDataComponents.BeforeDocuments {...additionalDataProps} />
                        )
                    }
                
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Attach Documents
                    </Typography>

                    <FormGrid
                        name="attachments"
                        columns={columns}
                        type={Dtos.SaeDocumentForm}
                    />

                    {
                        AdditionalDataComponents?.BeforeSubmit && (
                            <AdditionalDataComponents.BeforeSubmit {...additionalDataProps} />
                        )
                    }
                </FieldGroup>

                <div
                    className={classes.buttonGroup}
                >
                    {
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            onClick={onFormSubmit}
                        >
                            Send
                        </SubmitButton>
                    }

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onFormCancel}
                    >
                        Cancel
                    </Button>
                </div>

                {
                    AdditionalDataComponents?.FormEnd && (
                        <AdditionalDataComponents.FormEnd {...additionalDataProps} />
                    )
                }
            </Form>
        </>
        :
        <>
            <div
                className={classes.buttonGroup}
            >
                <Alert
                    icon={<></>}
                    severity="error"
                    className={classes.alert}
                >
                        There are currently no investigators connected to { institution?.name }. Please contact your system administrator.
                </Alert>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onFormCancel}
                >
                    Cancel
                </Button>
            </div>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeNotificationForm;