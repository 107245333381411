/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Grid, useTheme, makeStyles } from '@material-ui/core';

import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

import { faHeartbeat } from '@fortawesome/pro-duotone-svg-icons/faHeartbeat';

import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';

import { InstitutionContext, PatientContext, IInstitution, IPatient } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import SaeInformationCard from './SaeInformationCard';

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext'


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type SaeInformationFn = (patient: IPatient | null, institution?: IInstitution | null, sae?: Dtos.Sae<Dtos.ISaeForm, IPatient> | null) => string;

/**
 * This interface defines the properties for the SaeInformation component.
 */
export interface ISaeInformationProps {
    patient?: IPatient | null;
    institution?: IInstitution | null;
    sae?: Dtos.Sae<Dtos.ISaeForm, IPatient> | null;
    patientText?: string | SaeInformationFn;
    patientCaption?: string | SaeInformationFn;
    institutionText?: string | SaeInformationFn;
    institutionCaption?: string | SaeInformationFn;
    saeText?: string | SaeInformationFn;
    saeCaption?: string | SaeInformationFn;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',

        '& > *': {
            flex: '1 1 auto'
        }
    },
    mb3: {
        marginBottom: theme.spacing(2)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the sae review system.
 * @param param0 component properties.
 */
const SaeInformation: React.FunctionComponent<ISaeInformationProps> = ({
    children,
    patient,
    institution,
    sae,
    patientText,
    patientCaption,
    institutionText,
    institutionCaption,
    saeText,
    saeCaption
}) => {
    const theme = useTheme()
    const classes = useStyles();

    const contextPatient = React.useContext(PatientContext);
    const contextInstitution = React.useContext(InstitutionContext);
    const contextSae = React.useContext(SaeContext);

    const patientToUse = React.useMemo(() => {
        return patient === undefined ? contextPatient.patient : patient;
    }, [patient, contextPatient]);

    const institutionToUse = React.useMemo(() => {
        return institution === undefined ? contextInstitution.institution : institution;
    }, [institution, contextInstitution]);

    const saeToUse = React.useMemo(() => {
        return sae === undefined ? contextSae.sae : sae;
    }, [sae, contextSae]);

    const patientTextToUse = !patientText ?
        patientToUse?.studyNumber ?? 'New Patient' :
        patientText instanceof Function ?
            patientText(patientToUse, institutionToUse, saeToUse) :
            patientText;

    const patientCaptionToUse = !patientCaption ?
        patientToUse?.id?.toString() ?? '' :
        patientCaption instanceof Function ?
            patientCaption(patientToUse, institutionToUse, saeToUse) :
            patientCaption;

    const institutionTextToUse = !institutionText ?
        institutionToUse?.code ?? 'XXXXX' :
        institutionText instanceof Function ?
            institutionText(patientToUse, institutionToUse, saeToUse) :
            institutionText;

    const institutionCaptionToUse = !institutionCaption ?
        institutionToUse?.name ?? 'Unknown Institution' :
        institutionCaption instanceof Function ?
            institutionCaption(patientToUse, institutionToUse, saeToUse) :
            institutionCaption;

    const saeTextToUse = !saeText ?
        saeToUse?.saeNumber as unknown as string ?? 'Unknown Sae' :
        saeText instanceof Function ?
            saeText(patientToUse, institutionToUse, saeToUse) :
            saeText ?? '';

    const saeCaptionToUse = !saeCaption ?
        saeToUse?.saeNumber as unknown as string ?? 'Unknown Sae' :
        saeCaption instanceof Function ?
            saeCaption(patientToUse, institutionToUse, saeToUse) :
            saeCaption ?? '';

    return (
        <Grid
            container
            spacing={3}
            className={classes.mb3}
        >
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <SaeInformationCard
                    color={theme.palette.primary.main}
                    icon={faUser}
                    title="Patient"
                    text={patientTextToUse}
                    caption={patientCaptionToUse}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <SaeInformationCard
                    color={theme.palette.secondary.main}
                    icon={faBuilding}
                    title="Institution"
                    text={institutionTextToUse}
                    caption={institutionCaptionToUse}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                className={classes.item}
            >
                <SaeInformationCard
                    color="#76c6d7"
                    icon={faHeartbeat}
                    title="SAE"
                    text={saeTextToUse}
                    caption={saeCaptionToUse}
                />
            </Grid>
        </Grid>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeInformation;