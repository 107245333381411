/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae investigator review form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { makeStyles, Button, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';


import {
    InstitutionContext,
    Form, SubmitButton,
    Field,
    TextArea,
    FieldGroup,
    FormGrid,
    IFormGridCell,
    Input,
    Text,
    Select,
    GetFieldLookup,
    useFormActions, 
    ICrfConditionParameters,
    CrfCondition,
    IFileUpload,
    FileUpload,
    KeyboardDatePicker,
    ProgressButton,
    useSnackbar,
    IFormState,
    IValidationError,
    ValidationErrorType,
    useFormState
} from '@ngt/opms';

import { AlertTitle } from '@material-ui/lab';

import pluralize from 'pluralize';

import { useHistory } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';

import useInvalidSaeForm, { errorTextMapping, errorVariantMapping } from '../hooks/useInvalidSaeForm';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInvalidSaeFormProps {
    readOnly?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(-3),
        paddingTop: theme.spacing(2.5)
    },
    subHeading: {
        padding: theme.spacing(2, 3, 3, 3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const existingLink = (value?: IFileUpload) => {
    const file = value as any as Dtos.InvalidSaeFile;

    return `/opms/sae/file/view/${file?.id}`;
}

const existingDownloadLink = (value?: IFileUpload) => {
    const file = value as any as Dtos.InvalidSaeFile;

    return `/opms/sae/file/download/${file?.id}`;
}

const reasonOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.InvalidSaeForm>) => formState?.values.reason === Dtos.InvalidSaeReason.Other;

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const InvalidSaeConfirmationDialog: React.FunctionComponent<{
    open: boolean,
    toggleConfirmationDialog: () => void
}> = ({
    open,
    toggleConfirmationDialog
}) => {
    const { sae } = React.useContext(SaeContext);

    const { submit, setFieldValue } = useFormActions<Dtos.InvalidSaeForm, IValidationError>();

    const {
        onFormSubmit,
    } = useInvalidSaeForm();

    const onInvalidSaeConfirmation = React.useCallback(() => {

        toggleConfirmationDialog();

        setFieldValue('patientStudyNumber', sae?.patient?.studyNumber);
        setFieldValue('saeNumber', sae?.saeNumber);

        onFormSubmit();

        submit();

    }, [sae, submit, setFieldValue, onFormSubmit, toggleConfirmationDialog]);

    return (
        <Dialog open={open} onClose={toggleConfirmationDialog} aria-labelledby="remove-dialog" maxWidth="xs">
            <DialogTitle>
                Mark SAE as invalid
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography color={"error"}>The SAE data will be <strong>PERMANENTLY DELETED</strong>. This <strong>CANNOT</strong> be undone.</Typography>
                    <br />
                    Are you sure you want to mark patient {sae?.patient.studyNumber}'s SAE #{sae?.saeNumber} as invalid?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={toggleConfirmationDialog}
                >
                    No
                </Button>
                <Button
                    color="primary"
                    onClick={onInvalidSaeConfirmation}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const MarkAsInvalidButton: React.FunctionComponent<{
    toggleConfirmationDialog: () => void
}> = ({
    toggleConfirmationDialog
}) => {

    const { submitting } = useFormState({ submitting: true });

    return (
        <ProgressButton
            variant="contained"
            color="primary"
            onClick={toggleConfirmationDialog}
            loading={submitting}
        >
            Mark as Invalid
        </ProgressButton>
    );
}


const InvalidSaeForm: React.FunctionComponent<IInvalidSaeFormProps> = ({
    readOnly
}) => {
    const classes = useStyles();

    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { sae, actions: saeActions } = React.useContext(SaeContext);

    const { institution } = React.useContext(InstitutionContext);

    const invalidForm = sae?.invalidForm;

    const columns: Array<IFormGridCell<Dtos.InvalidSaeFile>> = React.useMemo(() => {
        return [
            {
                name: 'name',
                content: (
                    <Input component={Text} />
                )
            },
            {
                name: 'fileUpload',
                content: (
                    <Input
                        component={FileUpload}
                        existingLink={existingLink}
                        existingDownloadLink={existingDownloadLink}
                        disableUpload={(value?: IFileUpload, name?: string) => (invalidForm?.id != null && name?.includes("0")) ?? false}
                    />
                )
            }
        ]
    }, [invalidForm]);

    const allowDelete = React.useCallback((values, index) => {
        return invalidForm?.id == null || index != 0
    }, [invalidForm]);

    const reasonLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, 'Reason') ?? undefined;;
    }, [sae]);

    const decidedByLookup = React.useMemo(() => {
        return GetFieldLookup(sae?.lookups!, 'DecidedById') ?? undefined;;
    }, [sae]);

    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState<boolean>(false);

    const toggleConfirmationDialog = React.useCallback(() => {
        setConfirmationDialogOpen(!confirmationDialogOpen)
    }, [confirmationDialogOpen, setConfirmationDialogOpen]);

    const {
        handleSubmit,
        onFormSubmit,
        onFormSubmitAndReturn,
        onFormCancel,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useInvalidSaeForm();

    const onMarkSaeAsInvalid = React.useCallback(async ({ values, errors }: IFormState<Dtos.InvalidSaeForm, IValidationError>) => {
        const { submitType, ...form } = values as any;

        await saeActions.asyncSubmitInvalidSaeForm(values as Dtos.InvalidSaeForm);

        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SAE Not Marked as Invalid
                    </AlertTitle>
                    The form was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                </>,
                { variant: errorVariantMapping[maxErrorType] }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        SAE Marked as Invalid
                    </AlertTitle>
                    SAE was successfully marked as invalid.
                </>,
                { variant: 'success' }
            );
        }

        history.push(`/sae/${institution?.code}/${sae?.patient?.studyNumber}/${sae?.saeNumber}`);

    }, [saeActions?.asyncReject, saeActions.performAction, saeActions.load, institution, sae, history]);

    return (
        <div className={classes.container}>
            {
                <Form
                    initialValues={invalidForm}
                    onValidate={validate}
                    validateOn="onChange"
                    allowSubmit={undefined}
                    fieldErrors={'default'}
                    onSubmit={!!invalidForm ? handleSubmit : onMarkSaeAsInvalid}
                    labels={undefined}
                    lookups={undefined}
                    onSubmitFailed={onFormSubmitFailure}
                    onSubmitValidationFailed={onFormSubmitValidationFailure}
                    readOnly={!!readOnly}
                >
                    <Field
                        name="dateDeterminedInvalid"
                        label={
                            <Typography
                                variant="body1"
                            >
                                Date determined invalid
                            </Typography>
                        }
                        component={KeyboardDatePicker}
                        md={5}
                        lg={5}
                        xl={5}
                        fullWidth
                    />
                    <FieldGroup>
                        <Field
                            name="reason"
                            label={
                                <Typography
                                    variant="body1"
                                >
                                    Reason
                                </Typography>
                            }
                            component={Select}
                            nullOption={false}
                            md={5}
                            lg={5}
                            xl={5}
                            fullWidth
                        >
                            {
                                reasonLookup?.items?.map(item => {
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.value}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Field>
                        <CrfCondition
                            type={Dtos.InvalidSaeForm}
                            condition={reasonOther}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="reasonSpecify"
                                label={
                                    <Typography
                                        variant="body1"
                                    >
                                        Specify
                                    </Typography>
                                }
                                component={TextArea}
                                md={5}
                                lg={5}
                                xl={5}
                                fullWidth
                            />
                        </CrfCondition>
                    </FieldGroup>
                    <Field
                        name="decidedById"
                        label={
                            <Typography
                                variant="body1"
                            >
                                Decided by
                            </Typography>
                        }
                        component={Select}
                        nullOption={false}
                        md={5}
                        lg={5}
                        xl={5}
                        fullWidth
                    >
                        {
                            decidedByLookup?.items?.map(item => {
                                return (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.value}
                                    </MenuItem>
                                );
                            })
                        }
                    </Field>
                    <Field
                        name="comments"
                        label={
                            <Typography
                                variant="body1"
                            >
                                Narrative summary
                            </Typography>
                        }
                        component={TextArea}
                        variant="outlined"
                        rows={6}
                        md={5}
                        lg={5}
                        xl={5}
                        fullWidth
                    />

                    <FieldGroup>
                        <Typography
                            variant="h2"
                            color="primary"
                            className={classes.subHeading}
                        >
                            Files
                        </Typography>
                        <FormGrid
                            name="files"
                            columns={columns}
                            type={Dtos.InvalidSaeFile}
                            allowDelete={allowDelete}
                        />
                    </FieldGroup>

                    <div className={classes.buttonGroup}>
                        {
                            !readOnly && !!invalidForm && (
                                <>
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        onClick={onFormSubmit}
                                    >
                                        Submit
                                    </SubmitButton>
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        onClick={onFormSubmitAndReturn}
                                    >
                                        Submit and Return
                                    </SubmitButton>
                                </>    
                            )
                        }

                        {
                            !readOnly && !invalidForm && (
                                <MarkAsInvalidButton toggleConfirmationDialog={toggleConfirmationDialog} />
                            )
                        }
                                    
                        <Button
                            variant="contained"
                            type="button"
                            color="primary"
                            onClick={onFormCancel}
                        >
                            {!readOnly ? 'Cancel' : 'Back'}
                        </Button>
                    </div>   

                    <InvalidSaeConfirmationDialog
                        open={confirmationDialogOpen}
                        toggleConfirmationDialog={toggleConfirmationDialog}
                    />
                </Form>
            }
        </div >    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default InvalidSaeForm;