/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the sae reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';

import { ISaeRejectionForm } from '../components/SaeInvestigatorReviewForm';

import { Patient } from '../api/patient';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualSaeState {
    sae: Dtos.Sae<Dtos.ISaeForm, Patient> | null;
    loadState: IRequestState<ResponseStatus>;
    actionState: IRequestState<ResponseStatus>;
};

export interface ISaeState {
    byContext: Record<string, IIndividualSaeState>;
};

export interface ISaeStore {
    sae: ISaeState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualSaeState: IIndividualSaeState = {
    sae: null,
    loadState: {
        state: RequestState.None
    },
    actionState: {
        state: RequestState.None
    }
};

export const initialSaeState: ISaeState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class SaeReducer extends ImmerReducer<ISaeState> {
    public load(patientStudyNumber: string, saeNumber: number) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(patientStudyNumber: string, saeNumber: number, sae?:Dtos.Sae<Dtos.ISaeForm, Patient>) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].sae = sae ? sae : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(patientStudyNumber: string, saeNumber: number, responseStatus?: ResponseStatus) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public notify(patientStudyNumber: string, saeNumber: number, notificationForm: Dtos.SaeNotificationForm) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Pending
        }
    }

    public notifySuccess(patientStudyNumber: string, saeNumber: number) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Success
        };
    }

    public notifyFailure(patientStudyNumber: string, saeNumber: number, responseStatus: ResponseStatus) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public reject(patientStudyNumber: string, saeNumber: number, rejectForm: ISaeRejectionForm) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Pending
        }
    }

    public rejectSuccess(patientStudyNumber: string, saeNumber: number) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Success
        };
    }

    public rejectFailure(patientStudyNumber: string, saeNumber: number, responseStatus:ResponseStatus) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public performAction(patientStudyNumber: string, saeNumber: number, webAction: Dtos.WebAction) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Pending
        }
    }

    public performActionSuccess(patientStudyNumber: string, saeNumber: number) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Success
        };
    }

    public performActionFailure(patientStudyNumber: string, saeNumber: number, responseStatus: ResponseStatus) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public submitMedicalReview(patientStudyNumber: string, saeNumber: number, medicalReview: Dtos.MedicalReview) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Pending
        }
    }

    public submitMedicalReviewSuccess(patientStudyNumber: string, saeNumber: number) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Success
        };
    }

    public submitMedicalReviewFailure(patientStudyNumber: string, saeNumber: number, responseStatus: ResponseStatus) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public submitInvalidForm(patientStudyNumber: string, saeNumber: number, invalidForm: Dtos.InvalidSaeForm) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Pending
        }
    }

    public submitInvalidFormSuccess(patientStudyNumber: string, saeNumber: number, invalidSaeForm: Dtos.InvalidSaeForm) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Success
        };

        if (this.draftState.byContext[context].sae != null) {
            this.draftState.byContext[context].sae!.invalidForm = invalidSaeForm;
        }
    }

    public submitInvalidFormFailure(patientStudyNumber: string, saeNumber: number, responseStatus: ResponseStatus) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualSaeState };
        }

        this.draftState.byContext[context].actionState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(patientStudyNumber: string, saeNumber: number) {
        const context = `${patientStudyNumber}-${saeNumber}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialSaeState };
    }
};

export const saeActions = createActionCreators(SaeReducer);
export const saeReducer = createReducerFunction(SaeReducer, initialSaeState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createSaeApi = (client: JsonServiceClient) => ({
    load: (patientStudyNumber: string, saeNumber: number) => {
        const request: Dtos.SaeGetSingleByPatientStudyNumber = new Dtos.SaeGetSingleByPatientStudyNumber();
        request.patientStudyNumber = patientStudyNumber;
        request.saeNumber = saeNumber;
        return client.get(request);
    },
    notify: (patientStudyNumber: string, saeNumber: number, notificationForm: Dtos.SaeNotificationForm) => {
        const request: Dtos.SaeSendNotification = new Dtos.SaeSendNotification();
        request.patientStudyNumber = patientStudyNumber;
        request.saeNumber = saeNumber;
        request.notificationForm = notificationForm;
        return client.post(request);
    },
    reject: (patientStudyNumber: string, saeNumber: number, rejectionForm: ISaeRejectionForm) => {
        const request: Dtos.SaeWebActionSave = new Dtos.SaeWebActionSave();
        request.webAction = new Dtos.WebAction({
            patientStudyNumber,
            saeNumber,
            saeFollowUpNumber: rejectionForm?.followUpNumber,
            actionType: Dtos.SaeWebActionTypeEnum.Rejected,
            notes: rejectionForm?.notes,
            reasonForRejection: rejectionForm?.reasonForRejection,
            rejectedAs: rejectionForm?.rejectedAs,
            recipients: ''
        });
        return client.post(request);
    },
    performAction: (patientStudyNumber: string, saeNumber: number, webAction: Dtos.WebAction) => {
        const request: Dtos.SaeWebActionSave = new Dtos.SaeWebActionSave();
        request.webAction = webAction;
        return client.post(request);
    },
    submitMedicalReview: (patientStudyNumber: string, saeNumber: number, medicalReview: Dtos.MedicalReview) => {
        const request = new Dtos.SaeMedicalReviewPostSave({
            medicalReview: medicalReview
        })

        return client.post(request);
    },
    submitInvalidForm: (patientStudyNumber: string, saeNumber: number, invalidForm: Dtos.InvalidSaeForm) => {
        const request = new Dtos.SaeInvalidFormPostSave({
            invalidSaeForm: invalidForm
        })

        return client.post(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createSaeLogic = (api: ReturnType<typeof createSaeApi>) => {
    const logic = {
        load: createLogic<ISaeStore, {}, undefined, string, ReturnType<typeof saeActions.load>>({
            type: saeActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, saeNumber] = action.payload

                try {
                    let response: Dtos.SaeSingleResponse | null = null;

                    response = await api.load(patientStudyNumber, saeNumber);

                    dispatch(saeActions.loadSuccess(
                        patientStudyNumber,
                        saeNumber,
                        response.sae
                    ));
                }
                catch (error: any) {
                    dispatch(saeActions.loadFailure(
                        patientStudyNumber,
                        saeNumber,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        notify: createLogic<ISaeStore, {}, undefined, string, ReturnType<typeof saeActions.notify>>({
            type: saeActions.notify.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, saeNumber, notificationForm] = action.payload

                try {
                    const response = await api.notify(patientStudyNumber, saeNumber, notificationForm);

                    dispatch(saeActions.notifySuccess(
                        patientStudyNumber,
                        saeNumber
                    ));
                }
                catch (error: any) {
                    dispatch(saeActions.notifyFailure(
                        patientStudyNumber,
                        saeNumber,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        reject: createLogic<ISaeStore, {}, undefined, string, ReturnType<typeof saeActions.reject>>({
            type: saeActions.reject.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, saeNumber, rejectForm] = action.payload

                try {
                    const response = await api.reject(patientStudyNumber, saeNumber, rejectForm);

                    dispatch(saeActions.rejectSuccess(
                        patientStudyNumber,
                        saeNumber
                    ));
                }
                catch (error: any) {
                    dispatch(saeActions.rejectFailure(
                        patientStudyNumber,
                        saeNumber,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        performAction: createLogic<ISaeStore, {}, undefined, string, ReturnType<typeof saeActions.performAction>>({
            type: saeActions.performAction.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, saeNumber, webAction] = action.payload

                try {
                    const response = await api.performAction(patientStudyNumber, saeNumber, webAction);

                    dispatch(saeActions.performActionSuccess(
                        patientStudyNumber,
                        saeNumber
                    ));
                }
                catch (error: any) {
                    dispatch(saeActions.performActionFailure(
                        patientStudyNumber,
                        saeNumber,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        submitMedicalReview: createLogic<ISaeStore, {}, undefined, string, ReturnType<typeof saeActions.submitMedicalReview>>({
            type: saeActions.submitMedicalReview.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, saeNumber, medicalReview] = action.payload

                try {
                    const response = await api.submitMedicalReview(patientStudyNumber, saeNumber, medicalReview);

                    dispatch(saeActions.submitMedicalReviewSuccess(
                        patientStudyNumber,
                        saeNumber
                    ));
                }
                catch (error: any) {
                    dispatch(saeActions.submitMedicalReviewFailure(
                        patientStudyNumber,
                        saeNumber,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        submitInvalidForm: createLogic<ISaeStore, {}, undefined, string, ReturnType<typeof saeActions.submitInvalidForm>>({
            type: saeActions.submitInvalidForm.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, saeNumber, invalidForm] = action.payload

                try {
                    const response = await api.submitInvalidForm(patientStudyNumber, saeNumber, invalidForm);

                    dispatch(saeActions.submitInvalidFormSuccess(
                        patientStudyNumber,
                        saeNumber,
                        response.invalidSaeForm
                    ));
                }
                catch (error: any) {
                    dispatch(saeActions.submitInvalidFormFailure(
                        patientStudyNumber,
                        saeNumber,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load,
        logic.notify,
        logic.reject,
        logic.performAction,
        logic.submitMedicalReview,
        logic.submitInvalidForm
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useSaeSelector: TypedUseSelectorHook<ISaeStore> = useSelector;

export const saeSelectors = {
    sae: (state: ISaeStore, patientStudyNumber: string, saeNumber: number) => {
        const context = `${patientStudyNumber}-${saeNumber}`;

        return state.sae?.byContext[context]?.sae ?? initialIndividualSaeState.sae;
    },
    loadState: (state: ISaeStore, patientStudyNumber: string, saeNumber: number) => {
        const context = `${patientStudyNumber}-${saeNumber}`;

        return state.sae?.byContext[context]?.loadState ?? initialIndividualSaeState.loadState;
    },
    actionState: (state: ISaeStore, patientStudyNumber: string, saeNumber: number) => {
        const context = `${patientStudyNumber}-${saeNumber}`;

        return state.sae?.byContext[context]?.actionState ?? initialIndividualSaeState.actionState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerSaeReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createSaeApi(client);

    const logic = createSaeLogic(api);

    reducerRegistry.register('sae', saeReducer as Reducer, logic as any);
};

export default registerSaeReducer;
