/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the depot management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useHistory } from 'react-router-dom';

import { Theme, makeStyles } from '@material-ui/core/styles';

/**
 * Used to format text.
 */
import Typography from '@material-ui/core/Typography';

import { Button, Paper } from '@material-ui/core';

import { Column } from 'material-table';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


import * as Dtos from '../api/dtos';
import { useOrderForms } from '../hooks/useOrderForms';
import { useDepots } from '../hooks/useDepots';
import { useDepot } from '../hooks/useDepot';
import DepotModal from '../modals/DepotModal';
import DrugBreadcrumbs from '../components/DrugBreadcrumbs';
import { CollapsibleTable, PageLayout } from '@ngt/opms';
import { useLoading } from '../hooks/useLoading';
import DrugManagementContext from '../context/DrugManagementContext';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonSet: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(3),
        textAlign: 'right'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Hooks
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const useDepotColumns = (depots: Dtos.Depot[] | null) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.Depot>> = [
            {
                
                title: 'Depot Name',
                field: 'depotName'
            },
            {
                title: 'Address',
                field: 'address'
            },
            {
                title: 'Email',
                field: 'email'
            },
            {
                title: 'Contact Name',
                field: 'contactName'
            },
            {
                title: 'Phone Number',
                field: 'phoneNo'
            }
        ]

        return cols;
    }, [depots]);

    return columns as any;
};

const DepotManagement: React.FunctionComponent = () => {
    const classes = useStyles();

    const history = useHistory();

    const [orderForms, orderFormLoadState, orderFormActions] = useOrderForms();

    const [depots, depotsLoadState, depotsActions] = useDepots();

    const [, , , depotActions] = useDepot();

    const [modalOpen, setModalOpen] = React.useState(false);

    const [depotForm, setDepot] = React.useState<Dtos.Depot | null>(null);

    const drugManagementContext = React.useContext(DrugManagementContext);

    React.useEffect(() => {
        orderFormActions.load();

        return () => {
            orderFormActions.clear();
        }
    }, []);

    React.useEffect(() => {
        depotsActions.load();

        return () => {
            depotsActions.clear();
        }
    }, []);

    const onFormSave = React.useCallback(() => {
        setModalOpen(false);
        depotActions.clear();
        depotsActions.load();
    }, []);

    const addDepotClick = React.useCallback(() => {
        setDepot(new Dtos.Depot({ orderFormId: 1 }));

        setModalOpen(true);
    }, [setModalOpen]);

    const onRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: Dtos.Depot) => {
        if (rowData) {
            history.push(`${drugManagementContext.baseUrl}/depot/${rowData.id}`);
        }
    }, []);

    const depotColumns = useDepotColumns(depots);

    const depotsLoading = useLoading(depots,
        depotsLoadState);

    return (
        <>
            <DepotModal
                depot={depotForm}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                orderForms={orderForms}
                orderFormLoadState={orderFormLoadState}
                onFormSave={onFormSave}
                depotActions={depotActions}
            />
            <PageLayout
                breadcrumbs={
                    <DrugBreadcrumbs
                        showInstitution={false}
                        showDepot={true}
                    />
                }
                heading='Depots'
            >
                <Paper
                    className={classes.container}
                >
                    {/* <Typography
                        variant="h1"
                        color="secondary"
                    >
                        Depots
                    </Typography> */}
                    <div
                        className={classes.buttonSet}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addDepotClick}
                        >
                            Add Depot
                        </Button>
                    </div>
                    <CollapsibleTable
                        title="Depot"
                        loading={depotsLoading}
                        data={depots ?? []}
                        columns={depotColumns}
                        onRowClick={onRowClick}
                    />
                </Paper>
            </PageLayout>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default DepotManagement;