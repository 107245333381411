/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { History as RouterHistory } from 'history';

import { Button, FormControl, InputLabel, Select, MenuItem, Grid, Tooltip, withStyles } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    ICollapsibleTableProps
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useSaes } from '../hooks/useSaes';

import { IPatient } from '@ngt/opms';

import SaeReviewContext from '../context/SaeReviewContext';
import SaeSearchContext from '../context/SaeSearchContext';
import { patientsActions } from '@ngt/opms/dist/store/modules/data/patients';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface ISaeCollapsibleTableProps<TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>> extends OmitData<ICollapsibleTableProps<TSae>> {
    data?: TSae[];
    columns: Array<Column<TSae>>;
    title?: string;
    loading?: boolean;
    status: Dtos.SaeStatus[];
    showInvestigatorColumn: boolean;
    showMedicalReviewColumn: boolean;
}

interface ISaeButtonProps {
    patientInstitutionId: number;
    patientStudyNumber: string;
    saeNumber: number;
    label: string;
    urlSuffix: string;
    tooltipText?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    buttonSet: {
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    tooltipText: {
        padding: theme.spacing(0.5),
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform
];

const useSaeColumns = <
    TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm,
    TPatient extends IPatient = IPatient,
    TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>
>(
    columns: Array<Column<TSae>>,
    canViewSae: boolean,
    canReviewSae: boolean,
    canAdministerSae: boolean,
    canMedicalReviewSae: boolean,
    investigatorColumn: boolean,
    medicalReviewColumn: boolean,
    classes: Record<string, string>
) => {
    const { hasMedicalReview } = React.useContext(SaeReviewContext);

    const getRecentInvestigatorActivity = React.useCallback((sae: TSae) => {
        const latestAction = sae?.actionHistory?.find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedSiteInvestigators ||
            a.actionType === Dtos.SaeWebActionTypeEnum.Approved ||
            a.actionType === Dtos.SaeWebActionTypeEnum.Rejected ||
            a.actionType === Dtos.SaeWebActionTypeEnum.Reset);

        const latestActionDate = latestAction?.actionDate;

        const latestActionDateStr = !!latestActionDate ? DateTime.fromISO(latestActionDate).toFormat('dd/MM/yyyy') : undefined;

        const notifiedStudyChair = sae?.actionHistory?.find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedStudyChair)?.actionDate === latestActionDate;

        const notifiedSponsor = sae?.actionHistory?.find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedStudySponsor)?.actionDate === latestActionDate;

        switch (latestAction?.actionType) {
            case Dtos.SaeWebActionTypeEnum.NotifiedSiteInvestigators: return `Notified site investigator(s)${notifiedStudyChair ? ', study chair & deputy chair' : ''}${notifiedSponsor ? ', study sponsor' : ''} on ${latestActionDateStr}`;
            case Dtos.SaeWebActionTypeEnum.Approved: return `Approved on ${latestActionDateStr}`;
            case Dtos.SaeWebActionTypeEnum.Rejected: return `Rejected on ${latestActionDateStr}`;
            case Dtos.SaeWebActionTypeEnum.Reset: return `Reset on ${latestActionDateStr}`;
        }

        return "No activity yet";

    }, []);

    const getRecentMedicalReviewActivity = React.useCallback((sae: TSae) => {
        const latestAction = sae?.actionHistory?.find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers ||
            a.actionType === Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview);

        const latestActionDate = latestAction?.actionDate;

        const latestActionDateStr = !!latestActionDate ? DateTime.fromISO(latestActionDate).toFormat('dd/MM/yyyy') : undefined;

        switch (latestAction?.actionType) {
            case Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers: return `Notified medical reviewer(s) on ${latestActionDateStr}`;
            case Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview: return `Review submitted on ${latestActionDateStr}`;
        }

        return "No activity yet";

    }, []);

    const saeColumns = React.useMemo(() => {
        const actionColumns: Array<Column<TSae>> = [];

        if (investigatorColumn && !!canReviewSae) {
            actionColumns.push({
                field: 'id',
                title: 'Investigator',
                render: sae => { 
                    return (
                        <>
                            {
                                sae.status == Dtos.SaeStatus.AwaitingForReview &&
                                    <SaeButton
                                        patientInstitutionId={sae.patient.institutionId as number}
                                        patientStudyNumber={sae.patient.studyNumber}
                                        saeNumber={sae.saeNumber as number}
                                        label="Review"
                                        urlSuffix="review"
                                        tooltipText={getRecentInvestigatorActivity(sae)}
                                    />
                            }

                            {
                                !!canAdministerSae && <SaeButton
                                    patientInstitutionId={sae.patient.institutionId as number}
                                    patientStudyNumber={sae.patient.studyNumber}
                                    saeNumber={sae.saeNumber as number}
                                    label="Notify"
                                    urlSuffix="notify/investigators"
                                    tooltipText={getRecentInvestigatorActivity(sae)}
                                />
                            }
                        </>
                    );
                },
                width: 50,
                sorting: false
            });
        }

        if (medicalReviewColumn && !!canMedicalReviewSae) {
            actionColumns.push({
                field: 'id',
                title: 'Medical Review',
                render: sae => {
                    return (
                        <>
                            {
                                sae?.medicalReviewStatus === Dtos.SaeMedicalReviewStatus.Pending && <SaeButton
                                    patientInstitutionId={sae.patient.institutionId as number}
                                    patientStudyNumber={sae.patient.studyNumber}
                                    saeNumber={sae.saeNumber as number}
                                    label="Review"
                                    urlSuffix="mreview"
                                    tooltipText={getRecentMedicalReviewActivity(sae)}
                                />
                            }

                            {
                                !!canAdministerSae && <SaeButton
                                    patientInstitutionId={sae.patient.institutionId as number}
                                    patientStudyNumber={sae.patient.studyNumber}
                                    saeNumber={sae.saeNumber as number}
                                    label="Notify"
                                    urlSuffix="notify/mreviewer"
                                    tooltipText={getRecentMedicalReviewActivity(sae)}
                                />
                            }
                        </>
                    );
                },
                sorting: false,
            });
        }

        return columns.concat(actionColumns);
    }, [columns, canViewSae, canReviewSae, canAdministerSae, canMedicalReviewSae, classes, investigatorColumn, medicalReviewColumn, getRecentInvestigatorActivity, getRecentMedicalReviewActivity])

    return saeColumns;
};

const SaeButton: React.FunctionComponent<ISaeButtonProps> = ({
    patientInstitutionId,
    patientStudyNumber,
    saeNumber,
    label,
    urlSuffix,
    tooltipText
}) => {
    const classes = useStyles();

    const history = useHistory();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutionCode = contextInstitutions.institutions?.find(i => i.id === patientInstitutionId)?.code ?? ALL_INSTITUTIONS_CODE;

    let saeLink = `/sae/${institutionCode}/${patientStudyNumber}/${saeNumber}`

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    const onClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>) => {
        event.stopPropagation();
        history.push(`${saeLink}/${urlSuffix}`);
    }, [history, saeLink, urlSuffix]);

    const htmlTooltip = React.useMemo(() => {
        if (!tooltipText) {
            return <></>;
        }

        return (
            <>
                <strong>Recent Activity</strong>
                <hr/>
                <div className={classes.tooltipText}>
                    {tooltipText}
                    <br /><br />
                    See <Link to={`${saeLink}#ActionHistory`}>Action History</Link>
                </div>
            </>
        );
    }, [tooltipText, classes, saeLink]);

    return <>
        {
            !!tooltipText && <LightTooltip title={htmlTooltip} className={classes.tooltip} placement="bottom-start" interactive>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onClick}
                    size="small"
                >
                    {label}
                </Button>
            </LightTooltip>
        }

        {
            !tooltipText && <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onClick}
                size="small"
            >
                {label}
            </Button>
        }
    </>
};

const SaeCollapsibleTable = <TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>> ({
    data,
    loading,
    title,
    status,
    columns,
    showInvestigatorColumn,
    showMedicalReviewColumn,
    ...tableProps
}: ISaeCollapsibleTableProps<TSaeForm, TPatient, TSae>) => {

    const classes = useStyles();

    const history = useHistory();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return contextInstitutions?.institutions?.find(i => i.code === institutionCode);
    }, [contextInstitutions?.institutions, institutionCode]);

    const [saes, saesLoadState, saesActions] = useSaes<TSaeForm, TPatient, TSae>(institution?.code, false);

    const { patientStudyNumber, saeNumber } = React.useContext(SaeSearchContext);

    const saesToUse = React.useMemo(() => {
        if (!saes && !data) {
            return [];
        }

        return (data ?? saes ?? []).filter(sae => status.includes(sae.status))
            .filter(sae => patientStudyNumber ? sae.patient.studyNumber === patientStudyNumber : true)
            .filter(sae => saeNumber ? sae.saeNumber?.toString() === saeNumber : true);
    }, [saes, data, status, patientStudyNumber, saeNumber]);

    const [[canViewSae, canReviewSae, canAdministerSae, canMedicallyReviewSae], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, false);

    const saesLoadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            saesLoadState.state === RequestState.None || saesLoadState.state === RequestState.Pending || permissionLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, saesLoadState, permissionLoadState]);

    const titleToUse = title ?? 'SAE';

    const columnsToUse = useSaeColumns(columns, canViewSae, canReviewSae, canAdministerSae, canMedicallyReviewSae, showInvestigatorColumn, showMedicalReviewColumn, classes);

    const onRowClick = React.useCallback((event?: React.MouseEvent<Element, MouseEvent>, sae?: TSae) => {
        const institutionCode = contextInstitutions.institutions?.find(i => i.id === sae?.patient?.institutionId)?.code ?? ALL_INSTITUTIONS_CODE;

        history.push(`/sae/${institutionCode}/${sae?.patient?.studyNumber}/${sae?.saeNumber}`)
    }, [history, contextInstitutions?.institutions]);

    const [tableCount, setTableCount] = React.useState(1);

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [columnsToUse, saesToUse]);

    return (
        <>
            {
                <CollapsibleTable
                    key={tableCount}
                    title={titleToUse}
                    entityName="SAEs"
                    loading={saesLoadingToUse}
                    data={saesToUse}
                    columns={columnsToUse}
                    onRowClick={onRowClick}
                    {...tableProps}
                />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeCollapsibleTable;
